import React, { Component } from "react";
import moment from 'moment';
import '../assets/styles/AppointmentCard.scss';
import Pagination from '@material-ui/lab/Pagination';

import { favouriteService } from '../services/favourite';

import {Card} from "react-bootstrap";

import dots_icon from '../assets/images/dots_icon.png';
import appointment_icon from '../assets/images/appointment_icon.png';
import location_pin_outline_icon from '../assets/images/location-pin-outline-icon.png';
import default_avatar from '../assets/images/avatar-default.png';

import { BookingInformationModal } from "./BookingInformationModal";
import _ from 'lodash';

export class AppointmentCard extends Component {

  constructor(props) {
    super(props);

    this.state = {
        appointment: {},
        meta: {},
        showBookingModal: false,
    };

    this.handleBookingModal = this.handleBookingModal.bind(this);
    this.getCoverage = this.getCoverage.bind(this);
    this.handlePerAppointmentCancel = this.handlePerAppointmentCancel.bind(this);
    this.updateAppointmentEventData = this.updateAppointmentEventData.bind(this);
  }

  handleBookingModal = (isOpen) => this.setState({ showBookingModal: isOpen });

  onFavClick(location_id, doctor_id, isFav){

        let params = {
            practice_location_id: location_id
        }

        favouriteService.favourite(isFav, params)
        .then(
            data => {
                this.props.onFav(doctor_id, isFav)
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    getCoverage(appointment){
        let coverage = "";
        if(appointment.pay_by_employer){
            coverage = appointment.employee_info.employer_name 
        }else{
            if(appointment.voucher_redeem){
                coverage = "Not Applicable - Voucher Redemption"
            }else{
                coverage = "Pay By Myself"
            }
        }

        return coverage;
    }

    convertTimezone(input){
        var timezoneValueRegex = /^[0-9]{2}:[0-9]{2}$/;
        var timezoneValueLength = 5;
        var timezoneName = input.substring(0, input.length - timezoneValueLength);
        var timezoneValue = input.slice(-timezoneValueLength);
  
        // Check timezone value format
        if (timezoneValueRegex.test(timezoneValue)) {
          return timezoneName + parseInt(timezoneValue);
        }
    }

    convertEpoch(value){
        return moment.unix(value).utc()
    }

    onMoreClick(appointment){
        this.setState({appointment})
        this.handleBookingModal(true);
    }

    handlePageChange = (event, value) => {
        this.props.setPageNo(value);
    };

    handlePerAppointmentCancel(appointment_id){
        this.setState(prevState => ({
            appointment: {
                ...prevState.appointment, 
                cancel_by: '1'
            }
        }));
        this.props.handleOnCancel(appointment_id);
    }


    updateAppointmentEventData(appointment_id,event_id){
        this.setState(prevState => ({
            appointment: {
                ...prevState.appointment, 
                user_google_event_id: event_id
            }
        }));
        this.props.updateAppointmentEventData(appointment_id,event_id);
    }
    
    render() {
  
      return (
        <div className="appointments-div">
            <div className={`appointments-list ${this.props.appointments.length%2 === 0 ? '' : 'add-after-space'}`}>
            {
                this.props.appointments.map((appointment, index) => (
                    <Card className="appointment-card" key={`appointment-${index}`}>
                        <Card.Header>
                            <div className="datetime-div">
                                <img src={appointment_icon} alt="appointment"/>
                                <div className="datetime">{this.convertEpoch(appointment.time).format('dddd, DD MMM YYYY | hh:mma')} {this.convertTimezone(appointment.doctor.timezone.utc_timezone_text)}</div>
                            </div>
                            <button className="btn btn-link more-btn" onClick={() => this.onMoreClick(appointment)}><img className="dots-icon" src={dots_icon} alt="more"/></button>
                        </Card.Header>
                        <Card.Body>
                            <div className="appointment-info">
                                <div className="profile-pic desktop-v">
                                    <img src={`${appointment.doctor.avatar ? (appointment.doctor.avatar.url.thumb.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} alt="profile-pic"/>
                                </div>
                                <div className="details-container">
                                    <div className="details">
                                        <div className="profile-pic mobile-v d-none">
                                            <img src={`${appointment.doctor.avatar ? (appointment.doctor.avatar.url.thumb.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} alt="profile-pic"/>
                                        </div>
                                        <div className="details-div">
                                            <div className="name-row">
                                                <a className="name" href={`/search-book/professional-profile/${appointment.doctor.friendly_id}/${appointment.location.location_friendly_id}`}>{appointment.doctor.title || '' } {appointment.doctor.name}</a>
                                                {!_.isEmpty(appointment.cancel_by) &&
                                                    <div className="appointment-status cancelled">Cancelled</div>
                                                }
                                                {(_.isEmpty(appointment.cancel_by) && this.props.type === 'past') &&
                                                    <div className="appointment-status attended">Attended</div>
                                                }
                                            </div>
                                            <div className="specialty">{appointment.doctor.specialties[0].name}</div>
                                            <div className="clinic-row">
                                                <img src={location_pin_outline_icon} alt="location-pin"/>
                                                <a className="clinic-name" href={`/search-book/clinic/${appointment.location.location_friendly_id}`}>{appointment.location.name}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient-details">
                                        <div className="row-info">
                                            <div className="col-label">Medical Coverage</div>
                                            <div className="col-value">{this.getCoverage(appointment)}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Reason</div>
                                            <div className="col-value">{appointment.reason}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Patient</div>
                                            <div className="col-value">{appointment.visitor.relationship === 'Me' ? 'Myself' : appointment.visitor.first_name}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Phone</div>
                                            <div className="col-value">{appointment.doctor.phone_number}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>  
                ))
            } 
            </div>
            
            <Pagination count={this.props.meta.total_pages} page={this.props.page_no} onChange={this.handlePageChange}/>

            {!_.isEmpty(this.state.appointment) &&
                <BookingInformationModal showBookingModal={this.state.showBookingModal} handleBookingModal={this.handleBookingModal} appointment={this.state.appointment} getCoverage={this.getCoverage} type={this.props.type} handleOnFav={this.props.handleOnFav} handlePerAppointmentCancel={this.handlePerAppointmentCancel} updateAppointmentEventData={this.updateAppointmentEventData}/>
            }
        </div>

      );
  }
}

export default AppointmentCard;
