import React from 'react';

import { NavLink, Switch, Route } from 'react-router-dom';

import { Home } from './Home';
import { Result } from './Result';
import { Clinic } from './Clinic';
import { Login } from './Login';
import { Profile } from './Profile';
import { ProfessionalProfile } from './ProfessionalProfile';
import { ComponentError } from './ComponentError';
import { Terms } from './Terms';
import { Privacy } from './Privacy';
import { UpcomingAppointment } from './UpcomingAppointment';
import { PastAppointment } from './PastAppointment';
import { Favourite } from './Favourite';
import { Settings } from './Settings';
import { ExpertColumn } from './ExpertColumn';
import { ExpertColumnDetail } from './ExpertColumnDetail';

const Main = () => {
    return (
        <Switch>
            <Route exact path='/search-book' component={Home}></Route>
            <Route exact path='/search-book/result' component={Result}></Route>
            <Route exact path='/search-book/clinic/:location_friendly_id' component={Clinic}></Route>
            <Route exact path='/search-book/login' component={Login}></Route>
            <Route exact path='/search-book/profile' component={Profile}></Route>
            <Route exact path='/search-book/professional-profile/:doc_friendly_id/:location_friendly_id' component={ProfessionalProfile}></Route>
            <Route exact path='/search-book/terms' component={Terms}></Route>
            <Route exact path='/search-book/privacy' component={Privacy}></Route>
            <Route exact path='/search-book/upcoming-appointment' component={UpcomingAppointment}></Route>
            <Route exact path='/search-book/past-appointment' component={PastAppointment}></Route>
            <Route exact path='/search-book/favourites' component={Favourite}></Route>
            <Route exact path='/search-book/settings' component={Settings}></Route>
            <Route exact path='/search-book/expert-column' component={ExpertColumn}></Route>
            <Route exact path='/search-book/expert-column/:id' component={ExpertColumnDetail}></Route>
            <Route component = { ComponentError } />
        </Switch>
    );
}

export { Main };
