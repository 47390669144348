import React, { Component }  from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { locationService } from '../services/location';
import { userService } from '../services/user';
import { pinService } from '../services/pin';
import '../assets/styles/VerifyPhoneModal.scss';
import '../assets/styles/MainModal.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import _ from 'lodash';
import ReactPinField from "react-pin-field";

export class VerifyPhoneModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            edit_phone: false,
            current_step: 1,
            current_country_code: global.constant.default_country_code,
            countries: global.constant.countries.map((country) => ({ label: `${country.name} (${country.dial_code})`, value: country.code, full: country })),
            verify: {
                phone_number: ""
            },
            disable_generate_pin_btn: false,
            invalid_pin: false
        };

    }

    verifySchema = yup.object().shape({
        phone_number: yup.string().required().min(7).max(12).label('Mobile Phone')
    });

    get show() {
        return this.props.showVerifyPhoneModal;
    }

    componentDidMount(){
        this.getCurrentCountry();
    }

    getCurrentCountry(){
        locationService.getCurrentCountry()
        .then(
            location => {
                if(location){
                    this.setState({
                        current_country_code: location.country_code
                    });
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    onEditClick(){
        this.setState({
            edit_phone: true
        });
    }

    handleVerify(formData, actions, stateData){
        let params = _.cloneDeep(formData);

        let selected_country = stateData.countries.find(option => option.value === stateData.current_country_code)
        params.phone_number = `${selected_country.full.dial_code.replace(/\D/g,'')}${params.phone_number}`;
        
        userService.changeNumber(params)
        .then(
            response => {
                if(response.success || _.has(response,'auth_token')){
                    // generate pin
                    this.onNextClick();
                }else{
                    actions.setErrors({
                        phone_number: 'Phone number is already in use.'
                    });
                }

            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    onNextClick(){
        this.setState({
            current_step: 2
        });

        this.generatePin();
    }

    generatePin(){
        //pinService.generate();
        this.setState({
            disable_generate_pin_btn: true
        });
    
        // **** here's the timeout ****
        setTimeout(() => this.setState({ disable_generate_pin_btn: false }), 10000);
    }

    closeVerifyModal(){
        this.setState({
            edit_phone: false,
            current_step: 1
        });
        this.props.handleVerifyPhoneModal(false);
    }

    verifyPin(pin){
        let params = {
            pin_number: pin
        }
        let isPinCorrect;
        pinService.verify(params).then(
            response => {
                isPinCorrect = response;
                this.setState({
                    invalid_pin: !isPinCorrect
                });

                let list = document.querySelectorAll('.pin-field')
                for (var i = 0; i < list.length; ++i) {
                    isPinCorrect ? list[i].classList.remove('incorrect-pin') : list[i].classList.add('incorrect-pin');
                }
        
                if(isPinCorrect){
                    // close modal
                    // open booking order
                    // change user data - verified to true
                    
                    this.closeVerifyModal();
                    this.props.handleBookingModal(true);
        
                    let user = {
                        ...JSON.parse(localStorage.getItem('user')),
                        verified: true
                    };
                    localStorage.setItem('user', JSON.stringify(user));
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        );

    }

    render() {
        const SingleValue = country_props => (
            <components.SingleValue {...country_props}>
              {country_props.data.full.dial_code}
            </components.SingleValue>
        );
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="verify-phone-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeVerifyModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    <div className="title">Please Verify Your Phone Number</div>
                    {this.state.current_step == 2 &&
                        <div className="pin-step-container">
                            <ReactPinField length="4" className="pin-field" onComplete={(val) => this.verifyPin(val)}/>
                            <div className={`invalid-pin-error ${this.state.invalid_pin ? 'show' : ''}`}>Invalid Pin Number</div>
                            <Button className={`btn-block submit-btn ${this.state.disable_generate_pin_btn ? 'disabled' : ''}`} type="button" onClick={() => this.generatePin()}>Generate Pin</Button>
                        </div>
                    }
                    {this.state.current_step == 1 &&
                        (() => {
                            if (userService.getUser().phone && !this.state.edit_phone) {
                                return <div className="verify-current-phone" show={this.state.current_step == 1}>
                                        <div className="phone-number">+{userService.getUser().phone}</div>
                                        <button className="btn btn-block next-btn" onClick={() => this.onNextClick()}>Next</button>
                                        <button className="btn btn-link" onClick={() => this.onEditClick()}>Edit</button>
                                </div>;
                            } else {
                                return <Formik show={this.state.current_step == 1} validationSchema={this.verifySchema} onSubmit={(form, actions) => this.handleVerify(form, actions, this.state)} initialValues={this.state.verify}>
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit} className="verify-form">
                                        <Form.Row>
                                            <Form.Group className="phone-field-div">    
                                                <Select className="phone-prefix-field" value={this.state.countries.filter(option => option.value === this.state.current_country_code)} options={this.state.countries} onChange={selected => this.setState({ current_country_code: selected.value })} components={{ SingleValue }}/>
                                                <div className="phone-input-div">
                                                    <Form.Control
                                                        type="text"
                                                        name="phone_number"
                                                        value={values.phone_number}
                                                        onChange={handleChange}
                                                        isValid={touched.phone && !errors.phone_number}
                                                        isInvalid={!!errors.phone_number}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.phone_number}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </Form.Row>
                                
                                        <Button className="btn-block submit-btn" type="submit">Next</Button>
                                    </Form>
                                )}
                                </Formik>;
                            }
                        })()
                    }

                </Modal.Body>
            </Modal>
        );
  }
}