import React, { Component }  from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { userService } from '../services/user';
import '../assets/styles/SignInModal.scss';
import '../assets/styles/MainModal.scss';
import pre_login_bookdoc_logo from '../assets/images/pre-login-bookdoc-logo@2x.png';
import grey_email_icon from '../assets/images/grey-email-icon.png';
import grey_lock_icon from '../assets/images/grey-lock-icon.png';
import fb_icon from '../assets/images/fb_icon.svg';
import { TextField, Grid } from '@material-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import _ from 'lodash';

import ReactGA from 'react-ga';

export class SignInModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            login_error: false
        };

       this.handleSubmit = this.handleSubmit.bind(this)
    }

    get show() {
        return this.props.showSignInModal;
    }

    setEmail = (email) => this.setState({ email: email });
    setPassword = (password) => this.setState({ password: password });

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleSubmit(event) {
        event.preventDefault();

        userService.login(this.state.email, this.state.password)
        .then(
            data => {
                if(_.has(data,'user')){
                    ReactGA.event({
                        category: "User",
                        action: "Sign In",
                    });
                   window.location.reload(false);
                }else{
                    this.setState({
                        login_error: true
                    });
                }
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    responseFacebook = (response) => {
        if(response){
            let params = {
                email: response.email,
                first_name: response.first_name,
                last_name: response.last_name,
                sex: response.gender,
                provider: "Facebook",
                oauth_app_id: process.env.REACT_APP_FACEBOOK_APP_ID,
                oauth_app_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
                oauth_uuid: response.id,
            }
            userService.registration(params).then(
                user => {
                    window.location.reload(false);
                },
                error => {
                    console.log(error)
                    this.setState({ error, loading: false })
                }
            );
        }
    }

    render() {
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="sign-in-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.props.handleSignInModal(false)}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    <div className="modal-logo">
                        <img src={pre_login_bookdoc_logo} />
                    </div>
                    <div className="modal-title">Sign In</div>
                    <div className="modal-sub-title">Sign in with your BookDoc account to make booking</div>

                    <div className="form-container">
                        {this.state.login_error && 
                            <div className="login-error">Invalid Email or Password</div>
                        }
                       
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Group controlId="email" className="login-form-group">
                                <Grid container alignItems="flex-end">
                                    <Grid item className="grid-input-icon">
                                        <img className="input-icon" src={grey_email_icon}/>
                                    </Grid>
                                    <Grid item className="grid-input-field">
                                        <TextField id="email-input" type="email" className="form-control" label="Email" value={this.state.email} onChange={(e) => this.setEmail(e.target.value)} InputProps={{disableUnderline: true}} autoFocus autoComplete="email"/>
                                        
                                    </Grid>
                                </Grid>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Grid container alignItems="flex-end">
                                    <Grid item className="grid-input-icon">
                                        <img className="input-icon" src={grey_lock_icon}/>
                                    </Grid>
                                    <Grid item className="grid-input-field">
                                        <TextField id="password-input" type="password" className="form-control" label="Password" value={this.state.password} onChange={(e) => this.setPassword(e.target.value)}  InputProps={{disableUnderline: true}} autoFocus/>
                                    </Grid>
                                </Grid>
                            </Form.Group>
                            <button type="button" className="btn forget-password" onClick={(e) => this.props.handleSwitchChangePwModal(true)}>Forgot Your Password?</button>
                            <div className="t-and-c">
                                By continuing, you accept BookDoc's <a href="/search-book/terms" target="_blank">terms & conditions</a> and <a href="/search-book/privacy" target="_blank">Privacy policy</a>.
                            </div>
                            <Button className="btn signin-btn" block size="lg" type="submit" disabled={!this.validateForm()}>
                                Sign In
                            </Button>
                        </Form>
                    </div>
                        <div className="separator">or</div>
                        <div className="form-container">
                            <FacebookLogin
                                appId="1225759537863554"
                                autoLoad={false}
                                fields="first_name,last_name,email,gender"
                                callback={this.responseFacebook} 
                                render={renderProps => (
                                    //<button onClick={renderProps.onClick}>This is my custom FB button</button>
                                    <Button className="fb-btn" block size="lg" type="button" onClick={renderProps.onClick}>
                                        <img src={fb_icon}/>
                                        Continue with Facebook
                                    </Button>
                                )}
                                />
                        </div>
                        <div className="new-patient-div">Are you a new patient? <button className="btn btn-link sign-up-link" onClick={() => this.props.handleSwitchSignUpModal(true)}>Sign up for a BookDoc account now!</button></div>
                </Modal.Body>
            </Modal>
        );
  }
}