import React, { Component } from "react";
import { userService} from '../services/user';
import  { Redirect } from 'react-router-dom';

import { teleconsultService } from '../services/teleconsult';
import { doctorService } from '../services/doctor';
import { slotService } from '../services/slot';
import { favouriteService } from '../services/favourite';

import _ from 'lodash';
import '../assets/styles/ProfessionalProfile.scss';
import Carousel from 'react-bootstrap/Carousel';
import Spinner from 'react-bootstrap/Spinner';

import video_icon from '../assets/images/video_icon.png';
import chat_icon from '../assets/images/chat_icon.png';
import mail_icon from '../assets/images/mail_icon.png';
import phone_icon from '../assets/images/phone_icon.png';
import grab_icon from '../assets/images/grab_icon.png';
import hotel_icon from '../assets/images/hotel_icon.png';
import world_icon from '../assets/images/world_icon.png';
import location_pin_outline_icon from '../assets/images/location-pin-outline-icon.png';
import calendar_icon from '../assets/images/calendar-icon.svg';
import clinic_default from '../assets/images/clinic_default.png';
import unfav from '../assets/images/fav-unfill.png'; 
import fav from '../assets/images/fav-fill.png'; 
import walk_in_icon from '../assets/images/walk_in_icon.png'; 

import Map from "./Map";
import { arrayService } from '../services/array';
import profile_img from '../assets/images/profile.png';
import moment from 'moment';
import {Tabs, Tab} from "react-bootstrap";
import default_avatar from '../assets/images/avatar-default.png';
import { BookingModal } from "./BookingModal";
import { SignInModal } from "./SignInModal";
import { SignUpModal } from "./SignUpModal";
import { VerifyPhoneModal } from "./VerifyPhoneModal";
import { HotelDialog } from "./HotelDialog";
import { ScheduleModal } from "./ScheduleModal";

import OperationHour from "./OperationHour";

class ProfessionalProfile extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            slot_loading: false,
            doctor: {},
            chat_info: {},
            location: {},
            slots: [],
            today_date: moment().startOf('day'),
            slot_date: moment().startOf('day'),
            day_diff: 0,
            key: 'profile',
            showVerifyPhoneModal: false,
            showBookingModal: false,
            showSignInModal: false,
            showSignUpModal: false,
            showHotelDialog: false,
            showScheduleModal: false,
            appointment: {
                from: 'prof_profile',
                slot: {},
                doctor: {}
            }
        }

        this.handleVerifyPhoneModal = this.handleVerifyPhoneModal.bind(this);
        this.handleBookingModal = this.handleBookingModal.bind(this);
        this.handleSignInModal = this.handleSignInModal.bind(this);
        this.handleSignUpModal = this.handleSignUpModal.bind(this);
        this.handleHotelDialog = this.handleHotelDialog.bind(this);
        this.handleScheduleModal = this.handleScheduleModal.bind(this);
        this.getSlots = this.getSlots.bind(this);
        this.get_range_dates = this.get_range_dates.bind(this);
        this.setSelectedSlot = this.setSelectedSlot.bind(this);
        this.onSelectedSlot = this.onSelectedSlot.bind(this);
        this.onFavClick = this.onFavClick.bind(this);
    }

    handleBookingModal = (isOpen) => this.setState({ showBookingModal: isOpen });
    handleVerifyPhoneModal = (isOpen) => this.setState({ showVerifyPhoneModal: isOpen });
    handleSignInModal = (isOpen) => this.setState({ showSignInModal: isOpen });
    handleSignUpModal = (isOpen) => this.setState({ showSignUpModal: isOpen });
    handleHotelDialog = (isOpen) => this.setState({ showHotelDialog: isOpen });
    handleScheduleModal = (isOpen) => this.setState({ showScheduleModal: isOpen });
    handleSwitchSignInModal  = (isOpen) => this.setState({ showSignInModal: isOpen, showSignUpModal: !isOpen  });
    handleSwitchSignUpModal  = (isOpen) => this.setState({ showSignInModal: !isOpen, showSignUpModal: isOpen });

    getDoctor(doc_friendly_id, location_friendly_id){
        this.setState({
            loading: true
        });
        let params = {
            location_id: location_friendly_id
        }
        doctorService.get(doc_friendly_id,params)
        .then(
            data => {
                if(data){
                    let location = arrayService.findElementByProperty(data.locations, 'friendly_id', this.props.match.params.location_friendly_id)
                    this.getSlots(location.id,moment(this.state.slot_date).format('YYYYMMDD'))

                    this.getDocChatInfo(data.id);

                    this.setState(prevState => ({
                        doctor: data, 
                        location: location,
                        appointment: {
                            ...prevState.appointment, 
                            doctor: data
                        }
                    }))
                }
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        ).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    getDocChatInfo(doctor_id){
        teleconsultService.getDocChatInfo(doctor_id).then(
            data => {
                if(data){
                    this.setState({
                        chat_info: data
                    });
                }
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        );
    }

    getSlots(location_id, date){
        this.setState({
            slot_loading: true,
            slots: []
        });
        let params = {
            location: location_id,
            date: date,
            around: 1
        }
        slotService.get(params)
        .then(
            data => {
                this.setState({ 
                    slots: data
                })
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        ).finally(() => {
            this.setState({
                slot_loading: false
            });
        });
    }

    setTabKey(key){
        this.setState({ key: key})
    }

    componentDidMount(){
        this.getDoctor(this.props.match.params.doc_friendly_id,this.props.match.params.location_friendly_id)
    }

    get_range_dates(is_increase){

        let current_date = moment(this.state.slot_date).startOf('day')
    
        if(is_increase){
            current_date = moment(current_date).add(1, 'day');
        }else{
            current_date = current_date.subtract(1, "days");
        }
    
        this.setState({
          slot_date: current_date,
          day_diff: current_date.diff(moment(this.state.today_date), 'days')
        })
        
       this.getSlots(this.state.location.id, moment(current_date).format('YYYYMMDD'))
    }

    onSelectedSlot(slot){
        this.setSelectedSlot(slot);

        userService.getUser() ? (userService.getUser().verified ? this.handleBookingModal(true) : this.handleVerifyPhoneModal(true)) : this.handleSignInModal(true);
    }

    setSelectedSlot(slot){
        this.setState(prevState => ({
            appointment: {
                ...prevState.appointment, 
                slot: slot
            }
        }));
    }

    onFavClick(location_id, isFav){

        let params = {
            practice_location_id: location_id
        }

        favouriteService.favourite(isFav, params)
        .then(
            data => {
                this.setState(prevState => ({
                    doctor: {
                        ...prevState.doctor,
                        favorited: !isFav,
                    },
                    appointment: {
                        ...prevState.appointment,
                        doctor: {
                            ...prevState.appointment.doctor,
                            favorited: !isFav
                        }
                    }
                }))
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        );
    }

    convertEpochToTime(value){
        return moment.unix(value).utc().format('hh:mm A')
    }

    getDirectionUrl(){
        // return `https://www.google.com/maps/search/?api=1&query=${this.state.location.lat},${this.state.location.long}`
        return `https://www.google.com/maps/dir//${this.state.location.lat},${this.state.location.long}`
    }

    render() {

        if(this.state.loading){
            return <div className="loader"><Spinner animation="border" /></div>
        }else{
            if (_.isEmpty(this.state.doctor)){
                return <div className="loader">Invalid doctor page</div>
            }else{
                return (
                    <div className="professional-profile-page container">
                        <div className="header">
                            <div className="title">Online Appointment</div>
                            <div className="bread-crumb">Home / Search & Book / {this.state.doctor.name}</div>
                        </div>
                        <hr className="header-line"/>
                        <div className="body desktop-v">
                            <div className="clinic-details">
                                <div className="img-slider">
                                    <Carousel className="carousel" nextIcon="" nextLabel="" prevIcon="" prevLabel="">
                                        {
                                            this.state.doctor.photos.map((item, index) => (
                                                <Carousel.Item className="carousel-item" key={`expert-${index}`}>
                                                    <img className="d-block w-100" src={item.styles.origin.url} alt={item.alt}/>
                                                </Carousel.Item>
                                            ))
                                        }
                                        {_.isEmpty(this.state.doctor.photos) && 
                                            <Carousel.Item className="carousel-item">
                                                <img className="d-block w-100" src={clinic_default}/>
                                            </Carousel.Item>
                                        }
                                    </Carousel>
                                </div>
                                <div className="consultation-type">
                                    <div className="title">Consultation</div>
                                    {this.state.doctor.is_using_calendar &&
                                        <div className="type"><img src={walk_in_icon} className="consultation-icon"/>Walk In</div>
                                    }  
                                </div>
                                <div className="contact">
                                    <div className="title">Contact</div>
                                    <div className="details-container">
                                        <div className="icon-col">
                                            <img className="location-pin-outline-icon" src={location_pin_outline_icon} />
                                        </div>
                                        <div className="address-col">
                                            <div className="address-name"><a href={`/search-book/clinic/${this.state.location.friendly_id}`}>{this.state.location.name}</a></div>
                                            <div className="address">{this.state.location.address}, {[this.state.location.postal, this.state.location.state].filter(Boolean).join(" ")}</div>
                                            <div className="map-container">
                                                <Map results={[this.state.doctor]} location_friendly_id={this.props.match.params.location_friendly_id} parentName={`ProfessionalProfile`}/>
                                            </div>
                                            <a className="direction-tag" href={this.getDirectionUrl()} target="_blank">Get Direction</a>
                                            <div className="phone-email-container">
                                                <a href={`tel:${this.state.doctor.phone_number}`}><img className="contact-icon phone" src={phone_icon}/></a>
                                                <a href={`mailto:${this.state.doctor.email}`}><img className="contact-icon mail" src={mail_icon}/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="interest">
                                    <div className="title">You might be interested....</div>
                                    <div className="interest-links">
                                        <a className="link-container" target="_blank" href={`grab://open?screenType=BOOKING&dropOffKeywords=${this.state.location.name}&dropOffAddress=${this.state.location.name}&dropOffLatitude=${this.state.location.lat}&dropOffLongitude=${this.state.location.long}&sourceID=CiFLV3RB7N&sourceAppName=BookDoc`}>
                                            <div className="interest-icon">
                                                <img src={grab_icon}/>
                                            </div>
                                            <div className="interest-title">Book A Ride</div>
                                        </a>
                                        <div className="link-container" onClick={() => this.handleHotelDialog(true)}>
                                            <div className="interest-icon">
                                                <img src={world_icon}/>
                                            </div>
                                            <div className="interest-title">Travel Info</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="doctor-details">
                                <div className="details-container">                
                                    <div className="profile-pic">
                                        <img src={`${this.state.doctor.avatar ? (this.state.doctor.avatar.styles.origin.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} />
                                    </div>
                                    <div className="details">
                                        <div className="top-details">
                                        <div className="name-row">
                                            <div className="name">{this.state.doctor.title || '' } {this.state.doctor.name}</div>
                                        </div>
                                        <div className="specialty">{this.state.doctor.specialties[0].name}</div>
                                        <div className="feature-tags">
                                            {this.state.doctor.is_using_calendar == true && 
                                                <div className="feature-tag online-booking">Online Booking</div>
                                            }
                                            {(!_.isEmpty(userService.getUser()) && _.includes(_.map(this.state.doctor.employers, 'id'), _.isEmpty(userService.getUser().employee) ? null : userService.getUser().employee.employer_id)) && 
                                                <div className="feature-tag corporate-panel">Corporate Panel</div>
                                            }
                                        </div>
                                        
                                        </div>
                                        <div className="bottom-details">
                                            {userService.getUser() && 
                                                <button className="btn fav-btn" onClick={() => this.onFavClick(this.state.location.id, this.state.doctor.favorited)}>
                                                    { this.state.doctor.favorited ? <img src={fav}/> : <img src={unfav}/> }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {this.state.doctor.is_using_calendar == true &&
                                    <React.Fragment>
                                        <div className="schedule-walk-in">Schedule (Walk-in only)</div>
                                        <div className="slots-container">
                                            <div className="date-bar">
                                                <button className={`btn btn-link previous ${this.state.day_diff > 0 ? '' : 'disabled'}`}><i className="fa fa-chevron-left" aria-hidden="true" onClick={() => this.get_range_dates(0)}></i></button>
                                                <div className="date">{moment(this.state.slot_date).format('ddd, DD MMM Y')}</div>
                                                <button className="btn btn-link next" onClick={() => this.get_range_dates(1)}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="slots">
                                                {(() => {
                                                    if (this.state.slot_loading) {
                                                        return <div className="slots-loader"><Spinner animation="border" className="spinner"/></div>;
                                                    } else {
                                                        return (this.state.slots.length ?
                                                            this.state.slots.map((slot, index) => (
                                                            <button key={`slot-${index}`} className="btn slot valid-slot" onClick={() => this.onSelectedSlot(slot)}>{this.convertEpochToTime(slot.start)}</button>
                                                            )) : <div className="slots-loader">No Slots</div>
                                                            );
                                                    }
                                                })()}
                                                
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
    
                                {/* teleconsult */}
                                {!_.isEmpty(this.state.chat_info) &&
                                    <div className="teleconsult-container">
                                        <div className="div-header">
                                            <div className="header-title">Teleconsult (Require App)</div>
                                            <a className="download-app" target="_blank" href="https://sites.bookdoc.com/download-bookdoc-app/">Download App</a>
                                        </div>
                                        <div className="teleconsult-lists">
                                            {this.state.chat_info.chat_enabled &&
                                                <a className="btn btn-link teleconsult-btn chat-btn" href={`https://deeplink.bookdoc.com/teleconsult/doctor/${this.state.doctor.id}`}>
                                                    <div className="teleconsult-icon">
                                                        <img className="chat-icon" src={chat_icon}/>
                                                    </div>
                                                    <div className="button-info">
                                                        <div className="info-text">Chat</div>
                                                        <div className="info-price">{`${this.state.chat_info.chat_price.currency} ${this.state.chat_info.chat_price.price.toFixed(2)}`}</div>
                                                    </div>
                                                </a>
                                            }
                                            {this.state.chat_info.video_enabled &&
                                                <a className="btn btn-link teleconsult-btn video-btn" href={`https://deeplink.bookdoc.com/teleconsult/doctor/${this.state.doctor.id}`}>
                                                    <div className="teleconsult-icon">
                                                        <img className="chat-icon" src={video_icon}/>
                                                    </div>
                                                    <div className="button-info">
                                                        <div className="info-text">Video</div>
                                                        <div className="info-price">{`${this.state.chat_info.chat_price.currency} ${this.state.chat_info.chat_price.video_price.toFixed(2)}`}</div>
                                                    </div>
                                                </a>
                                            }   
                                        </div>
                                    </div>
                                }
                                {/* end teleconsult */}    
    
                                <hr className="details-line"/>
        
                                <div className="details-tabs-container">
                                    <Tabs id="controlled-details-tabs" activeKey={this.state.key} onSelect={(k) => this.setTabKey(k)}>
                                        <Tab eventKey="profile" title="Profile">
                                            {this.state.doctor.summary && 
                                                <div className="detail-container">
                                                    <div className="tab-title">Biography</div>
                                                    <p className="tab-content">{this.state.doctor.summary}</p>
                                                </div>
                                            }
                                            {this.state.doctor.educations.length && 
                                                <div className="detail-container">
                                                    <div className="sub-tab-title">Education</div>
                                                    <ul className="tab-content list">
                                                    {
                                                        this.state.doctor.educations.map((education, index) => (
                                                            <li key={`edu-${index}`}>{education.name}</li>
                                                        ))
                                                    }
                                                    </ul>
                                                </div>
                                            }
    
                                            <div className="detail-container">
                                                <div className="sub-tab-title">Credentials</div>
                                                <div className="tab-content credential">
                                                    <div className="cre-title">Qualifications</div>
                                                    <div className="punctuation">:</div>
                                                    <div className="cre-content">{
                                                    this.state.doctor.qualifications.map(({name}) => name).join(', ')}</div>
                                                </div>
                                                {this.state.doctor.mmc && 
                                                    <div className="tab-content credential">
                                                        <div className="cre-title">Medical Registration Number</div>
                                                        <div className="punctuation">:</div>
                                                        <div className="cre-content">{this.state.doctor.mmc}</div>
                                                    </div>
                                                }
                                                {this.state.doctor.nsr && 
                                                    <div className="tab-content credential">
                                                        <div className="cre-title">National Specialist Registry Number</div>
                                                        <div className="punctuation">:</div>
                                                        <div className="cre-content">{this.state.doctor.nsr}</div>
                                                    </div>
                                                }
                                                
                                            </div>
                                            {this.state.doctor.languages && 
                                                <div className="detail-container">
                                                    <div className="sub-tab-title">Language</div>
                                                    <p className="tab-content">{this.state.doctor.languages}</p>
                                                </div>
                                            }
                                        </Tab>
                                        <Tab eventKey="services" title="Services">
                                            <div className="detail-container">
                                                <div className="tab-title">Services/Procedures</div>
                                                <ul className="tab-content list">
                                                    {
                                                        this.state.doctor.services.map((service, index) => (
                                                            <li key={`service-${index}`}>{service.name}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="hours" title="Hours">
                                            <div className="detail-container">
                                                <div className="sub-tab-title">{this.state.location.name}</div>
                                                <OperationHour operatingHours={this.state.location.operating_hours} />
                                                {this.state.location.clinic_hour_note &&
                                                    <div className="hour-note">*Notes: {this.state.location.clinic_hour_note}</div>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
    
                        <div className="body mobile-v">
                            <div className="clinic-details">
                                <div className="img-slider">
                                    <Carousel className="carousel" nextIcon="" nextLabel="" prevIcon="" prevLabel="">
                                        {
                                            this.state.doctor.photos.map((item, index) => (
                                                <Carousel.Item className="carousel-item" key={`expert-${index}`}>
                                                    <img className="d-block w-100" src={item.styles.origin.url} alt={item.alt}/>
                                                </Carousel.Item>
                                            ))
                                        }
                                        {_.isEmpty(this.state.doctor.photos) && 
                                            <Carousel.Item className="carousel-item">
                                                <img className="d-block w-100" src={clinic_default}/>
                                            </Carousel.Item>
                                        }
                                    </Carousel>
                                    {userService.getUser() && 
                                        <button className="btn fav-btn" onClick={() => this.onFavClick(this.state.location.id, this.state.doctor.favorited)}>
                                            { this.state.doctor.favorited ? <img src={fav}/> : <img src={unfav}/> }
                                        </button>
                                    }
                                    <div className="doctor-details">
                                        <div className="details-container">                
                                            <div className="profile-pic">
                                                <img src={`${this.state.doctor.avatar ? (this.state.doctor.avatar.styles.origin.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} />
                                            </div>
                                            <div className="details">
                                                <div className="name-row">
                                                    <div className="name">{this.state.doctor.name}</div>
                                                </div>
                                                <div className="specialty">{this.state.doctor.specialties[0].name}</div>
                                                <div className="feature-tags">
                                                    {this.state.doctor.is_using_calendar == true && 
                                                        <div className="feature-tag online-booking">Online Booking</div>
                                                    }
                                                     {(!_.isEmpty(userService.getUser()) && _.includes(_.map(this.state.doctor.employers, 'id'), _.isEmpty(userService.getUser().employee) ? null : userService.getUser().employee.employer_id)) && 
                                                        <div className="feature-tag corporate-panel">Corporate Panel</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="doctor-details">
                                <div className="details-tabs-container">
                                    <Tabs id="controlled-details-tabs" activeKey={this.state.key} onSelect={(k) => this.setTabKey(k)}>
                                        <Tab eventKey="profile" title="Profile">
                                            {this.state.doctor.summary && 
                                                <div className="detail-container">
                                                    <div className="tab-title">Biography</div>
                                                    <p className="tab-content">{this.state.doctor.summary}</p>
                                                </div>
                                            }
                                            {this.state.doctor.educations.length && 
                                                <div className="detail-container">
                                                    <div className="sub-tab-title">Education</div>
                                                    <ul className="tab-content list">
                                                    {
                                                        this.state.doctor.educations.map((education, index) => (
                                                            <li key={`edu-${index}`}>{education.name}</li>
                                                        ))
                                                    }
                                                    </ul>
                                                </div>
                                            }
    
                                            <div className="detail-container">
                                                <div className="sub-tab-title">Credentials</div>
                                                <div className="tab-content credential">
                                                    <div className="cre-title">Qualifications</div>
                                                    <div className="punctuation">:</div>
                                                    <div className="cre-content">{
                                                    this.state.doctor.qualifications.map(({name}) => name).join(', ')}</div>
                                                </div>
                                                {this.state.doctor.mmc && 
                                                    <div className="tab-content credential">
                                                        <div className="cre-title">Medical Registration Number</div>
                                                        <div className="punctuation">:</div>
                                                        <div className="cre-content">{this.state.doctor.mmc}</div>
                                                    </div>
                                                }
                                                {this.state.doctor.nsr && 
                                                    <div className="tab-content credential">
                                                        <div className="cre-title">National Specialist Registry Number</div>
                                                        <div className="punctuation">:</div>
                                                        <div className="cre-content">{this.state.doctor.nsr}</div>
                                                    </div>
                                                }
                                                
                                            </div>
                                            {this.state.doctor.languages && 
                                                <div className="detail-container">
                                                    <div className="sub-tab-title">Language</div>
                                                    <p className="tab-content">{this.state.doctor.languages}</p>
                                                </div>
                                            }
                                        </Tab>
                                        <Tab eventKey="services" title="Services">
                                            <div className="detail-container">
                                                <div className="tab-title">Services/Procedures</div>
                                                <ul className="tab-content list">
                                                    {
                                                        this.state.doctor.services.map((service, index) => (
                                                            <li key={`service-${index}`}>{service.name}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="hours" title="Hours">
                                            <div className="detail-container">
                                                <div className="sub-tab-title">{this.state.location.name}</div>
                                                <OperationHour operatingHours={this.state.location.operating_hours} />
                                                {this.state.location.clinic_hour_note &&
                                                    <div className="hour-note">*Notes: {this.state.location.clinic_hour_note}</div>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            
                                <hr className="mobile-details-line"/>
    
                                {this.state.doctor.is_using_calendar == true &&
                                    <div className="slots-container">
                                        <div className="schedule-div">
                                            <div className="schedule-title">
                                                Schedule (Walk-in only)
                                            </div>
                                            <div className="schedule-show" onClick={() => this.handleScheduleModal(true)}>Show All</div>
                                        </div>
                                        <div className="mobile-date-bar">
                                            <img src={calendar_icon} />
                                            <div className="date">{moment(this.state.slot_date).format('ddd, DD MMM Y')}</div>
                                        </div>
                                        <div className="slots">
                                            {(() => {
                                                if (this.state.slot_loading) {
                                                    return <div className="slots-loader"><Spinner animation="border" className="spinner"/></div>;
                                                } else {
                                                    return (this.state.slots.length ?
                                                        this.state.slots.map((slot, index) => (
                                                            <button key={`slot-${index}`} className="btn slot valid-slot" onClick={() => this.onSelectedSlot(slot)}>{this.convertEpochToTime(slot.start)}</button>
                                                        )) : <div className="slots-loader">No Slots</div>
                                                    );
                                                }
                                            })()}
                                        </div>
                                    </div>
                                }
                            </div>
    
                            <div className="clinic-details">
                                <div className="contact">
                                        <div className="title">Contact</div>
                                        <div className="details-container">
                                            <div className="icon-col">
                                                <img className="location-pin-outline-icon" src={location_pin_outline_icon} />
                                            </div>
                                            <div className="address-col">
                                                <div className="address-name">{this.state.location.name}</div>
                                                <div className="address">{this.state.location.address}, {[this.state.location.postal, this.state.location.state].filter(Boolean).join(" ")}</div>
                                                <div className="map-container">
                                                    <Map results={[this.state.doctor]} location_friendly_id={this.props.match.params.location_friendly_id} parentName={`ProfessionalProfile`}/>
                                                </div>
                                                <a className="direction-tag" href={this.getDirectionUrl()} target="_blank">Get Direction</a>
                                                <div className="phone-email-container">
                                                    <a href={`tel:${this.state.doctor.phone_number}`}><img className="contact-icon phone" src={phone_icon}/></a>
                                                    <a href={`mailto:${this.state.doctor.email}`}><img className="contact-icon mail" src={mail_icon}/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mobile-details-line"/>
                                    {/* <div className="interest">
                                        <div className="title">You might be interested....</div>
                                        <div className="interest-links">
                                            <a className="link-container" target="_blank" href={`grab://open?screenType=BOOKING&dropOffKeywords=${this.state.location.name}&dropOffAddress=${this.state.location.name}&dropOffLatitude=${this.state.location.lat}&dropOffLongitude=${this.state.location.long}&sourceID=CiFLV3RB7N&sourceAppName=BookDoc`}>
                                                <div className="interest-icon">
                                                    <img src={grab_icon}/>
                                                </div>
                                                <div className="interest-title">Book A Ride</div>
                                            </a>
                                            <div className="link-container" onClick={() => this.handleHotelDialog(true)}>
                                                <div className="interest-icon">
                                                    <img src={world_icon}/>
                                                </div>
                                                <div className="interest-title">Travel Info</div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                       
                                <div className="doctor-details">
                                {/* teleconsult */}
                                {!_.isEmpty(this.state.chat_info) &&
                                    <div className="teleconsult-container">
                                        <div className="teleconsult-lists">
                                            {this.state.chat_info.chat_enabled &&
                                                <a className="btn btn-link teleconsult-btn chat-btn" href={`https://deeplink.bookdoc.com/teleconsult/doctor/${this.state.doctor.id}`} target="_blank">
                                                    <div className="teleconsult-icon">
                                                        <img className="chat-icon" src={chat_icon}/>
                                                    </div>
                                                    <div className="button-info">
                                                        <div className="info-text">Chat</div>
                                                        <div className="info-price">{`${this.state.chat_info.chat_price.currency} ${this.state.chat_info.chat_price.price.toFixed(2)}`}</div>
                                                    </div>
                                                </a>
                                            }
                                            {this.state.chat_info.video_enabled &&
                                                <a className="btn btn-link teleconsult-btn video-btn" href={`https://deeplink.bookdoc.com/teleconsult/doctor/${this.state.doctor.id}`} target="_blank">
                                                    <div className="teleconsult-icon">
                                                        <img className="chat-icon" src={video_icon}/>
                                                    </div>
                                                    <div className="button-info">
                                                        <div className="info-text">Video</div>
                                                        <div className="info-price">{`${this.state.chat_info.chat_price.currency} ${this.state.chat_info.chat_price.video_price.toFixed(2)}`}</div>
                                                    </div>
                                                </a>
                                            }   
                                        </div>
                                    </div>
                                }
                                {/* end teleconsult */}
                            </div>
    
                        </div>
    
                        {userService.getUser() &&
                            <React.Fragment>
                                <VerifyPhoneModal showVerifyPhoneModal={this.state.showVerifyPhoneModal} handleVerifyPhoneModal={this.handleVerifyPhoneModal} handleBookingModal={this.handleBookingModal}/>
                                <BookingModal showBookingModal={this.state.showBookingModal} handleBookingModal={this.handleBookingModal} selected={this.state.appointment} slots={this.state.slots} location={this.state.location} get_range_dates={this.get_range_dates} slot_date={this.state.slot_date} day_diff={this.state.day_diff} setSelectedSlot={this.setSelectedSlot} slot_loading={this.state.slot_loading} onFav={this.onFavClick}/>
                            </React.Fragment>
                        }
                        <React.Fragment>
                            <SignInModal showSignInModal={this.state.showSignInModal} handleSignInModal={this.handleSignInModal} handleSwitchSignUpModal={this.handleSwitchSignUpModal}/>
                            <SignUpModal showSignUpModal={this.state.showSignUpModal} handleSignUpModal={this.handleSignUpModal} handleSwitchSignInModal={this.handleSwitchSignInModal}/>
                            <HotelDialog showHotelDialog={this.state.showHotelDialog} handleHotelDialog={this.handleHotelDialog} doctor={this.state.doctor} location={this.state.location} />
                            <ScheduleModal showScheduleModal={this.state.showScheduleModal} handleScheduleModal={this.handleScheduleModal} slots={this.state.slots} onSelectedSlot={this.onSelectedSlot}  get_range_dates={this.get_range_dates} slot_date={this.state.slot_date} day_diff={this.state.day_diff} slot_loading={this.state.slot_loading}/>
                        </React.Fragment>
                    </div>
    
                );
            };
        }
    }
}

export { ProfessionalProfile };
