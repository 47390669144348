import { authHeader } from '../helpers/auth-header';

export const NotificationService = {
    get
};

function get(){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()},
    };

    return fetch(`${process.env.REACT_APP_API_URL}notifications?page=1`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function handleResponse(response) {
    return response.json();
}