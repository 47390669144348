import React, { Component, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useLocation} from 'react-router-dom';
import { useHistory } from "react-router";

import { userService } from '../services/user';
import AppContext from '../components/AppContext';

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const myContext = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }
    
    function handleSubmit(event) {
        event.preventDefault();

        userService.login(email, password)
        .then(
            user => {
                myContext.setUser(user)

                const { from } = location.state || { from: { pathname: "/search-book" } };
                history.push(from);
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    return (
        <div className="Login">
            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                </Form.Group>
                <Button block size="lg" type="submit" disabled={!validateForm()}>
                Login
                </Button>
            </Form>
        </div>
    )};

export { Login };
