import { stringify } from 'query-string';

export const statisticService = {
    get
};

function get(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header},
    };

    return fetch(`${process.env.REACT_APP_API_URL}statistics?${stringify(params)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function handleResponse(response) {
    if(!response.ok){
        return Promise.reject(response);
    }

    return response.json();
}