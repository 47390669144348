import React, { Component } from "react";
import _ from 'lodash';
import '../assets/styles/Error.scss';

export class Error extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        return (
        <div className="error-div">
            {this.props.message}
        </div>
    )};
}
