import { authHeader } from '../helpers/auth-header';
import { userService} from '../services/user';
import { stringify } from 'query-string';

export const teleconsultService = {
    getDocChatInfo,
};

function getDocChatInfo(doctor_id){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: userService.getUser() ? {...header,...authHeader()} : {...header}
    };

    return fetch(`${process.env.REACT_APP_API_URL}doctor_chat_infos/${doctor_id}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // window.location.reload(true);
        }
        const error = (response && response.message) || response.statusText;
        if(error.length == 0){
            error = 'Internal Server Error'
        }
        return Promise.reject(error);
    }

    return response.json();
}