import React, { Component } from "react";

import { userService} from '../services/user';
import { appointmentService } from '../services/appointment';

import '../assets/styles/UpcomingAppointment.scss';
import _ from 'lodash';
import  { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import { AppointmentCard } from "./AppointmentCard";

class PastAppointment extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            appointments: [],
            page_no: 1,
            per_page: 10,
            type: 'past',
            meta: {},
        }
        this.handleOnFav = this.handleOnFav.bind(this);
        this.setPageNo = this.setPageNo.bind(this);
    }

    getAppointments(pageNo){
        this.setState({
            loading: true
        });
        let params = {
            type: this.state.type,
            per_page: this.state.per_page,
            page: pageNo
        }
        
        appointmentService.get(params)
        .then(
            appointments => {
                if(appointments){
                    this.setState({
                        meta: appointments.meta,
                        appointments: appointments.appointments
                    });
                }
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        ).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    setPageNo(page_no){
        this.setState({page_no});
        this.getAppointments(page_no);
    }

    componentDidMount(){
        if(userService.getUser()){
           this.getAppointments(this.state.page_no);
        }
    }

    handleOnFav(appointment_id, isFav){
        let appointment = _.find(this.state.appointments, ['id', appointment_id]);
        _.set(appointment, 'location.favorited', !isFav);
        this.setState({
            appointments: this.state.appointments.map(el => (el.id === appointment_id ? appointment : el))
        });
    }
    
    render() {
        if (_.isEmpty(userService.getUser()))
            return <Redirect to='/search-book'  />;

        return (
            <div className="upcoming-appointment-page container">
                <div className="header">
                    <div className="title">Past Appointments</div>
                    <div className="bread-crumb">Home / Past Appointments</div>
                </div>
                <hr className="header-line"/>
                <div className="body">
                    {(() => {
                        if (this.state.loading) {
                            return <div className="loader"><Spinner animation="border" className="spinner"/></div>;
                        } else {
                            return (!_.isEmpty(this.state.appointments) ?
                                <AppointmentCard appointments={this.state.appointments} meta={this.state.meta} page_no={this.state.page_no} type={this.state.type} handleOnFav={this.handleOnFav}  setPageNo={this.setPageNo}/> : <div className="empty-div">No Appointment</div>
                                );
                        }
                    })()}
                </div>
            </div>
        );
    }
}

export { PastAppointment };
