import React, { Component } from "react";
import _ from 'lodash';
import map_icon from '../assets/images/map-icon.png';
import location_pin_outline_icon from '../assets/images/location-pin-outline-icon.png';
import moment from 'moment';
import default_avatar from '../assets/images/avatar-default.png';
import { TimeSlots } from "./TimeSlots";
import Spinner from 'react-bootstrap/Spinner';

import unfav from '../assets/images/fav-unfill.png'; 
import fav from '../assets/images/fav-fill.png'; 
import mail_icon from '../assets/images/mail_icon.png';
import phone_icon from '../assets/images/phone_icon.png';
import walk_in_icon from '../assets/images/walk_in_icon.png'; 
import video_icon from '../assets/images/video_colored_icon.png'; 
import chat_icon from '../assets/images/chat_icon_dual_color.png'; 

import { userService} from '../services/user';
import { favouriteService } from '../services/favourite';
import { slotService } from '../services/slot';

import { BookingModal } from "./BookingModal";
import { SignInModal } from "./SignInModal";
import { SignUpModal } from "./SignUpModal";
import { VerifyPhoneModal } from "./VerifyPhoneModal";

export class ResultListView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      slot_date: moment().startOf('day'),
      booking_modal_day_diff: 0,
      start_date: _.cloneDeep(this.props.start_date),
      track_prop_start_date: this.props.start_date,
      range_number: 0,
      range_dates: [],
      today_date: moment().startOf('day'),
      day_diff: 0,
      location: {},
      slots: [],
      appointment: {
        from: 'result_list',
        slot: {},
        doctor: {}
      }
    };

    this.handleVerifyPhoneModal = this.handleVerifyPhoneModal.bind(this);
    this.handleBookingModal = this.handleBookingModal.bind(this);
    this.handleSignInModal = this.handleSignInModal.bind(this);
    this.handleSignUpModal = this.handleSignUpModal.bind(this);

    this.get_range_dates = this.get_range_dates.bind(this);
    this.setSelectedSlot = this.setSelectedSlot.bind(this);
    this.onSelectedSlot = this.onSelectedSlot.bind(this);

    this.get_booking_modal_range_dates = this.get_booking_modal_range_dates.bind(this);

    this.handleOnFav = this.handleOnFav.bind(this);
  }

  handleBookingModal = (isOpen) => this.setState({ showBookingModal: isOpen });
  handleVerifyPhoneModal = (isOpen) => this.setState({ showVerifyPhoneModal: isOpen });
  handleSignInModal = (isOpen) => this.setState({ showSignInModal: isOpen });
  handleSignUpModal = (isOpen) => this.setState({ showSignUpModal: isOpen });
  handleSwitchSignInModal  = (isOpen) => this.setState({ showSignInModal: isOpen, showSignUpModal: !isOpen  });
  handleSwitchSignUpModal  = (isOpen) => this.setState({ showSignInModal: !isOpen, showSignUpModal: isOpen });

  get show() {
    return this.props.activeView === "list_view";
  }

  onSelectedSlot(slot,doctor){
    this.setSelectedSlotAndDoctor(slot,doctor);

    this.getBookingModalSlots(doctor.location.id,moment(this.state.slot_date).format('YYYYMMDD'))

    userService.getUser() ? (userService.getUser().verified ? this.handleBookingModal(true) : this.handleVerifyPhoneModal(true)) : this.handleSignInModal(true);
  }

  setSelectedSlotAndDoctor(slot,doctor){
    this.setState(prevState => ({
      location: doctor.location,
      appointment: {
          ...prevState.appointment,
          doctor: doctor,
          slot: slot
      }
    }));
  }

  setSelectedSlot(slot){
      this.setState(prevState => ({
        appointment: {
            ...prevState.appointment,
            slot: slot
        }
      }));
  }

  /* for booking modal */
  get_booking_modal_range_dates(is_increase){
      let current_date = moment(this.state.slot_date).startOf('day')

      if(is_increase){
          current_date = moment(current_date).add(1, 'day');
      }else{
          current_date = current_date.subtract(1, "days");
      }

      this.setState({
        slot_date: current_date,
        day_diff: current_date.diff(moment(this.state.today_date), 'days')
      })
      
    this.getBookingModalSlots(this.state.location.id, moment(current_date).format('YYYYMMDD'))
  }

  getBookingModalSlots(location_id, date){
    let params = {
        location: location_id,
        date: date,
        around: 1
    }
    slotService.get(params)
    .then(
        data => {
            this.setState({ 
              slots: data
            })
        },
        error => {
            console.log(error)
            this.setState({ error })
        }
    );
  }
  /* end booking modal */

  get_range_dates(is_increase){

    let days = []
    let start_date = moment(this.state.range_dates[0]).startOf('day')
    let day_diff = this.state.day_diff

    if(is_increase){
      start_date = moment(this.state.range_dates[2]).startOf('day').add(1, "days")
      day_diff = start_date.diff(moment(this.state.today_date), 'days')
    }else{
      if(this.state.day_diff < 3){
        day_diff = 0;
        start_date = this.state.today_date
  
      }else{
        start_date = start_date.subtract(3, "days");
        day_diff = start_date.diff(moment(this.state.today_date), 'days')
      }
    }

    for (var i = 0; i < 3; i++) {
      days.push(moment().add(day_diff+i, 'day').toDate());
    }

    this.setState({
      start_date: start_date,
      day_diff: day_diff,
      range_dates: days
    })
    
   this.props.onGetResultsBasedOnDate(moment(days[0]).format('DD-MM-YYYY'), true)
  }

  componentDidMount(){
   this.default_range_dates();
  }
  componentDidUpdate(){
    if(this.props.start_date != null && this.props.start_date != this.state.track_prop_start_date){
      this.setState({
        track_prop_start_date: this.props.start_date
      }, () => {
        this.default_range_dates();
      })
    }
  }

  default_range_dates(){
    let days = []
    let _clone_date = _.cloneDeep(this.state.track_prop_start_date)
    if(_clone_date.format('YYYY-MM-DD') > this.state.today_date.format('YYYY-MM-DD')){
      _clone_date = _clone_date.subtract(1, 'day');
    }
    let _day_diff = moment(_clone_date).startOf('day').diff(moment(this.state.today_date), 'days')

    for (var i = 0; i < 3; i++) {
      days.push(moment().add(_day_diff+i, 'day').toDate());
    }

    this.setState({
      start_date: _clone_date,
      day_diff: _day_diff,
      range_dates: days
    })

    this.props.onGetResultsBasedOnDate(moment(days[0]).format('DD-MM-YYYY'))
  }

  onFavClick(location_id, doctor_id, isFav){

    let params = {
        practice_location_id: location_id
    }

    favouriteService.favourite(isFav, params)
    .then(
        data => {
            this.props.onFav(doctor_id, isFav, location_id)
        },
        error => {
            console.log(error)
            this.setState({ error })
        }
    );
  }

  handleOnFav(doctor_id, isFav, location_id){
    let doc = this.state.appointment.doctor;
    doc.favorited = !isFav;

    this.setState(prevState => ({
      appointment: {
          ...prevState.appointment,
          doctor: doc
      }
    }));

    this.props.onFav(doctor_id, isFav, location_id);
  }

  render() {

    if (this.show) {
      return (
        <React.Fragment>
            <div className="list-header">
                <div className="result-number">
                  {_.isEmpty(this.props.results) ? 0 : this.props.meta.total_count} Results
                </div>
                <div className="date-bar">
                    {this.state.day_diff > 0 && 
                      <button className="btn btn-link previous" onClick={() => this.get_range_dates(0)}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                    }
                    <div className="dates">
                    {
                      this.state.range_dates.map((item, index) => (
                        <div key={`date-${index}`} className="date-item">
                          <div className="day-name">{moment(item, 'DD-MM-YYYY').format('ddd')}</div>
                          <div className="day-date">{moment(item, 'DD-MM-YYYY').format('MMM DD')}</div>
                        </div>
                      ))
                    }
                    </div>
                    <button className="btn btn-link next" onClick={() => this.get_range_dates(1)}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                </div>
            </div>
            <div className="list-group">
              {
                this.props.results.map((item, index) => (
                      <div className="list-item" key={`result-${index}`}>
                        <div className="details-container">                
                          <div className="profile-pic">
                            <img src={`${item.avatar ? (item.avatar.url.thumb.url) : default_avatar}`} onError={(e) => {
                              e.target.src = default_avatar }} />  
                            <div className="prof-feature">
                              {item.is_using_calendar == true && 
                                  <div className="feature"><img src={walk_in_icon} className="consultation-icon"/>Walk-in</div>
                              }
                              {(_.has(item, 'chat_enabled') && item.chat_enabled) &&
                                  <div className="feature"><img src={chat_icon} className="consultation-icon"/> Chat</div>
                              }
                              {(_.has(item, 'video_enabled') && item.video_enabled) &&
                                  <div className="feature"><img src={video_icon} className="consultation-icon"/> Video Call</div>
                              }
                            </div>
                          </div>
                          <div className="details">
                            <div className="name-row">
                            <a className="name" href={`/search-book/professional-profile/${item.friendly_id}/${item.location.friendly_id}`}>{item.title || '' } {item.name}</a>
                              {userService.getUser() && 
                                <div className="favourite-icon" onClick={() => this.onFavClick(item.location.id, item.id,item.favorited)}>{ item.favorited ? <img src={fav}/> : <img src={unfav}/> }</div>
                              }
                            </div>
                            <div className="specialty">{item.specialties[0].name}</div>
                            <div className="feature-tags">
                              {item.is_using_calendar == true && 
                                <div className="feature-tag online-booking">Online Booking</div>
                              }
                              {(!_.isEmpty(userService.getUser()) && _.includes(item.employer_ids, _.isEmpty(userService.getUser().employee) ? null : userService.getUser().employee.employer_id)) && 
                                <div className="feature-tag corporate-panel">Corporate Panel</div>
                              }
                            </div>
                            <div className="address-container">
                              <div className="icon-col">
                                <img className="location-pin-outline-icon" src={location_pin_outline_icon} />
                              </div>
                              <div className="address-col">
                              <a href={`/search-book/clinic/${item.location.friendly_id}`} className="address-name">{item.location.name}</a>
                                <div className="address">{item.location.address}</div>
                                <div className="address">{[item.location.zipcode, item.location.state].filter(Boolean).join(" ")}</div>
                                <div className="address">{item.location.country}</div>
                              </div>
                            </div>
                            <div className="contact-container">
                            {item.is_using_calendar == true ? 
                                <a className="btn btn-link book-online-btn" href={`/search-book/professional-profile/${item.friendly_id}/${item.location.friendly_id}`}>Book Online</a> : <div></div>
                            }
                            <div className="phone-email-container">
                                <a href={`tel:${item.phone_number}`}><img className="contact-icon phone" src={phone_icon}/></a>
                                <a href={`mailto:${item.email}`}><img className="contact-icon mail" src={mail_icon}/></a>
                            </div>
                        </div>
                          </div>
                        </div>
                        <div className="vertical-line"></div>
                        {(() => {
                          if (this.props.loading_result) {
                            return <div className="slots-loader"><Spinner animation="border" className="spinner"/></div>;
                          } else {
                            return (
                              (() => {
                                if(!("slots" in item) || !item.slots.length){
                                  return <div className="time-container">
                                    <div className="empty-slots">No Slots</div>
                                  </div>
                                }else{
                                  return <div className="time-container">
                                    <TimeSlots dateRange={this.state.range_dates} timeSlots={item.slots} doctor={item} onSelectedSlot={this.onSelectedSlot}/>
                                    <div className="availability-row">
                                      <a href={`/search-book/professional-profile/${item.friendly_id}/${item.location.friendly_id}`} className="availability-btn">Next Availability</a>
                                    </div>
                                  </div>
                                }
                              })()
                            );
                          }
                        })()}
                      </div>
                    ))
              }
            </div>

            {userService.getUser() &&
                <React.Fragment>
                    <VerifyPhoneModal showVerifyPhoneModal={this.state.showVerifyPhoneModal} handleVerifyPhoneModal={this.handleVerifyPhoneModal} handleBookingModal={this.handleBookingModal}/>
                    <BookingModal showBookingModal={this.state.showBookingModal} handleBookingModal={this.handleBookingModal} selected={this.state.appointment} slots={this.state.slots} location={this.state.location} get_range_dates={this.get_booking_modal_range_dates} slot_date={this.state.slot_date} day_diff={this.state.booking_modal_day_diff} setSelectedSlot={this.setSelectedSlot} onFav={this.handleOnFav}/>
                </React.Fragment>
            }
            {_.isEmpty(userService.getUser()) &&
              <React.Fragment>
                <SignInModal showSignInModal={this.state.showSignInModal} handleSignInModal={this.handleSignInModal} handleSwitchSignUpModal={this.handleSwitchSignUpModal}/>
                <SignUpModal showSignUpModal={this.state.showSignUpModal} handleSignUpModal={this.handleSignUpModal} handleSwitchSignInModal={this.handleSwitchSignInModal}/>
              </React.Fragment>
            }
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}