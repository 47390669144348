import { authHeader } from '../helpers/auth-header';

export const pinService = {
    generate,
    verify
};


function generate(){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}pins/generate`, requestOptions)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function verify(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'PUT',
        headers: {...header,...authHeader()},
        body: JSON.stringify(params)
    };

    return fetch(`${process.env.REACT_APP_API_URL}pins/verify`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if(data.status == 'Verify Failed'){
                return false;
            }else if(data.status == 'Success'){
                return true;
            }
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401 || response.status === 422) {
            // auto logout if 401 response returned from api
            return response.json();
        }else{
            const error = (response && response.message) || response.statusText;
            if(error.length == 0){
                error = 'Internal Server Error'
            }
            return Promise.reject(error);
        }
    }

    return response.json();
}
