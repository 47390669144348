import React, { Component, useState } from "react";
import { userService } from '../services/user';
import { NotificationService } from '../services/notification';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class UnauthorizedDialog extends Component {
    constructor(props) {
      super(props);
      this.state = {
          open: false
      };
    }

    getNotification(){
        NotificationService.get()
        .then(
            data => {
                if(_.has(data,'errors')){
                    this.handleOpen();
                }
            },
            error => {
                console.log(error)
            }
        );
    }

    show(){
        let _open = true;
        if(window.sessionStorage.getItem("unauthorized"))
            window.sessionStorage.removeItem("unauthorized");
        else{
            _open = this.state.open;
        }
        return _open;
    }

    handleClose = () => this.setState({ open: false });
    handleOpen = () => this.setState({ open: true });

    render() {
        return (
            <Dialog
                open={this.show()}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unauthorized"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Unauthorized. Please log in again, or contact BookDoc admin.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                    Ok
                </Button>
                </DialogActions>
            </Dialog>
        )};
    }

export { UnauthorizedDialog };
