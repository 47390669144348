import React, { Component } from 'react';
import '../assets/styles/Footer.scss';

class Footer extends Component {
    render() {
        return (
            <div className="container-fluid mx-auto footer">
                <div className="row footer-row-content">
                    <div className="d-md-flex justify-content-around bd-highlight col-md-12 footer-content">
                        <div className="bd-highlight footer-content-menu">
                            <div className="footer-title">About BookDoc</div>
                            <ul className="footer-list">
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/`}>About Us</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/milestone-achievements`}>Milestone Achievements</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/meet-our-people/`}>Meet Our People</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/services`}>Services</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/newsroom`}>Newsroom</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/careers`}>Careers</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/newsroom/awards/`}>Awards</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/testimonials/`}>Testimonials</a></li>
                            </ul>
                        </div>
                        <div className="bd-highlight footer-content-menu">
                            <div className="footer-title">Directory</div>
                            <ul className="footer-list">
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/`}>Healthcare Professionals</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/`}>Service/Procedure</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/`}>Locations</a></li>
                            </ul>
                        </div>
                        <div className="bd-highlight footer-content-menu">
                            <div className="footer-title">Common Searches</div>
                            <ul className="footer-list">
                                <li><a href={`/../search-book/result?specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>General Practitioner (GP Doctor)</a></li>
                                <li><a href={`/../search-book/result?specialty=Dentist%20(General)&type=specialty`}>Dentist (General)</a></li>
                                <li><a href={`/../search-book/result?specialty=Cardiology&type=specialty`}>Cardiology</a></li>
                                <li><a href={`/../search-book/result?specialty=Dermatology&type=specialty`}>Dermatology</a></li>
                                <li><a href={`/../search-book/result?specialty=Gastroenterology%20%26%20Hepatology&type=specialty`}>Gastroenterology & Hepatology</a></li>
                                <li><a href={`/../search-book/result?specialty=General%20Paediatrics&type=specialty`}>General Paediatrics</a></li>

                            </ul>
                        </div>
                        <div className="bd-highlight footer-content-menu">
                            <div className="footer-title">&nbsp;</div>
                            <ul className="footer-list">
                                <li><a href={`/../search-book/result?specialty=Obstetrics%20and%20Gynaecology%20(O%26G)&type=specialty`}>Obstetrics and Gynaecology (O&G)</a></li>
                                <li><a href={`/../search-book/result?specialty=Ophthalmology&type=specialty`}>Ophthalmology</a></li>
                                <li><a href={`/../search-book/result?specialty=Otorhinolaryngology%20(ENT)&type=specialty`}>Otorhinolaryngology (ENT)</a></li>
                                <li><a href={`/../search-book/result?specialty=Physiotherapy&type=specialty`}>Physiotherapy</a></li>
                                <li><a href={`/../search-book/result?specialty=Chiropractic&type=specialty`}>Chiropractic</a></li>
                                <li><a href={`/../search-book/result?specialty=Psychiatry&type=specialty`}>Psychiatry</a></li>
                            </ul>
                        </div>
                        <div className="bd-highlight footer-content-menu">
                            <div className="footer-title">Cities</div>
                            <ul className="footer-list">
                                <li><a href={`/../search-book/result?lat=3.1569486&location=Kuala%20Lumpur&long=101.71230300000002&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Kuala Lumpur</a></li>
                                <li><a href={`/../search-book/result?lat=3.1278871&location=Petaling%20Jaya&long=101.59448850000001&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Petaling Jaya</a></li>
                                <li><a href={`/../search-book/result?lat=3.081213&location=Subang%20Jaya&long=101.5844108&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Subang Jaya</a></li>
                                <li><a href={`/../search-book/result?lat=3.073281&location=Shah%20Alam&long=101.518461&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Shah Alam</a></li>
                                <li><a href={`/../search-book/result?lat=2.926361&location=Putrajaya&long=101.69644500000004&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Putrajaya</a></li>
                                <li><a href={`/../search-book/result?lat=3.044917&location=Klang&long=101.44556209999996&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Klang</a></li>
                            </ul>
                        </div>
                        <div className="bd-highlight footer-content-menu">
                            <div className="footer-title">&nbsp;</div>
                            <ul className="footer-list">
                                <li><a href={`/../search-book/result?lat=5.4141675&location=Penang&long=100.32875890000003&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Penang</a></li>
                                <li><a href={`/../search-book/result?lat=6.1248&location=Alor%20Setar&long=100.36781780000001&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Alor Setar</a></li>
                                <li><a href={`/../search-book/result?lat=4.597479&location=Ipoh&long=101.09010599999999&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Ipoh</a></li>
                                <li><a href={`/../search-book/result?lat=1.492659&location=Johor%20Bahru&long=103.74135909999995&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Johor Bahru</a></li>
                                <li><a href={`/../search-book/result?lat=1.352083&location=Singapore&long=103.81983600000001&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Singapore</a></li>
                                <li><a href={`/../search-book/result?lat=22.396428&location=Hong%20Kong&long=114.10949700000003&specialty=General%20Practitioner%20(GP%20Doctor)&type=specialty`}>Hong Kong</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row bottom-bar">
                    <div className="d-lg-flex justify-content-between bd-highlight col-md-12 bottom-bar-content">
                        <div className="align-self-center bd-highlight copyright">
                            © 2021 BookDoc
                        </div>
                        <div className="align-self-center flex-fill bd-highlight text-right bottom-bar-list-container">
                            <ul className="bottom-bar-list">
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/terms-of-use/`}>Terms of Use</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/privacy-policy/`}>Privacy Policy</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/contact-us`}>Contact Us</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/faqs`}>FAQs</a></li>
                                <li><a href={`${process.env.REACT_APP_MAIN_SITE_URL}/download-bookdoc-app/`}>Download BookDoc App</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    )};
}

export { Footer };
