module.exports = global.constant = {
    minAge: 13,
    minYear: 1900,
    password: {
      minLength: 6
    },
    searchTextBox: {
      minLength: 2
    },
    calendar: {
      maxTimeRange: 12
    },
    genders: [
      {
        label: "Male",
        value: "male"
      },
      {
        label: "Female",
        value: "female"
      }
    ],
    seenDoctors: [
      {
        label: "New Patient",
        value: false
      },{
        label: "Patient has seen the doctor before",
        value: true
      }
    ],
    visitors:[
      {
        label: "Me",
        value: true
      },{
        label: "Someone Else",
        value: false
      }
    ],
    coverages: [
      {
        label: 'I\'m paying by myself',
        value: 1
      },
      {
        label: 'Not Applicable - Voucher Redemption',
        value: 2
      }
    ],
    partners: [
      {
        name: 'Agoda',
        propertyName: 'agoda',
        iconName: 'agoda'
      },
      {
        name: 'Berjaya',
        propertyName: 'berjaya',
        iconName: 'berjaya'
      },
      {
        name: 'Swiss Garden',
        propertyName: 'swissGarden',
        iconName: 'swiss-garden'
      },
      {
        name: 'GTower Hotel',
        propertyName: 'gTowerHotel',
        iconName: 'gtower'
      },
      {
        name: 'Airbnb',
        propertyName: 'airbnb',
        iconName: 'airbnb'
      }
    ],
    available_countries: [
      {
        label: 'Available Countries',
        type: 'available_country',
        options: [{
            name: 'Malaysia',
            lat: 3.145265,
            long: 101.693675,
            type: 'available_country'
          },
          {
            name: 'Singapore',
            lat: 1.308384,
            long: 103.837761,
            type: 'available_country'
          },
          {
            name: 'Hong Kong',
            lat: 22.278884,
            long: 114.159465,
            type: 'available_country'
          },
          {
            name: 'Thailand',
            lat: 13.749262,
            long: 100.491496,
            type: 'available_country'
          }
        ]
      }
    ],
    featured_heathcare_providers: [
        "https://sites.bookdoc.com/wp-content/uploads/2020/08/MOH-logo01-300x136.jpg",
        "https://sites.bookdoc.com/wp-content/uploads/2020/08/education01-300x136.jpg",
        "https://sites.bookdoc.com/wp-content/uploads/2020/08/ssm-logo-300x136.jpg",
        "https://sites.bookdoc.com/wp-content/uploads/2020/08/hrdf-logo-300x136.jpg",
        "https://sites.bookdoc.com/wp-content/uploads/2020/07/partner-tnb-300x137.jpg",
        "https://sites.bookdoc.com/wp-content/uploads/2020/07/partner-axa-300x137.jpg"
    ],
    relationships: [
      {
        label: 'Child',
        value: 'Child',
        enabled: true
      },
      {
        label: 'Parents',
        value: 'Parents',
        enabled: true
      },
      {
        label: 'Spouse',
        value: 'Spouse',
        enabled: true
      }
    ],
    time_options: [{
        label: 'AM',
        value: 'AM'
      },{
          label: 'PM',
          value: 'PM'
      },{
          label: 'Both',
          value: ''
      }
    ],
    feature_options: [{
      label: 'All',
      value: ''
    },{
        label: 'Online Booking',
        value: 'online-booking'
    },{
        label: 'Chat',
        value: 'chat'
    },{
      label: 'Video Call',
      value: 'video-call'
    }],
    default_country_code: "MY",
    countries: [
      {
        "name":"Afghanistan",
        "code":"AF",
        "dial_code":"+93"
      },
      {
        "name":"Albania",
        "code":"AL",
        "dial_code":"+355"
      },
      {
        "name":"Algeria",
        "code":"DZ",
        "dial_code":"+213"
      },
      {
        "name":"American Samoa",
        "code":"AS",
        "dial_code":"+1-684"
      },
      {
        "name":"Andorra",
        "code":"AD",
        "dial_code":"+376"
      },
      {
        "name":"Angola",
        "code":"AO",
        "dial_code":"+244"
      },
      {
        "name":"Anguilla",
        "code":"AI",
        "dial_code":"+1-264"
      },
      {
        "name":"Antarctica",
        "code":"AQ",
        "dial_code":"+672"
      },
      {
        "name":"Antigua and Barbuda",
        "code":"AG",
        "dial_code":"+1-268"
      },
      {
        "name":"Argentina",
        "code":"AR",
        "dial_code":"+54"
      },
      {
        "name":"Armenia",
        "code":"AM",
        "dial_code":"+374"
      },
      {
        "name":"Aruba",
        "code":"AW",
        "dial_code":"+297"
      },
      {
        "name":"Australia",
        "code":"AU",
        "dial_code":"+61"
      },
      {
        "name":"Austria",
        "code":"AT",
        "dial_code":"+43"
      },
      {
        "name":"Azerbaijan",
        "code":"AZ",
        "dial_code":"+994"
      },
      {
        "name":"Bahamas",
        "code":"BS",
        "dial_code":"+1-242"
      },
      {
        "name":"Bahrain",
        "code":"HB",
        "dial_code":"+973"
      },
      {
        "name":"Bangladesh",
        "code":"BD",
        "dial_code":"+880"
      },
      {
        "name":"Barbados",
        "code":"BB",
        "dial_code":"+1-264"
      },
      {
        "name":"Belarus",
        "code":"BY",
        "dial_code":"+375"
      },
      {
        "name":"Belgium",
        "code":"BE",
        "dial_code":"+32"
      },
      {
        "name":"Belize",
        "code":"BZ",
        "dial_code":"+501"
      },
      {
        "name":"Benin",
        "code":"BJ",
        "dial_code":"+229"
      },
      {
        "name":"Bermuda",
        "code":"BM",
        "dial_code":"+1-441"
      },
      {
        "name":"Bhutan",
        "code":"BT",
        "dial_code":"+975"
      },
      {
        "name":"Bolivia",
        "code":"BO",
        "dial_code":"+591"
      },
      {
        "name":"Bosnia and Herzegovina",
        "code":"BA",
        "dial_code":"+387"
      },
      {
        "name":"Botswana",
        "code":"BW",
        "dial_code":"+267"
      },
      {
        "name":"Bouvet Island",
        "code":"BV",
        "dial_code":"+47"
      },
      {
        "name":"Brazil",
        "code":"BR",
        "dial_code":"+55"
      },
      {
        "name":"British Indian Ocean Territory",
        "code":"IO",
        "dial_code":"+246"
      },
      {
        "name":"British Virgin Islands",
        "code":"VG",
        "dial_code":"+1-284"
      },
      {
        "name":"Brunei",
        "code":"BN",
        "dial_code":"+673"
      },
      {
        "name":"Bulgaria",
        "code":"BG",
        "dial_code":"+359"
      },
      {
        "name":"Burkina Faso",
        "code":"BF",
        "dial_code":"+226"
      },
      {
        "name":"Burundi",
        "code":"BI",
        "dial_code":"+257"
      },
      {
        "name":"Cabo Verde",
        "code":"CV",
        "dial_code":"+238"
      },
      {
        "name":"Cambodia",
        "code":"KH",
        "dial_code":"+855"
      },
      {
        "name":"Cameroon",
        "code":"CM",
        "dial_code":"+237"
      },
      {
        "name":"Canada",
        "code":"CA",
        "dial_code":"+1"
      },
      {
        "name":"Cayman Islands",
        "code":"KY",
        "dial_code":"+1-345"
      },
      {
        "name":"Central African Republic",
        "code":"CF",
        "dial_code":"+236"
      },
      {
        "name":"Chad",
        "code":"TD",
        "dial_code":"+235"
      },
      {
        "name":"Chile",
        "code":"CL",
        "dial_code":"+56"
      },
      {
        "name":"China",
        "code":"CN",
        "dial_code":"+86"
      },
      {
        "name":"Christmas Island",
        "code":"CX",
        "dial_code":"+61"
      },
      {
        "name":"Cocos (Keeling) Islands",
        "code":"CC",
        "dial_code":"+61"
      },
      {
        "name":"Colombia",
        "code":"CO",
        "dial_code":"+57"
      },
      {
        "name":"Comoros",
        "code":"KM",
        "dial_code":"+269"
      },
      {
        "name":"Congo",
        "code":"CG",
        "dial_code":"+242"
      },
      {
        "name":"Cook Islands",
        "code":"CK",
        "dial_code":"+682"
      },
      {
        "name":"Costa Rica",
        "code":"CR",
        "dial_code":"+506"
      },
      {
        "name":"Côte d’Ivoire",
        "code":"CI",
        "dial_code":"+225"
      },
      {
        "name":"Croatia",
        "code":"HR",
        "dial_code":"+385"
      },
      {
        "name":"Cuba",
        "code":"CU",
        "dial_code":"+53"
      },
      {
        "name":"Cyprus",
        "code":"CY",
        "dial_code":"+357"
      },
      {
        "name":"Czech Republic",
        "code":"CZ",
        "dial_code":"+420"
      },
      {
        "name":"Denmark",
        "code":"DK",
        "dial_code":"+45"
      },
      {
        "name":"Djibouti",
        "code":"DJ",
        "dial_code":"+253"
      },
      {
        "name":"Dominica",
        "code":"DM",
        "dial_code":"+1-767"
      },
      {
        "name":"Dominican Republic",
        "code":"DO",
        "dial_code":"+1-809"
      },
      {
        "name":"Dominican Republic",
        "code":"DO",
        "dial_code":"+1-829"
      },
      {
        "name":"Dominican Republic",
        "code":"DO",
        "dial_code":"+1-849"
      },
      {
        "name":"Ecuador",
        "code":"EC",
        "dial_code":"+593"
      },
      {
        "name":"Egypt",
        "code":"EG",
        "dial_code":"+20"
      },
      {
        "name":"El Salvador",
        "code":"SV",
        "dial_code":"+503"
      },
      {
        "name":"Equatorial Guinea",
        "code":"GQ",
        "dial_code":"+240"
      },
      {
        "name":"Eritrea",
        "code":"ER",
        "dial_code":"+291"
      },
      {
        "name":"Estonia",
        "code":"EE",
        "dial_code":"+372"
      },
      {
        "name":"Ethiopia",
        "code":"ET",
        "dial_code":"+251"
      },
      {
        "name":"Falkland Islands",
        "code":"FK",
        "dial_code":"+500"
      },
      {
        "name":"Fiji",
        "code":"FJ",
        "dial_code":"+679"
      },
      {
        "name":"Finland",
        "code":"FI",
        "dial_code":"+358"
      },
      {
        "name":"France",
        "code":"FR",
        "dial_code":"+33"
      },
      {
        "name":"French Guiana",
        "code":"GF",
        "dial_code":"+594"
      },
      {
        "name":"French Polynesia",
        "code":"PF",
        "dial_code":"+689"
      },
      {
        "name":"Gabon",
        "code":"GA",
        "dial_code":"+241"
      },
      {
        "name":"Gambia",
        "code":"GM",
        "dial_code":"+220"
      },
      {
        "name":"Georgia",
        "code":"GE",
        "dial_code":"+995"
      },
      {
        "name":"Germany",
        "code":"DE",
        "dial_code":"+49"
      },
      {
        "name":"Ghana",
        "code":"GH",
        "dial_code":"+233"
      },
      {
        "name":"Gibraltar",
        "code":"GI",
        "dial_code":"+350"
      },
      {
        "name":"Greece",
        "code":"GR",
        "dial_code":"+30"
      },
      {
        "name":"Greenland",
        "code":"GL",
        "dial_code":"+299"
      },
      {
        "name":"Grenada",
        "code":"GD",
        "dial_code":"+1-473"
      },
      {
        "name":"Guadeloupe",
        "code":"GU",
        "dial_code":"+1-671"
      },
      {
        "name":"Guatemala",
        "code":"GT",
        "dial_code":"+502"
      },
      {
        "name":"Guinea",
        "code":"GN",
        "dial_code":"+224"
      },
      {
        "name":"Guinea-Bissau",
        "code":"GW",
        "dial_code":"+245"
      },
      {
        "name":"Guyana",
        "code":"GY",
        "dial_code":"+592"
      },
      {
        "name":"Haiti",
        "code":"HT",
        "dial_code":"+509"
      },
      {
        "name":"Heard Island and McDonald Islands",
        "code":"HM",
        "dial_code":"+334"
      },
      {
        "name":"Holy See (Vatican City)</ +504",
        "code":"VA",
        "dial_code":"+379"
      },
      {
        "name":"Hong Kong",
        "code":"HK",
        "dial_code":"+854"
      },
      {
        "name":"Hungary",
        "code":"HU",
        "dial_code":"+36"
      },
      {
        "name":"Iceland",
        "code":"IS",
        "dial_code":"+354"
      },
      {
        "name":"India",
        "code":"IN",
        "dial_code":"+91"
      },
      {
        "name":"Indonesia",
        "code":"ID",
        "dial_code":"+62"
      },
      {
        "name":"Iran",
        "code":"IR",
        "dial_code":"+98"
      },
      {
        "name":"Iraq",
        "code":"IQ",
        "dial_code":"+964"
      },
      {
        "name":"Ireland",
        "code":"IE",
        "dial_code":"+353"
      },
      {
        "name":"Israel",
        "code":"IL",
        "dial_code":"+972"
      },
      {
        "name":"Italy",
        "code":"IT",
        "dial_code":"+39"
      },
      {
        "name":"Jamaica",
        "code":"JM",
        "dial_code":"+1-876"
      },
      {
        "name":"Japan",
        "code":"JP",
        "dial_code":"+81"
      },
      {
        "name":"Jordan",
        "code":"JO",
        "dial_code":"+962"
      },
      {
        "name":"Kazakhstan",
        "code":"KZ",
        "dial_code":"+7"
      },
      {
        "name":"Kenya",
        "code":"KE",
        "dial_code":"+354"
      },
      {
        "name":"Kiribati",
        "code":"KI",
        "dial_code":"+686"
      },
      {
        "name":"Korea",
        "code":"KR",
        "dial_code":"+82"
      },
      {
        "name":"Kuwait",
        "code":"KW",
        "dial_code":"+965"
      },
      {
        "name":"Kyrgyzstan",
        "code":"KG",
        "dial_code":"+996"
      },
      {
        "name":"Laos",
        "code":"LA",
        "dial_code":"+856"
      },
      {
        "name":"Latvia",
        "code":"LV",
        "dial_code":"+371"
      },
      {
        "name":"Lebanon",
        "code":"LB",
        "dial_code":"+961"
      },
      {
        "name":"Lesotho",
        "code":"LS",
        "dial_code":"+266"
      },
      {
        "name":"Liberia",
        "code":"LR",
        "dial_code":"+231"
      },
      {
        "name":"Libya",
        "code":"LY",
        "dial_code":"+218"
      },
      {
        "name":"Liechtenstein",
        "code":"LI",
        "dial_code":"+423"
      },
      {
        "name":"Luxembourg",
        "code":"LU",
        "dial_code":"+352"
      },
      {
        "name":"Macao SAR",
        "code":"MO",
        "dial_code":"+853"
      },
      {
        "name":"Macedonia",
        "code":"MK",
        "dial_code":"+807"
      },
      {
        "name":"Madagascar",
        "code":"MG",
        "dial_code":"+450"
      },
      {
        "name":"Malawi",
        "code":"MW",
        "dial_code":"+454"
      },
      {
        "name":"Malaysia",
        "dial_code":"+60",
        "code":"MY"
      },
      {
        "name":"Maldives",
        "code":"VM",
        "dial_code":"+960"
      },
      {
        "name":"Mali",
        "code":"ML",
        "dial_code":"+223"
      },
      {
        "name":"Malta",
        "code":"MT",
        "dial_code":"+356"
      },
      {
        "name":"Marshall Islands",
        "code":"MH",
        "dial_code":"+692"
      },
      {
        "name":"Martinique",
        "code":"MQ",
        "dial_code":"+596"
      },
      {
        "name":"Mauritania",
        "code":"MR",
        "dial_code":"+222"
      },
      {
        "name":"Mauritius",
        "code":"MU",
        "dial_code":"+230"
      },
      {
        "name":"Mayotte",
        "code":"YT",
        "dial_code":"+262"
      },
      {
        "name":"Mexico",
        "code":"MX",
        "dial_code":"+52"
      },
      {
        "name":"Micronesia",
        "code":"FM",
        "dial_code":"+691"
      },
      {
        "name":"Moldova",
        "code":"MD",
        "dial_code":"+373"
      },
      {
        "name":"Monaco",
        "code":"MC",
        "dial_code":"+377"
      },
      {
        "name":"Mongolia",
        "code":"MG",
        "dial_code":"+976"
      },
      {
        "name":"Montenegro",
        "code":"ME",
        "dial_code":"+382"
      },
      {
        "name":"Montserrat",
        "code":"MS",
        "dial_code":"+1-664"
      },
      {
        "name":"Morocco",
        "code":"MA",
        "dial_code":"+212"
      },
      {
        "name":"Mozambique",
        "code":"MZ",
        "dial_code":"+258"
      },
      {
        "name":"Myanmar",
        "code":"MM",
        "dial_code":"+95"
      },
      {
        "name":"Namibia",
        "code":"NA",
        "dial_code":"+264"
      },
      {
        "name":"Nauru",
        "code":"NR",
        "dial_code":"+674"
      },
      {
        "name":"Nepal",
        "code":"NP",
        "dial_code":"+977"
      },
      {
        "name":"Netherlands",
        "code":"NL",
        "dial_code":"+31"
      },
      {
        "name":"New Caledonia",
        "code":"NC",
        "dial_code":"+687"
      },
      {
        "name":"New Zealand",
        "code":"NZ",
        "dial_code":"+64"
      },
      {
        "name":"Nicaragua",
        "code":"NI",
        "dial_code":"+505"
      },
      {
        "name":"Niger",
        "code":"NE",
        "dial_code":"+227"
      },
      {
        "name":"Nigeria",
        "code":"NG",
        "dial_code":"+234"
      },
      {
        "name":"Niue",
        "code":"NU",
        "dial_code":"+683"
      },
      {
        "name":"Norfolk Island",
        "code":"NF",
        "dial_code":"+672"
      },
      {
        "name":"Northern Mariana Islands",
        "code":"MP",
        "dial_code":"+1-670"
      },
      {
        "name":"North Korea",
        "code":"KP",
        "dial_code":"+850"
      },
      {
        "name":"Norway",
        "code":"NO",
        "dial_code":"+47"
      },
      {
        "name":"Oman",
        "code":"OM",
        "dial_code":"+968"
      },
      {
        "name":"Pakistan",
        "code":"PK",
        "dial_code":"+92"
      },
      {
        "name":"Palau",
        "code":"PW",
        "dial_code":"+680"
      },
      {
        "name":"Palestinian Authority",
        "code":"PS",
        "dial_code":"+970"
      },
      {
        "name":"Panama",
        "code":"PA",
        "dial_code":"+507"
      },
      {
        "name":"Papua New Guinea",
        "code":"PG",
        "dial_code":"+675"
      },
      {
        "name":"Paraguay",
        "code":"PY",
        "dial_code":"+595"
      },
      {
        "name":"Peru",
        "code":"PE",
        "dial_code":"+51"
      },
      {
        "name":"Philippines",
        "code":"PH",
        "dial_code":"+63"
      },
      {
        "name":"Pitcairn Islands",
        "code":"PN",
        "dial_code":"+64"
      },
      {
        "name":"Poland",
        "code":"PL",
        "dial_code":"+48"
      },
      {
        "name":"Portugal",
        "code":"PT",
        "dial_code":"+351"
      },
      {
        "name":"Puerto Rico",
        "code":"PR",
        "dial_code":"+1-787"
      },
      {
        "name":"Puerto Rico",
        "code":"PR",
        "dial_code":"+1-939"
      },
      {
        "name":"Qatar",
        "code":"QA",
        "dial_code":"+974"
      },
      {
        "name":"Reunion",
        "code":"RE",
        "dial_code":"+262"
      },
      {
        "name":"Romania",
        "code":"RO",
        "dial_code":"+40"
      },
      {
        "name":"Russia",
        "code":"RU",
        "dial_code":"+7"
      },
      {
        "name":"Rwanda",
        "code":"RW",
        "dial_code":"+250"
      },
      {
        "name":"Saint Kitts and Nevis",
        "code":"KN",
        "dial_code":"+1-689"
      },
      {
        "name":"Saint Lucia",
        "code":"LC",
        "dial_code":"+1-758"
      },
      {
        "name":"Saint Pierre and Miquelon",
        "code":"PM",
        "dial_code":"+508"
      },
      {
        "name":"Saint Vincent and the Grenadines",
        "code":"VC",
        "dial_code":"+1-784"
      },
      {
        "name":"Samoa",
        "code":"WS",
        "dial_code":"+658"
      },
      {
        "name":"San Marino",
        "code":"SM",
        "dial_code":"+378"
      },
      {
        "name":"São Tomé and Príncipe",
        "code":"ST",
        "dial_code":"+239"
      },
      {
        "name":"Saudi Arabia",
        "code":"SA",
        "dial_code":"+966"
      },
      {
        "name":"Senegal",
        "code":"SN",
        "dial_code":"+221"
      },
      {
        "name":"Serbia",
        "code":"SR",
        "dial_code":"+381"
      },
      {
        "name":"Seychelles",
        "code":"SC",
        "dial_code":"+248"
      },
      {
        "name":"Sierra Leone",
        "code":"SL",
        "dial_code":"+232"
      },
      {
        "name":"Singapore",
        "code":"SG",
        "dial_code":"+65"
      },
      {
        "name":"Slovakia",
        "code":"SK",
        "dial_code":"+421"
      },
      {
        "name":"Slovenia",
        "code":"SI",
        "dial_code":"+386"
      },
      {
        "name":"Solomon Islands",
        "code":"SB",
        "dial_code":"+677"
      },
      {
        "name":"Somalia",
        "code":"SO",
        "dial_code":"+252"
      },
      {
        "name":"South Africa",
        "code":"ZA",
        "dial_code":"+27"
      },
      {
        "name":"South Georgia and the South Sandwich Islands",
        "code":"GS",
        "dial_code":"+500"
      },
      {
        "name":"Spain",
        "code":"ES",
        "dial_code":"+34"
      },
      {
        "name":"Sri Lanka",
        "code":"LK",
        "dial_code":"+94"
      },
      {
        "name":"St Helena",
        "code":"SH",
        "dial_code":"+290"
      },
      {
        "name":"Sudan",
        "code":"SD",
        "dial_code":"+249"
      },
      {
        "name":"Suriname",
        "code":"SR",
        "dial_code":"+597"
      },
      {
        "name":"Svalbard and Jan Mayen",
        "code":"SJ",
        "dial_code":"+47"
      },
      {
        "name":"Swaziland",
        "code":"SZ",
        "dial_code":"+268"
      },
      {
        "name":"Sweden",
        "code":"SE",
        "dial_code":"+46"
      },
      {
        "name":"Switzerland",
        "code":"CH",
        "dial_code":"+41"
      },
      {
        "name":"Syria",
        "code":"SY",
        "dial_code":"+963"
      },
      {
        "name":"Taiwan",
        "code":"TW",
        "dial_code":"+886"
      },
      {
        "name":"Tajikistan",
        "code":"TJ",
        "dial_code":"+992"
      },
      {
        "name":"Tanzania",
        "code":"TZ",
        "dial_code":"+255"
      },
      {
        "name":"Thailand",
        "code":"TH",
        "dial_code":"+66"
      },
      {
        "name":"Timor-Leste",
        "code":"TL",
        "dial_code":"+670"
      },
      {
        "name":"Togo",
        "code":"TG",
        "dial_code":"+228"
      },
      {
        "name":"Tokelau",
        "code":"TK",
        "dial_code":"+690"
      },
      {
        "name":"Tonga",
        "code":"TO",
        "dial_code":"+676"
      },
      {
        "name":"Trinidad and Tobago",
        "code":"TT",
        "dial_code":"+1-868"
      },
      {
        "name":"Tunisia",
        "code":"TN",
        "dial_code":"+261"
      },
      {
        "name":"Turkey",
        "code":"TR",
        "dial_code":"+90"
      },
      {
        "name":"Turkmenistan",
        "code":"TM",
        "dial_code":"+993"
      },
      {
        "name":"Turks and Caicos Islands",
        "code":"TC",
        "dial_code":"+1-649"
      },
      {
        "name":"Tuvalu",
        "code":"TV",
        "dial_code":"+688"
      },
      {
        "name":"Uganda",
        "code":"UG",
        "dial_code":"+256"
      },
      {
        "name":"Ukraine",
        "code":"UA",
        "dial_code":"+380"
      },
      {
        "name":"United Arab Emirates",
        "code":"AR",
        "dial_code":"+971"
      },
      {
        "name":"United Kingdom",
        "code":"GB",
        "dial_code":"+44"
      },
      {
        "name":"United States",
        "code":"US",
        "dial_code":"+1"
      },
      {
        "name":"Uruguay",
        "code":"UY",
        "dial_code":"+598"
      },
      {
        "name":"US Minor Outlying Islands",
        "code":"UM",
        "dial_code":"+1"
      },
      {
        "name":"US Virgin Islands",
        "code":"VI",
        "dial_code":"+1"
      },
      {
        "name":"Uzbekistan",
        "code":"UZ",
        "dial_code":"+998"
      },
      {
        "name":"Vanuatu",
        "code":"VU",
        "dial_code":"+678"
      },
      {
        "name":"Venezuela",
        "code":"VE",
        "dial_code":"+58"
      },
      {
        "name":"Vietnam",
        "code":"VN",
        "dial_code":"+84"
      },
      {
        "name":"Wallis and Futuna",
        "code":"WF",
        "dial_code":"+681"
      },
      {
        "name":"Yemen",
        "code":"YE",
        "dial_code":"+967"
      },
      {
        "name":"Zambia",
        "code":"ZM",
        "dial_code":"+260"
      },
      {
        "name":"Zimbabwe",
        "code":"ZW",
        "dial_code":"+263"
      }
    ]
};