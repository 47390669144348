import React, { Component }  from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import '../assets/styles/Result.scss';

import _ from 'lodash';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export class FilterModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    get show() {
        return this.props.showFilterModal;
    }

    closeModal(){
        this.props.handleFilterModal(false);
    }

    resetForm(){
        this.setState({
            current_step: 1,
            reset_pw: {
                email: ""
            }
        });
    }


    render() {
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="filter-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    <React.Fragment>
                        <div className="modal-title">Filters</div>

                        <div className="form-container">
                            <TextField
                                className="filter-field"
                                id="standard-password-input"
                                type="date"
                                autoComplete="current-password"
                                value={this.props.startDate.format('YYYY-MM-DD')} 
                                onChange={(e) => this.props.handleFilterDateChange(e.target.value)}
                                inputProps={{min: moment().format('YYYY-MM-DD') }}
                                label="Date"
                                />

                            <Autocomplete
                                className="filter-field"
                                value={this.props.meridiem}
                                onChange={(e,val) => this.props.handleMeridiemChange(val)}
                                options={global.constant.time_options}
                                getOptionLabel={(option) => option.label ? option.label : ""}
                                getOptionSelected={(option, selected) => option.value === selected.value }
                                id="meridiem-field"
                                selectOnFocus
                                renderInput={(params) => <TextField {...params} label="Time" margin="normal" />}
                            />

                            <Autocomplete
                                className="language-field filter-field"
                                value={this.props.language}
                                onChange={(e,val) => this.props.handleLanguageChange(val)}
                                options={this.props.languages}
                                getOptionLabel={(option) => option ? option : ""}
                                getOptionSelected={(option, selected) => option === selected }
                                id="language-field"
                                selectOnFocus
                                renderInput={(params) => <TextField {...params} label="Language" margin="normal" />}
                            />

                            <Autocomplete
                                className="filter-field"
                                value={this.props.feature}
                                onChange={(e,val) => this.props.handleFeatureChange(val)}
                                options={global.constant.feature_options}
                                getOptionLabel={(option) => option.label ? option.label : ""}
                                getOptionSelected={(option, selected) => option.value === selected.value }
                                id="feature-field"
                                selectOnFocus
                                renderInput={(params) => <TextField {...params} label="Feature" margin="normal" />}
                            />
                        </div>
                        </React.Fragment>


                </Modal.Body>
            </Modal>
        );
  }
}