import React, { Component } from "react";
import _ from 'lodash';
import '../assets/styles/Clinic.scss';
import { clinicService } from '../services/clinic';
import { doctorService } from '../services/doctor';
import Carousel from 'react-bootstrap/Carousel';
import default_clinic_img from '../assets/images/clinic_default.png';
import ClinicMap from "./ClinicMap";
import mail_icon from '../assets/images/mail_icon.png';
import phone_icon from '../assets/images/phone_icon.png';
import {Tabs, Tab} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import ProfessionalCard from "./ProfessionalCard";
import OperationHour from "./OperationHour";

class Clinic extends Component {

    constructor(props){
        super(props);

        this.state = {
            clinic: {},
            doctors: [],
            key: 'profile'
        }

        this.handleOnFav = this.handleOnFav.bind(this);
    }

    componentDidMount(){
        this.getClinic(this.props.match.params.location_friendly_id)
    }

    getClinic(location_friendly_id){
        clinicService.get(location_friendly_id)
        .then(
            data => {
                this.getDoctors();
                this.setState({ 
                    clinic: data
                })
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    getDoctors(){
        let params = {
            location_id: this.props.match.params.location_friendly_id
        }
        doctorService.searchDoctors(params)
        .then(
            data => {
                this.setState({ 
                    doctors: data.doctors
                })
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    openGoogleMapDirection(){
        let url = `https://www.google.com/maps/dir//${this.state.clinic.latitude},${this.state.clinic.longitude}`
        const newTab = window.open(url, '_blank')
        if (newTab) newTab.opener = null
    }

    setTabKey(key){
        this.setState({ key: key})
    }

    handleOnFav(doctor_id, isFav, location_id = null){
        this.setState({
            doctors: this.state.doctors.map(el => (el.id === doctor_id ? Object.assign({}, el, { favorited: !isFav }) : el))
        });
    }

    render() {
        if (_.isEmpty(this.state.clinic)){
            return (
                <div className="loader"><Spinner animation="border" /></div>
            )
        }else{
            return (
                <div className="clinic-page container">
                    <div className="header">
                        <div className="title">Online Appointment</div>
                        <div className="bread-crumb">Home / Search & Book / Clinic</div>
                    </div>
                    <hr className="header-line"/>
                    <div className="body">
                        <div className="clinic-details">
                            <div className="img-slider">
                                <Carousel className="carousel" nextIcon="" nextLabel="" prevIcon="" prevLabel="">
                                    {
                                        this.state.clinic.clinic_photo_locations.map((item, index) => (
                                            <Carousel.Item className="carousel-item" key={`expert-${index}`}>
                                                <img className="d-block w-100" src={item.url.origin.url} onError={(e) => { e.target.src = default_clinic_img }}/>
                                            </Carousel.Item>
                                        ))
                                    }
                                </Carousel>
                            </div>
                            <div className="operation-hours-container desktop-v">
                                <div className="title">Operation Hours</div>
                                <div className="operation-hours">
                                    <OperationHour operatingHours={this.state.clinic.operating_hours} />
                                </div>
                            </div>
                        </div>
                        <div className="clinic-sub-details">
                            <div className="details-container">                
                                <div className="address-col">
                                    <div className="address-name">{this.state.clinic.name}</div>
                                    <div className="address">{this.state.clinic.address}, {[this.state.clinic.zipcode, this.state.clinic.state].filter(Boolean).join(" ")}</div>
                                    <div className="map-container">
                                        <ClinicMap results={[this.state.clinic]} />
                                    </div>
                                    <button className="btn btn-link direction-tag" onClick={() => this.openGoogleMapDirection()}>Get Direction</button>
                                    <div className="phone-email-container">
                                        <a href={`tel:${this.state.clinic.phone}`}><img className="contact-icon phone" src={phone_icon}/></a>
                                        <a href={`mailto:${this.state.clinic.email}`}><img className="contact-icon mail" src={mail_icon}/></a>
                                    </div>
                                </div>
                            </div>
                            <div className="clinic-details  mobile-v">
                            <div className="operation-hours-container">
                                <div className="title">Operation Hours</div>
                                <div className="operation-hours">
                                    <OperationHour operatingHours={this.state.clinic.operating_hours} />
                                </div>
                            </div>
                            </div>
                            <hr className="details-line"/>
                            <Tabs id="tabs-container" className="tabs-container" activeKey={this.state.key} onSelect={(k) => this.setTabKey(k)}>
                                <Tab eventKey="profile" title="Profile">
                                    {this.state.clinic.description && 
                                        <div className="tab-detail-container">
                                            <div className="tab-title">Summary</div>
                                            <p className="tab-content">{this.state.clinic.description}</p>
                                        </div>
                                    }
                                </Tab>
                                <Tab eventKey="professional" title="Professional">
                                    <div className="tab-detail-container prof">
                                    {
                                        this.state.doctors.map((doctor, index) => (
                                            <ProfessionalCard key={`doc-card-${index}`} doctor={doctor} onFav={this.handleOnFav} />
                                        ))
                                    }
                                    </div>
                                </Tab>
                                <Tab eventKey="services" title="Services">
                                    <div className="tab-detail-container">
                                        <div className="tab-title">Services/Procedures</div>
                                        <ul className="tab-content list">
                                            {
                                                this.state.clinic.services.map((service, index) => (
                                                    <li key={`service-${index}`}>{service.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            )
        }

    };
}

export { Clinic };
