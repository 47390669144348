import React, { Component } from "react";

import { userService} from '../services/user';
import { favouriteService} from '../services/favourite';

import '../assets/styles/Favourite.scss';
import _ from 'lodash';
import  { Redirect } from 'react-router-dom';

import ProfessionalCard from "./ProfessionalCard";
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-bootstrap/Spinner';

class Favourite extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            count: 0,
            favourites: [],
            page_no: 1,
            per_page: 10,
            meta: {},
        }

        this.handleOnFav = this.handleOnFav.bind(this);
    }

    getFavourites(pageNo){
        this.setState({
            loading: true
        });
        let params = {
            per_page: this.state.per_page,
            page: pageNo
        }
        favouriteService.get(params)
        .then(
            favourites => {
               this.setState({ 
                   count: favourites.favorites.length,
                   favourites: favourites.favorites,
                   meta: favourites.meta,
               });
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        ).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleOnFav(doctor_id, isFav, location_id){
        this.setState({
            favourites: this.state.favourites.map(el => (el.location.id == location_id ? Object.assign({}, el, { favorited: !isFav }) : el))
        });
    }

    componentDidMount(){
        if(userService.getUser()){
           this.getFavourites(this.state.page_no);
        }
    }

    handlePageChange = (event, value) => {
        this.setState({
            page_no: value
        });
        this.getFavourites(value);
    };
    
    render() {
        if (_.isEmpty(userService.getUser()))
            return <Redirect to='/search-book' />;

            return (
                <div className="favourite-page container-md">
                    <div className="header">
                        <div className="title">Favourites</div>
                        <div className="bread-crumb">Home / Favourites</div>
                    </div>
                    <hr className="header-line"/>
                    <div className="body">
                        {(() => {
                            if (this.state.loading) {
                                return <div className="loader"><Spinner animation="border" className="spinner"/></div>;
                            } else {
                                return (!_.isEmpty(this.state.favourites) ?
                                    <React.Fragment>
                                    <div className={`favourites-list ${this.state.count%2 == 0 ? '' : 'add-after-space'}`}>
                                        {
                                            this.state.favourites.map((favourite, index) => (
                                                <ProfessionalCard key={`doc-card-${index}`} doctor={favourite} onFav={this.handleOnFav} />
                                            ))
                                        }
                                    </div>
                                    <Pagination count={this.state.meta.total_pages} page={this.state.page_no} onChange={this.handlePageChange}/>
                                    </React.Fragment>  : <div className="empty-div">No Favourite</div>
                                    );
                            }
                        })()}
                    </div>
                    
                </div>     
            );
    }
}

export { Favourite };
