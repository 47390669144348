import React, { Component }  from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { userService } from '../services/user';
import '../assets/styles/SignInModal.scss';
import '../assets/styles/MainModal.scss';

import { Formik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

export class ChangePwModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_step: 1,
            temp_email: "",
            reset_pw: {
                email: ""
            }
        };

       this.handleSubmit = this.handleSubmit.bind(this)
    }

    get show() {
        return this.props.showChangePwModal;
    }

    changePwSchema = yup.object().shape({
        email: yup.string().required().email().label('Email')
    });

    setEmail = (email) => this.setState({ email: email });

    handleSubmit(formData, actions, stateData) {
        userService.resetPassword(formData)
        .then(
            data => {
                if(_.has(data,'errors')){
                    actions.setErrors({
                        email: 'Email does not exist'
                    });
                }else{
                    this.setState({
                        current_step: 2,
                        temp_email: formData.email
                    });

                    actions.resetForm();
                }
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    closeModal(){
        this.setState({
            current_step: 1
        });
        this.props.handleChangePwModal(false);
    }

    resetForm(){
        this.setState({
            current_step: 1,
            reset_pw: {
                email: ""
            }
        });
    }


    render() {
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="sign-in-modal change-pw-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    {this.state.current_step == 1 &&
                    <React.Fragment>
                        <div className="modal-title">Reset Your Password</div>
                        <div className="modal-sub-title">We'll send a link to your BookDoc account email to change your password</div>

                        <div className="form-container">
                            <Formik validationSchema={this.changePwSchema} onSubmit={(form, actions) => this.handleSubmit(form, actions, this.state)} initialValues={this.state.reset_pw}>
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit} className="change-pw-form">
                                            <React.Fragment>
                                                <Form.Row>
                                                    <Form.Group>    
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            isValid={touched.email && !errors.email}
                                                            isInvalid={!!errors.email}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Button className="btn-block submit-btn change-pw-btn" type="submit">Submit</Button>
                                            </React.Fragment>
                                    </Form>
                                )}
                                </Formik>
                        </div>
                        </React.Fragment>
                    }
                    {this.state.current_step == 2 && 
                        <div className="change-pw-success">
                            <p>We have emailed you at {this.state.temp_email}</p>
                            <p>If you have not received an email within 5 minues, you may have used a different email address to sign up for BookDoc.</p>
                            <p><a onClick={() => this.resetForm()} className="reset-change-pw">Click here</a> to try another email address.</p>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        );
  }
}