
import _ from 'lodash';

export const arrayService = {
    findElementByProperty
};

function findElementByProperty(array, propertyName, value){
    return _.find(array, function (item) {
        return item[propertyName] === value;
      });
}