import React, { Component, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import circle_icon from '../assets/images/circle_icon.png';
import teleconsult_img from '../assets/images/teleconsult_img.png';
import '../assets/styles/Home.scss';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from "react-datepicker";
import '../modules/global';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactGA from 'react-ga';

import { statisticService } from '../services/statistic';
import { healthTipService } from '../services/healthTip';

class Home extends Component {

    constructor(props){
        super(props);
    
        this.state = {
            main_expert_column: [],
            expert_column_items: [],
            specialties: [],
            startDate: new Date(),
            search_input_value: '',
            selected_specialty: null,
            location: '',
            locations: [],
            stats: {}
        }
    }   

    getCommons(){
        axios.get(process.env.REACT_APP_API_URL+'commons').then(res => {
            const specialties = res.data.map((common) => {
                common._label = common.name;
                return { value: common, label: common.name }
            });
            this.setState({ specialties });
        })
    }
    getExpertColumns(){
        healthTipService.getHealthTips().then(
            res => {
                const expert_columns = res.health_tips;
                const main_expert_column = [expert_columns[0]];
                const expert_column_items = expert_columns.slice(1, 4);
                this.setState({ main_expert_column });
                this.setState({ expert_column_items });
            },
            error => {
                console.log('There has been a problem with your fetch operation: ', 
                    error.message);
            }
        )
    }

    getStats(){
        let params = {
            start_date: moment().subtract(1, "years").format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD')
        }
        statisticService.get(params).then(
            res => {
                if(_.has(res,'stats')){
                    this.setState({
                        stats: res.stats
                    });
                }
            },
            error => {
                console.log('There has been a problem with your fetch operation: ', 
                    error.message);
            }
        )
    }

    handleSpecialtyInputChange = (newValue, { action }) => {
        if (action === "input-change"){
            this.setState({ 
                search_input_value: newValue,
                selected_specialty: null
            });
            return newValue;
        }
    };

    handleSpecialtySelectChange = selectedValue => {
        selectedValue.label = selectedValue.value._label;
      
        this.setState({ 
            selected_specialty: selectedValue,
            search_input_value: selectedValue.label
        });

        var locationField = document.getElementById("locationField");
        var dateField = document.getElementById("dateField");

        if(selectedValue.value.type == 'doctor' && selectedValue.value.id != -1){
            locationField.classList.add("disabled");
            dateField.classList.remove('disabled');

        }else if(selectedValue.value.type == 'facility' && selectedValue.value.id != -1){
            locationField.classList.add("disabled");
            dateField.classList.add("disabled");
        }else{
            locationField.classList.remove("disabled");
            dateField.classList.remove("disabled");
        }
    };

    componentDidMount(){
        console.log({ REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT });
        console.log({ REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT });
        
        this.getCommons();
        this.getExpertColumns();
        this.getStats();

        const locations = global.constant.available_countries;

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var checkLocExist = locations.findIndex(x => x.type == 'current_location')

                if(checkLocExist < 0){
                    locations.unshift({
                        name: 'Current Location',
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                        type: 'current_location'
                    });
                }
              },
              function(error) {
                console.log("Error Code = eoo " + error.code + " - " + error.message);
              });
          } else {
            console.log("Not Available");
        }
        this.setState({ locations });
    }

    renderSuggestionType(suggestion) {
        switch(suggestion.type) {
            case 'common_name':
                return "Layman's Term";
            default:
                return suggestion.type;
        }
    }

    renderSuggestionName(suggestion){
        if(suggestion.type == 'doctor' && suggestion.id == -1){
            return `Doctors named "${this.state.search_input_value}" (${suggestion.count})`;
        }else if(suggestion.type == 'facility' && suggestion.id == -1){
            return `Facilities named "${this.state.search_input_value}" (${suggestion.count})`;
        }
        else{
            return suggestion.name;
        }
    }

    submitForm = (e) => {
        e.preventDefault();

        if(!_.has(this.state.selected_specialty,'value') || this.state.selected_specialty.value.type != 'facility' || this.state.selected_specialty.value.id == -1){
            this.props.history.push({
                pathname: '/search-book/result',
                state: this.state
            });
        }else{
            this.props.history.push({
                pathname:  `/search-book/clinic/${this.state.selected_specialty.value.friendly_id}`
            });
        }
    } 

    render() {

        const services = [{
            name: "Dentist (General)",
            desc: "A medical specialty that provides general examination of dental and oral hygiene, offering advice and carrying out dental and surgical procedures."
            },{
                name: "General Practitioner",
                desc: "A medical specialty which treats and provides nonsurgical medical care for all common medical conditions and refers patients to hospitals and other medical services for urgent and specialist treatment."
            },{
                name: "Gastroenterology & Hepatology",
                desc: "A medical specialty which specializes in the health and conditions affecting the digestive system. Usually involves GERD, peptic ulcers, IBD and IBS, colonic cancers, biliary stones, pancreatitis, viral hepatitis, fatty livers, liver cirrhosis, liver cancers, diverticular disease and haemorrhoids."
            },{
                name: "Obstetrics and Gynaecology (O&G)",
                desc: "A medical specialty in female health involving clinical prevention and management of diseases, and providing optimum care for pregnant women and menopausal age."
            },{
                name: "Ophthalmology",
                desc: "A medical specialty which specializes in the treatment of illnesses, diseases, injuries, and conditions that affect the eye."
            },{
                name: "Otorhinolaryngology (ENT)",
                desc: "A medical specialty which is focused on the ears, nose, and throat."
            }
        ]

        const teleconsultItems = [
            "See doctors without the hassle and cost of travel",
            "Reach a doctor 24/7",
            "Maintain privacy and confidentiality",
            "Have one on one consultation",
            "Have a secure platform"
        ]

        const experts = [
            {
                desc: "A great online platform to ease the public to meet the doctor as well to help the public avoid the spread of covid -19. It is such a great honour for me to be a part of the doctor’s team in BookDoc.",
                name: "Dr. Oh Pei Bing",
                from: "IG Clinic"
            },
            {
                desc: "I had helped patients through this apps before this, I do find this app useful in helping people from all around Malaysia especially during the COVID 19 period.",
                name: "Dr. Loke Wei Sien",
                from: "Singapore Children & Family (Bayan Lepas)"
            }
        ]

        const steps = [
            {
                title: "Choose a Doctor",
                desc: "Select your preferred doctor from the list"
            },{
                title: "Register or Login",
                desc: "Enter or confirm your personal details"
            },{
                title: "Confirm Appointment",
                desc: "Complete appointment details and provide consent prior to payment"
            },{
                title: "Chat or meet doctor",
                desc: "Chat or meet the doctor at schedule time to skip the queue"
            }
        ]

        const promiseOptions = inputValue =>
            new Promise((resolve, reject) => {
                if(inputValue.length >= 2){
                    axios.get(`${process.env.REACT_APP_API_URL}suggestions?keyword=${inputValue}`).then(res => {
                        resolve(res.data.map((suggestion) => {
                            if(suggestion.id == -1 && (suggestion.type == 'doctor' || suggestion.type == 'facility')){
                                suggestion.count = suggestion.name
                                suggestion.name = this.state.search_input_value
                            }
                            suggestion._label = this.renderSuggestionName(suggestion)
                            return { 
                                value: suggestion, label: (<div className="label specialty-label">
                                <span className="suggestion-name">{this.renderSuggestionName(suggestion)}</span>
                                <span className="suggestion-type">
                                    {this.renderSuggestionType(suggestion)}
                                </span></div>)
                            }
                        }))
                    })
                }else{
                    resolve([]);
                }
        }); 

        return (
        <div className="home-page">
            <div className="main-section">
                <div className="container">
                    <div className="d-flex left-content">
                        <div className="section-heading">Connecting you with healthcare professionals via video</div>
                        <div className="d-flex-inline section-desc">Hassle-free integrated online eco-system for finding and booking healthcare professionals</div>
                        <div className="d-flex section-form-container flex-wrap"> 
                            <form className="section-form w-100">
                                <div className="form-group">
                                    <AsyncSelect className="speciality-field" placeholder="Specialty, Service, Doctor, Clinic, etc." cacheOptions loadOptions={promiseOptions} defaultOptions={this.state.specialties} onInputChange={this.handleSpecialtyInputChange} onChange={this.handleSpecialtySelectChange} inputValue={this.state.search_input_value} value={this.state.selected_specialty} closeMenuOnSelect={true}/>
                                    <div className="section-field-loc-date">
                                        <Select id="locationField" className="location-field" options={this.state.locations} getOptionLabel ={(option)=>option.name}
                                        getOptionValue ={(option)=>option} placeholder="Select Location" onChange={location => this.setState({ location: location })} />
                                        <div className="form-group date-group">
                                            <DatePicker id="dateField" className="form-control section-field date-field" minDate={new Date()} dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={date => this.setState({ startDate: date })}/>
                                            <div className="custom-calendar-icon"></div>
                                        </div>
                                        <input className="btn search-btn section-btn" type="button" value="Search" onClick={this.submitForm}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* test steps */}
            <div className="steps-section">
                <div className="steps-box first-box">How does it works?</div>
                {
                  steps.map((step, index) => (
                    <div className="steps-box second-box" key={`test-step-${index}`}>
                        <div className="steps-container">
                            <div className="first-col">
                                <div className="num">{index+1}</div>
                            </div>
                            <div className="second-col">
                                <div className="title">{step.title}</div>
                                <div className="desc">{step.desc}</div>
                            </div>
                        </div>
                    </div>
                  ))
                }
            </div>

            {/* end test */}

            {/* common search */}
            <div className="container-fluid mx-auto common-search-section section">
                <div className="row justify-content-center top-part">
                    <div className="center-content">
                        <div className="d-flex-inline text-center">
                            <div className="section-heading">How Can We Help You?</div>
                        </div>
                        <hr className="divider" />
                        <div className="d-flex-inline section-desc text-center">
                            <p>Our consultation service gives you access to our highly trained medical professionals in any location from the comfort of your own home.</p>
                        </div>
                        <div className="d-flex flex-row p-2 common-items flex-wrap"> 
                            {
                                services.map((service, index) => (
                                    <div className="card text-center common-item-container" key={`common-search-${index}`}>
                                        <div className="card-body">
                                            <div className="card-icon">
                                                <img src={circle_icon} />
                                            </div>
                                            <div className="card-title">{service.name}</div>
                                            <div className="card-text">
                                                {service.desc}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="d-flex common-search-btn-container">
                            <a className="btn search-book-btn section-btn" href="#">Search & Book Now</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* teleconsult */}
            <div className="container-fluid mx-auto teleconsult-section section">
                <div className="row justify-content-center top-part">
                    <div className="center-content">
                        <div className="d-flex-inline text-center">
                            <div className="section-heading">Get Your Consultation Via Tele-Consult</div>
                        </div>
                        <hr className="divider" />
                        <div className="d-flex-inline section-desc text-center">
                            <p>Consultation from the comfort of your home.</p>
                        </div>
                        <div className="d-flex flex-row pt-2 teleconsult-body flex-wrap"> 
                            <div className="teleconsult-img-container">
                                <img src={teleconsult_img} />
                            </div>
                            <div className="teleconsult-details">
                                <div className="detail-title">You Can Now</div>
                                <div className="detail-list">
                                    {
                                        teleconsultItems.map((value, index) => (
                                            <div className="list-item" key={`teleconsult-text-${index}`}>
                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                <span className="item-text">{value}</span>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="d-flex teleconsult-btn-container">
                                    <a className="btn teleconsult-btn section-btn" href="https://deeplink.bookdoc.com/teleconsult" target="_blank">Schedule Appointment Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* healthcare professionals */}
            <div className="container-fluid mx-auto healthcare-prof-section">
                <div className="row top-part">
                    <div className="left-content">
                        <div className="d-flex-inline">
                            <div className="section-heading">Are You A Healthcare Professionals?</div>
                        </div>
                        <hr className="divider" />
                        <div className="d-flex-inline booking-number">{_.has(this.state.stats,'appointments') ? this.state.stats.appointments.toLocaleString() : 0}</div>
                        <div className="d-flex pt-2 section-desc flex-wrap"> 
                            <p>Bookings have been made through BookDoc app and portal in the last 12 months.</p>
                            <p>List your practice now to gain access to more patients and special doctors’ portal which allows for better appointment management and instant access to patient information.</p>
                        </div>

                        <div className="d-flex practice-btn-container">
                            <a className="btn practice-btn section-btn" href={`${process.env.REACT_APP_MAIN_SITE_URL}/services/healthcare-professionals/`}>Discover More</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* experts say */}
            <div className="container-fluid mx-auto experts-section">
                <div className="row top-part">
                    <div className="right-content">
                        <div className="d-flex-inline text-center">
                            <div className="section-heading">What Experts Say About Us</div>
                        </div>
                        <hr className="divider" />
                        <div className="d-flex pt-2 section-desc flex-wrap"> 
                            <Carousel className="carousel" nextIcon="" nextLabel="" prevIcon="" prevLabel="">
                                {
                                    experts.map((item, index) => (
                                        <Carousel.Item className="carousel-item" key={`expert-${index}`}>
                                            <Carousel.Caption>
                                                <div className="expert-desc">
                                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                                    {item.desc}
                                                </div>
                                                <div className="expert-name">{item.name}</div>
                                                <div className="expert-from">{item.from}</div>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            {/* featured healthcare provider */}
            <div className="container-fluid mx-auto featured-section section">
                <div className="row justify-content-center top-part">
                    <div className="center-content">
                        <div className="d-flex-inline text-center">
                            <div className="section-heading">Featured Healthcare Providers</div>
                        </div>
                        <hr className="divider" />
                        <div className="d-flex flex-row pt-2 featured-items flex-wrap"> 
                            {
                                global.constant.featured_heathcare_providers.map((value, index) => (
                                    <div className="featured-img-container" key={`featured-img-${index}`}>
                                        <img src={value} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        
            {/* expert columns */}
            <div className="container-fluid mx-auto expert-column-section section">
                <div className="row justify-content-center top-part">
                    <div className="center-content">
                        <div className="d-flex-inline text-center">
                            <div className="section-heading">Expert Column</div>
                        </div>
                        <hr className="divider" />
                        <div className="d-flex-inline section-desc text-center">
                            <p>Professionals from the healthcare industry are featured in monthly articles to raise awareness of health-related issues.</p>
                        </div>
                        <div className="d-flex flex-row pt-2 expert-column-lists flex-wrap"> 
                                {this.state.main_expert_column.map((expert_column, index) => (
                                    <div className="main-expert-column" key={`main-expert-column-${index}`}>
                                        <div className="main-expert-column-img-container">
                                            <div className="column-date-container">
                                                <div className="column-date">{moment(expert_column.publish_date, 'DD-MM-YYYY').format('DD')}</div>
                                                <div className="column-month-year">{moment(expert_column.publish_date, 'DD-MM-YYYY').format('MMM YYYY')}</div>
                                            </div>
                                            <img src={expert_column.image.thumb} className="main-expert-column-img" />
                                        </div>
                                        <div className="expert-information">
                                            {/* <div className="expert-tag">COVID-19 VACCINE</div> */}
                                            <div className="expert-title">
                                                <a href={`/search-book/expert-column/${expert_column.id}`}>{expert_column.title}</a>
                                            </div>
                                            <div className="expert-desc">{expert_column.short_description}</div>
                                            <div className="expert-author">{expert_column.author}</div>
                                        </div>
                                    </div>
                                ))}
                            <div className="expert-column-items">
                                {this.state.expert_column_items.map((expert_column, index) => (
                                    <div className="expert-column" key={`expert-column-item-${index}`}>
                                        <div className="expert-column-img-container">
                                            <div className="column-date-container">
                                                <div className="column-date">{moment(expert_column.publish_date, 'DD-MM-YYYY').format('DD')}</div>
                                                <div className="column-month-year">{moment(expert_column.publish_date, 'DD-MM-YYYY').format('MMM YYYY')}</div>
                                            </div>
                                            <img src={expert_column.image.thumb} className="main-expert-column-img" />
                                        </div>
                                        <div className="expert-information">
                                            {/* <div className="expert-tag">COVID-19 VACCINE</div> */}
                                            <div className="expert-title">
                                                <a href={`/search-book/expert-column/${expert_column.id}`}>{expert_column.title}</a>
                                            </div>
                                            <div className="expert-desc">{expert_column.short_description}</div>
                                            <div className="expert-author">{expert_column.author}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="more-articles">
                            <a href="/search-book/expert-column">
                                <div>Read More Articles</div>
                                <span className="dot">.</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        
            {/* download now */}
            <div className="container-fluid mx-auto download-section">
                <div className="container row top-part">
                    <div className="right-content">
                        <div className="d-flex-inline">
                            <div className="section-heading">Book Your favourite doctor using BookDoc App Now</div>
                        </div>
                        <div className="pt-2 section-desc"> 
                            We’re available via the <a className="app-store" href="https://apps.apple.com/my/app/bookdoc-go-activ-get-rewards/id1037726744">Apple App Store</a>, <a className="app-store" href="https://play.google.com/store/apps/details?id=com.bookdoc.bookdoc">Google Play Store</a>, and <a className="app-store" href="https://appgallery.huawei.com/#/app/C102164307">Huawei App Gallery</a>.
                        </div>
                        <div className="d-flex download-btn-container">
                            <a className="btn download-btn section-btn" href="https://sites.bookdoc.com/download-bookdoc-app/" target="_blank">Download Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )};
}

export { Home };
