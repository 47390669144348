export const validateMessagesService = {
    required, 
    invalid,
    ageInvalid,
    exists,
    notExist,
    minLength, 
    lengthDigit
};

function required(name) {
    return name + ' is required.';
}

function invalid(name) {
    return name + ' is invalid.';
}

function ageInvalid(name, age) {
    return name + ' must be at least ' + age + ' years old.';
}

function exists(name) {
    return 'This ' + name + ' is already exist.';
}

function notExist(name) {
    return 'This ' + name + ' does not exist.';
}

function minLength(name, minLength) {
    return name + ' should be ' + minLength + '+ characters.';
}

function lengthDigit(name, length) {
    return name + ' should be ' + length + ' digits.';
}

function notMatch(name, comparedName) {
    return name + ' doesn\'t match with ' + comparedName + '.';
}

function incorrect(name) {
    return 'Incorrect ' + name + '.';
}

function incorrectPassword() {
    return 'Please enter the correct current password for this account.';
}

function duplicate(name) {
    return name + ' is duplicate.';
}


function theSameWith(name, comparedName) {
    return name + ' is the same as ' + comparedName + '.';
}

function serverError() {
    return 'Server Error.';
}

function alreadyInUse(name) {
    return name + ' is already in use.';
}

function acceptTermPrivacy() {
    return 'Please read and accept the user agreement.';
}

function success(actionName) {
    return actionName + ' Successful.';
}