import React, { Component } from "react";
import _ from 'lodash';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export class BookingBehalfConfirmDialog extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
    }

    handleOk = () => {
        if(this.props.behalf_field == 'relationship'){
            this.props.handleVisitorSelectorChange('relationship', this.props.temp_behalf, true);
        }else if(this.props.behalf_field == 'coverage'){
            this.props.onCoverageChange(this.props.temp_coverage, true);
        }
        this.props.handleConfirmDialog(false);
    }

    render() {
        return (
        <div>
            <Dialog onClose={() => this.props.handleConfirmDialog(false)} aria-labelledby="simple-dialog-title" open={this.props.showConfirmDialog}>
                <DialogContent>
                    <div>It seems that your employer currently doesn't provide medical coverage to your {this.props.temp_behalf.label} . While you may still proceed with the appointment booking, it shall be done on the basis of self-paying.</div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.props.handleConfirmDialog(false)}>Cancel</Button>
                    <Button onClick={this.handleOk}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    )};
}
