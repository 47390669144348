import React,{useState} from 'react';
import { Navigation, Main, Footer, UnauthorizedDialog } from './components';
import {Helmet} from "react-helmet";
import AppContext from './components/AppContext';
import {useLocation} from 'react-router-dom';
import { useHistory } from "react-router";

import './App.css';

function App() {
  const [user, setUser] = useState({});
  const globalState = {
    user: user,
    setUser
  };

  return (
    <div className="App">
      <AppContext.Provider value={globalState}>
        <Navigation history={useHistory()} location={useLocation()}/>
        <UnauthorizedDialog />
        <Main />
        <Footer />
      </AppContext.Provider>
    </div>
  );
}

export default App;
