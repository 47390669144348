import { authHeader } from '../helpers/auth-header';
import _ from 'lodash';
import { userService } from "../services/user";

export const employerService = {
    getEmployerDetail
};

function getEmployerDetail(code){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}employers/${code}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function handleResponse(response) {

    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            userService.logout();
            window.location.reload(true);
        }else if(response.status === 422) {
            return response.json();
        }else{
            const error = (response && response.message) || response.statusText;
            if(error.length == 0){
                error = 'Internal Server Error'
            }
            return Promise.reject(error);
        }
    }

    return response.json();
}