import React, { Component }  from "react";
import { Form } from 'react-bootstrap';
import '../assets/styles/Settings.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import { userService } from "../services/user";
import { employerService } from "../services/employer";
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import  { Redirect } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Spinner from 'react-bootstrap/Spinner';

export class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: userService.getUser(),
            success_msg: "",
            premiumAlertShow: false,
            deactivate_alert: false,
            invalid_code: false,
            show_premium_form: false,
            invalid_premium_alert: false,
            premium_name: "",
            premium_form_error: "",
            passwords: {
                current_password: "",
                password: "",
                password_confirmation: "",
            },
            premium_programme: {
                employee_id: "",
                employer_code_number: "",
                nric: ""
            }
        };
    }

    handlePremiumAlert = (isOpen, premium_name) => this.setState({ premiumAlertShow: isOpen, premium_name: premium_name });
    showDeactivateAlert = (isOpen) => this.setState({ deactivate_alert: isOpen });
    showInvalidPremiumAlert = (isOpen) => this.setState({ invalid_premium_alert: isOpen });

    verifySchema = yup.object().shape({
        current_password: yup.string().required().min(6).label('Current Password'),
        password: yup.string().required().min(6).notOneOf([yup.ref('current_password'), null], "New Password cannot be the same as Current Password").label('New Password'),
        password_confirmation: yup.string().required().oneOf([yup.ref('password'), null], "Confirm New Password doesn't match with New Password").label('Password Confirmation')
    });

    premiumSchema = yup.object().shape({
        employee_id: yup.string().required().label('Employee ID')
    });

    componentDidMount(){
    }

    handlePassword(formData, actions, stateData){

        let password_save_btn = document.getElementById("password-save-btn");
        let password_save_text = document.getElementById("password-save-text");
        let password_save_spinner = document.getElementById("password-save-spinner");
        password_save_btn.classList.add("disabled");
        password_save_text.classList.add('d-none');
        password_save_spinner.classList.remove('d-none');
    
        userService.changePassword(formData)
        .then(
            response => {
                if(_.has(response,'user')){
                    actions.resetForm();
                    this.setState({
                        success_msg: "Password Changed Successfully."
                    });
                }else{
                    if(_.has(response,'errors')){
                        if(response.errors.includes('Invalid email or password.')){
                            actions.setErrors({
                                current_password: 'Please enter the correct current password for this account.'
                            });
                        }else{
                            actions.setErrors({
                                current_password: response.errors.join()
                            });
                        }
                    }
                }

            },
            error => {
                this.setState({ error, loading: false })
            }
        ).finally(() => {
            password_save_btn.classList.remove("disabled");
            password_save_text.classList.remove('d-none');
            password_save_spinner.classList.add('d-none');
        }); 
    }

    onSaveClick(){
        this.setState({
            success_msg: ""
        });
    }
    
    deactivatePremium(){
        userService.updateEmployer({}).then(
            data => {
                if(_.has(data,'auth_token')){
                    this.handlePremiumAlert(false,'');
                    localStorage.setItem('user', JSON.stringify(data));
                    this.setState({user: data});
                    this.showDeactivateAlert(true);
                }else{
                    //error
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    verifyActivationCode(){
        let activation_next_btn = document.getElementById("activation-next-btn");
        let activation_next_text = document.getElementById("activation-next-text");
        let activation_next_spinner = document.getElementById("activation-next-spinner");
        activation_next_btn.classList.add("disabled");
        activation_next_text.classList.add('d-none');
        activation_next_spinner.classList.remove('d-none');

        employerService.getEmployerDetail(this.state.premium_programme.employer_code_number).then(
            data => {
                let isCodeCorrect = true;
                if(_.has(data,'error')){
                    isCodeCorrect = false;
                }

                this.setState(prevState => ({
                    invalid_code: !isCodeCorrect,
                    show_premium_form: isCodeCorrect
                }));
                let list = document.querySelectorAll('.code-field')
                for (var i = 0; i < list.length; ++i) {
                    isCodeCorrect ? list[i].classList.remove('incorrect-pin') : list[i].classList.add('incorrect-pin');
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        ).finally(() => {
            activation_next_btn.classList.remove("disabled");
            activation_next_text.classList.remove('d-none');
            activation_next_spinner.classList.add('d-none');
        }); 
    }

    onActivationCodeChange(code){
        this.setState(prevState => ({
            show_premium_form: false,
            premium_programme: {
                ...prevState.premium_programme, 
                employer_code_number: code
            }
        }));
    }
    
    handlePremium(formData, actions, stateData){

        let activation_save_btn = document.getElementById("activation-save-btn");
        let activation_save_text = document.getElementById("activation-save-text");
        let activation_save_spinner = document.getElementById("activation-save-spinner");
        activation_save_btn.classList.add("disabled");
        activation_save_text.classList.add('d-none');
        activation_save_spinner.classList.remove('d-none');

        formData.employer_code_number = stateData.premium_programme.employer_code_number;
        userService.updateEmployer(formData).then(
            data => {
                if(_.has(data,'error')){
                    this.setState({
                        premium_form_error: data.error
                    });
                } else if(_.has(data,'auth_token')){
                    localStorage.setItem('user', JSON.stringify(data));
                    this.setState({user: data});
                }else{
                    this.showInvalidPremiumAlert(true);
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        ).finally(() => {
            activation_save_btn.classList.remove("disabled");
            activation_save_text.classList.remove('d-none');
            activation_save_spinner.classList.add('d-none');
        }); 
    }


    render() {
        if (_.isEmpty(userService.getUser()))
            return <Redirect to='/search-book' />;
        
        return (
            <div className="settings-page container">
                <div className="header">
                    <div className="title">Settings</div>
                    <div className="bread-crumb">Home / Settings</div>
                </div>
                <hr className="header-line"/>
                <div className="body">
                    <div className="profile-div section-div">
                        <div className="div-title">Profile</div>
                        <div className="profile-details">
                            <div className="info-row">
                                <div className="info-title">Name</div>
                                <div className="semicolon">:</div>
                                <div className="info-value">{userService.getUser().first_name} {userService.getUser().last_name}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-title">Age</div>
                                <div className="semicolon">:</div>
                                <div className="info-value">{moment().diff(userService.getUser().date_of_birth, 'years',true).toFixed(0)}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-title">Cell</div>
                                <div className="semicolon">:</div>
                                <div className="info-value">{userService.getUser().phone ? userService.getUser().phone : '-'}</div>
                            </div>
                        </div>
                        <div className="profile-policy">Please call us at 1300-88-2362 (BDOC) to change your name, date of birth, or any other personal information.</div>
                    </div>
                    <hr className="body-line"/>
                    <div className="section-div">
                        <div className="div-title">Password</div>
                            {this.state.success_msg &&
                                <Alert variant="outlined" severity="success" className="change-pw-success">{this.state.success_msg}</Alert>
                            }
                            <Formik validationSchema={this.verifySchema} onSubmit={(form, actions) => this.handlePassword(form, actions, this.state)} initialValues={this.state.passwords}>
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit} className="password-form">
                                        <Form.Row>
                                            <Form.Group>    
                                                <Form.Label>Current Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="current_password"
                                                    value={values.current_password}
                                                    onChange={handleChange}
                                                    isValid={touched.current_password && !errors.current_password}
                                                    isInvalid={!!errors.current_password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.current_password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group>    
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isValid={touched.password && !errors.password}
                                                    isInvalid={!!errors.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group>    
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password_confirmation"
                                                    value={values.password_confirmation}
                                                    onChange={handleChange}
                                                    isValid={touched.password_confirmation && !errors.password_confirmation}
                                                    isInvalid={!!errors.password_confirmation}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password_confirmation}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                
                                        <div className="save-btn-div">
                                            <Button className="btn-block submit-btn" type="submit" onClick={() => this.onSaveClick()} id="password-save-btn">
                                                <span id="password-save-text">Save</span>
                                                <Spinner className="d-none" id="password-save-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                    </div>
                    <hr className="body-line"/>
                    <div className="section-div">
                        <div className="div-title">Premium Programme</div>
                        <div className="premium-div">
                            {(() => {
                                if (_.isEmpty(this.state.user.employers)) {
                                    return (
                                        <Formik validationSchema={this.premiumSchema} onSubmit={(form, actions) => this.handlePremium(form, actions, this.state)} initialValues={this.state.premium_programme}>
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            handleBlur,
                                            values,
                                            touched,
                                            isValid,
                                            errors
                                        }) => (
                                            <Form noValidate onSubmit={handleSubmit} className="premium-form">
                                                <Form.Row>
                                                    <Form.Group>    
                                                        <Form.Label>Enter Your Corporate Activation Code</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="employer_code_number"
                                                            value={values.employer_code_number}
                                                            onChange={handleChange}
                                                            onBlur={e => {
                                                                handleBlur(e);
                                                                this.onActivationCodeChange(values.employer_code_number);
                                                              }}
                                                        />
                                                        <div className={`invalid-pin-error ${this.state.invalid_code ? 'show' : ''}`}>Activation Code is invalid</div>
                                                    </Form.Group>
                                                </Form.Row>
                                                {!this.state.show_premium_form && 
                                                    <div className="save-btn-div">
                                                        <Button className="btn-block submit-btn" id="activation-next-btn" type="button" onClick={() => this.verifyActivationCode()}>
                                                            <span id="activation-next-text">Next</span>
                                                            <Spinner className="d-none" id="activation-next-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                        </Button>
                                                    </div>
                                                }
                                                {this.state.show_premium_form && 
                                                    <React.Fragment>
                                                        <Form.Row>
                                                            <Form.Group>    
                                                                <Form.Label>Employee ID</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="employee_id"
                                                                    value={values.employee_id}
                                                                    onChange={handleChange}
                                                                    isValid={touched.employee_id && !errors.employee_id}
                                                                    isInvalid={!!errors.employee_id}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.employee_id}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        {!_.isEmpty(this.state.premium_form_error) &&
                                                            <div className="premium-error">{this.state.premium_form_error}</div>
                                                        }
                                                        <div className="save-btn-div">
                                                            <Button className="btn-block submit-btn" type="submit" id="activation-save-btn">
                                                                <span id="activation-save-text">Save</span>
                                                                <Spinner className="d-none" id="activation-save-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                            </Button>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                
                                            </Form>
                                        )}
                                        </Formik>
                                    );
                                } else {
                                    return (
                                        this.state.user.employers.map((employer, index) => (
                                            <div className="premium-employer" key={`premium-${index}`}>
                                                {!_.isEmpty(employer.logo) &&
                                                    <div className="premium-img"><img src={employer.logo.url.origin.url}/></div>
                                                }
                                                <div className="premium-details">
                                                    <div className="premium-line">The Premium Programme is currently activated exclusively for <span className="premium-name">{employer.name}</span>.</div>
                                                    <button className="btn premium-btn" onClick={() => this.handlePremiumAlert(true, employer.name)}>Deactivate Premium Programme</button>
                                                </div>
                                            </div>
                                        ))  
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </div>

                {/* deactivate confirmation dialog */}
                <Dialog
                    open={this.state.premiumAlertShow}
                    onClose={() => this.handlePremiumAlert(false, "")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By deactivating the Premium Programme, you will no longer be able to enjoy the full benefits of {this.state.premium_name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handlePremiumAlert(false, "")}>
                        No, Nevermind
                        </Button>
                        <Button onClick={() => this.deactivatePremium()} color="primary" autoFocus>
                        Yes, Deactivate
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* deactivated dialog */}
                <Dialog
                    open={this.state.deactivate_alert}
                    onClose={() => this.showDeactivateAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Deactivated"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        You have just deactivated the Premium Programme on this account. Note that you can still continue to use BookDoc app as a general user with limited features & benefits.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.showDeactivateAlert(false)} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* invalid premium dialog */}
                <Dialog
                    open={this.state.invalid_premium_alert}
                    onClose={() => this.showInvalidPremiumAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Failed Activation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        We are sorry, the Premium Programme cannot be activated on this account, please double-check your inputs. If this problem persists, you may contact BookDoc at 1300-88-2362 or care@bookdoc.com for further assistance.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.showInvalidPremiumAlert(false)} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
  }
}