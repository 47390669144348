import React, { Component }  from "react";
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import Spinner from 'react-bootstrap/Spinner';

import '../assets/styles/BookingModal.scss';
import '../assets/styles/MainModal.scss';

export class ScheduleModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_step: 1,
            slot_availability_error: "",
            temp_show: false,
        };
    }

    get show() {
        return this.props.showScheduleModal;
    }

    componentDidMount(){
    }

    closeScheduleModal(){
        this.props.handleScheduleModal(false);
    }

    onSelectedSlot(slot){
        this.props.handleScheduleModal(false);
        this.props.onSelectedSlot(slot);
    }

    convertEpochToTime(value){
        return moment.unix(value).utc().format('hh:mm A')
    }

    render() {
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="booking-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeScheduleModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    <div className="slot-selection-container">
                        <div className="title">Schedule (Walk-in only)</div>
                        <div className="slots-container">
                            <div className="date-bar">
                                <button className={`btn btn-link previous ${this.props.day_diff > 0 ? '' : 'disabled'}`}><i className="fa fa-chevron-left" aria-hidden="true" onClick={() => this.props.get_range_dates(0)}></i></button>
                                <div className="date">{moment(this.props.slot_date).format('ddd, DD MMM Y')}</div>
                                <button className="btn btn-link next" onClick={() => this.props.get_range_dates(1)}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                            </div>
                            <div className="slots">
                                {(() => {
                                    if (this.props.slot_loading) {
                                        return <div className="slots-loader"><Spinner animation="border" className="spinner"/></div>;
                                    } else {
                                        return (this.props.slots.length ?
                                            this.props.slots.map((slot, index) => (
                                                <button key={`slot-${index}`} className="btn slot valid-slot" onClick={() => this.onSelectedSlot(slot)}>{this.convertEpochToTime(slot.start)}</button>
                                            )) : <div className="slots-loader">No Slots</div>
                                            );
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
  }
}