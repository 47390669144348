import React, { Component } from "react";

import { healthTipService } from '../services/healthTip';

import '../assets/styles/ExpertColumn.scss';
import _ from 'lodash';
import  { Redirect } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import Spinner from 'react-bootstrap/Spinner';

class ExpertColumn extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            expert_columns: [],
            page_no: 1,
            meta: {},
        }
    }

    getExpertColumns(page_no){
        this.setState({
            loading: true
        });
        let params = {
            page: page_no
        }
        healthTipService.getHealthTips(params).then(
            res => {
                this.setState({ 
                    expert_columns: res.health_tips,
                    meta: res.metadata
                });
            },
            error => {
                console.log('There has been a problem with your fetch operation: ', 
                    error.message);
            }
        ).finally(() => {
            this.setState({
                loading: false
            });
        });
    }


    handlePageChange = (event, value) => {
        this.setState({
            page_no: value
        });
        this.getExpertColumns(value);
    };

    componentDidMount(){
        this.getExpertColumns(this.state.page_no);
    }
    
    render() {
        return (
            <div className="expert-column-page container">
                <div className="header">
                    <div className="title">Expert Columns</div>
                    <div className="bread-crumb">Home / Expert Columns</div>
                </div>
                <hr className="header-line"/>
                <div className="body">
                    {(() => {
                        if (this.state.loading) {
                            return <div className="loader"><Spinner animation="border" className="spinner"/></div>;
                        } else {
                            return (!_.isEmpty(this.state.expert_columns) ?
                                <React.Fragment>
                                {
                                    this.state.expert_columns.map((expert_column, index) => (
                                        <div className="card" key={`expert-column-${index}`}>
                                            <a href={`/search-book/expert-column/${expert_column.id}`}><img className="card-img-top" src={expert_column.image.cover} /></a>
                                            <div className="card-body">
                                                <h5 className="card-title"><a href={`/search-book/expert-column/${expert_column.id}`}>{expert_column.title}</a></h5>
                                                <div className="author-date">{expert_column.author} . {expert_column.publish_date}</div>
                                                <p className="card-text">{expert_column.short_description}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                                </React.Fragment>  : <div className="empty-div">No Expert Column</div>
                                );
                        }
                    })()}
                </div>
                {!_.isEmpty(this.state.expert_columns) && 
                    <Pagination count={this.state.meta.total_page} page={this.state.page_no} onChange={this.handlePageChange}/>
                }
            </div>
            
        );
    }
}

export { ExpertColumn };
