import React, { Component }  from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import '../assets/styles/SignUpModal.scss';
import '../assets/styles/MainModal.scss';
import pre_login_bookdoc_logo from '../assets/images/pre-login-bookdoc-logo@2x.png';
import fb_icon from '../assets/images/fb_icon.svg';
import Select, { components } from 'react-select';
import moment from 'moment';
import _ from 'lodash';
import { validateMessagesService } from '../services/validateMessage';
import { locationService } from '../services/location';
import { Formik } from 'formik';
import * as yup from 'yup';
import { userService } from '../services/user';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import ReactGA from 'react-ga';

export class SignUpModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            current_step: 1,
            current_country_code: global.constant.default_country_code,
            days: _.range(1, 32).map((x) => ({ value: x, label: String(x).padStart(2, '0') })),
            months: _.range(1, 13).map((x) => ({ value: x, label: String(x).padStart(2, '0') })),
            years: _.range(global.constant.minYear, moment().format('YYYY')).reverse().map((x) => ({ value: x, label: x })),
            countries: global.constant.countries.map((country) => ({ label: `${country.name} (${country.dial_code})`, value: country.code, full: country })),
            dob_day: "",
            dob_month: "",
            dob_year: "",
            dob_error: "",
            validated: false,
            email: "",
            sign_up: {
                date_of_birth: "",
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                sex: "male",
                phone: ""
            }
        };

        this.setValidated = this.setValidated.bind(this)
    }

    componentDidMount(){
        this.getCurrentCountry();
    }

    signUpSchema = yup.object().shape({
        first_name: yup.string().required().label('First Name'),
        last_name: yup.string().required().label('Last Name'),
        email: yup.string().required().email().label('Email'),
        password: yup.string().required().min(6).label('Password'),
        sex: yup.string().required().label('Gender'),
        phone: yup.string().required().min(7).max(12).label('Mobile Phone')
    });

    get show() {
        return this.props.showSignUpModal;
    }

    responseFacebook = (response) => {
        if(response){
            let params = {
                email: response.email,
                first_name: response.first_name,
                last_name: response.last_name,
                sex: response.gender,
                provider: "Facebook",
                oauth_app_id: process.env.REACT_APP_FACEBOOK_APP_ID,
                oauth_app_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
                oauth_uuid: response.id,
            }
            userService.registration(params).then(
                user => {
                    window.location.reload(false);
                },
                error => {
                    console.log(error)
                    this.setState({ error, loading: false })
                }
            );
        }
    }

    nextStep = () => this.setState({ current_step: this.state.current_step + 1 });
    previousStep = () => this.setState({ current_step: this.state.current_step - 1 });

    checkDob(toReg){
        let dob_error = "";
        let dob = moment([this.state.dob_day, this.state.dob_month, this.state.dob_year].join('-'), 'D-M-YYYY', true);

        if(dob.isValid()){
            let age = moment().diff(dob, 'years',true);

            if(age < global.constant.minAge){
                dob_error = validateMessagesService.ageInvalid('Date of Birth', global.constant.minAge)
            }else{
                this.setState(prevState => ({
                    current_step: toReg ? this.state.current_step+1 : this.state.current_step,
                    sign_up: {
                        ...prevState.sign_up, 
                        date_of_birth: dob.format('YYYY-MM-DD')
                    }
                }))
            }
        }else{
            dob_error = validateMessagesService.invalid('Date of Birth')
        }

        this.setState({
            dob_error: dob_error
        });

        if(!toReg){
            return dob_error;
        }
    }

    closeModal(){
        this.setState({
            current_step: 1
        });
        this.props.handleSignUpModal(false);
    }

    setValidated = (val) => this.setState({ validated: val });

    getCurrentCountry(){
        locationService.getCurrentCountry()
        .then(
            location => {
                if(location){
                    this.setState({
                        current_country_code: location.country_code
                    });
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    handleSignUp(formData, actions, state_data){

        if(!state_data.dob_error){

            let params = _.cloneDeep(formData);

            let selected_country = state_data.countries.find(option => option.value === state_data.current_country_code);

            params.phone = `${selected_country.full.dial_code.replace(/\D/g,'')}${params.phone}`;
            params.date_of_birth = state_data.sign_up.date_of_birth;
            params.sex = state_data.sign_up.sex;

            userService.registration(params)
            .then(
                response => {
                    if(response.errors){
                        _.each(response.errors, (arr, key) => {     
                            arr = arr.map((val) => (
                                `${this.signUpSchema.fields[key].spec.label} ${val}`
                            ));        
                            actions.setErrors({
                                [key]: arr.join()
                            });
                        });
                    }else{
                        ReactGA.event({
                            category: "User",
                            action: "Sign Up",
                        });
                        window.location.reload(false);
                    }
                },
                error => {
                    console.log(error)
                    this.setState({ error, loading: false })
                }
            );
        }

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //   event.preventDefault();
        //   event.stopPropagation();
        // }

        // this.setValidated(true);
    }

    handleChangeGender(gender){
        this.setState(prevState => ({
            sign_up: {
                ...prevState.sign_up, 
                sex: gender.value
            }
        }))
    }

    submitBtnClicked(){
        let dob_error = this.checkDob(false);
        let list = document.querySelectorAll('.dob-field [class*=control]')
        for (var i = 0; i < list.length; ++i) {
            dob_error ? list[i].classList.add('invalid-field') : list[i].classList.remove('invalid-field');
        }
    }

    render() {

        const SingleValue = country_props => (
            <components.SingleValue {...country_props}>
              {country_props.data.full.dial_code}
            </components.SingleValue>
        );

        return (
            <Modal show={this.show} size="lg" dialogClassName="sign-up-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                     <div className="modal-logo">
                        <img src={pre_login_bookdoc_logo} />
                    </div>
                    {this.state.current_step == 1 &&
                        <div className="default-step">
                            <div className="modal-title">Sign Up</div>
                            <div className="modal-sub-title">Create a new account to make your booking</div>
                            <div className="t-and-c">
                                By continuing, you accept BookDoc's <a href="/search-book/terms" target="_blank">terms & conditions</a> and <a href="/search-book/privacy" target="_blank">Privacy policy</a>.
                            </div>

                            <FacebookLogin
                                appId="1225759537863554"
                                autoLoad={false}
                                fields="first_name,last_name,email,gender"
                                callback={this.responseFacebook} 
                                render={renderProps => (
                                    <Button className="fb-btn" block size="lg" type="button" onClick={renderProps.onClick}>
                                        <img src={fb_icon}/> Continue with Facebook
                                    </Button>
                                )}
                            />

                            <Button className="btn signin-btn" block size="lg" type="button" onClick={() => this.nextStep()}>
                                Sign Up with Email Address
                            </Button>

                            <div className="new-patient-div">I have an account. <button className="btn btn-link sign-up-link" onClick={() => this.props.handleSwitchSignInModal(true)}>Sign in now!</button></div>
                        </div>
                    }
                    {this.state.current_step == 2 &&
                        <div className="dob-step">
                            <div className="modal-title">Date of Birth</div>
                            <div className="modal-sub-title">Enter your date of birth to proceed.</div>
                            <div className="dob-field-div">
                                <Select className="dob-field dob-day-field" options={this.state.days} placeholder="DD" onChange={day => this.setState({ dob_day: day.value })} />
                                <Select className="dob-field dob-month-field" options={this.state.months} placeholder="MM" onChange={month => this.setState({ dob_month: month.value })} />
                                <Select className="dob-field dob-year-field" options={this.state.years} placeholder="YYYY" onChange={year => this.setState({ dob_year: year.value })} />
                            </div>
                            {this.state.dob_error != "" &&
                                <div className="dob-error">{this.state.dob_error}</div>
                            }
                            <button className="btn next-btn btn-block" onClick={() => this.checkDob(true)}>Next</button>
                        </div>
                    }
                    {this.state.current_step == 3 &&
                        <div className="registration-step">
                            <div className="modal-title">Sign Up with Email Address</div>
                            <div className="modal-sub-title">Create a new account to make your booking</div>
                            <Formik validationSchema={this.signUpSchema} onSubmit={(form, actions) => this.handleSignUp(form,actions, this.state)} initialValues={this.state.sign_up}>
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit} className="sign-up-form">
                                        <Form.Row>
                                            <Form.Group>
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isValid={touched.email && !errors.email}
                                                    isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isValid={touched.password && !errors.password}
                                                    isInvalid={!!errors.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group>
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="first_name"
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    isValid={touched.first_name && !errors.first_name}
                                                    isInvalid={!!errors.first_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.first_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group>
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="last_name"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    isValid={touched.last_name && !errors.last_name}
                                                    isInvalid={!!errors.last_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.last_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Group className="dob-field-div">    
                                                    <Select className="dob-field dob-day-field" value={this.state.days.filter(option => option.value === this.state.dob_day)} options={this.state.days} placeholder="DD" onChange={day => this.setState({ dob_day: day.value })} />
                                                    <Select className="dob-field dob-month-field" value={this.state.months.filter(option => option.value === this.state.dob_month)} options={this.state.months} placeholder="MM" onChange={month => this.setState({ dob_month: month.value })} />
                                                    <Select className="dob-field dob-year-field" value={this.state.years.filter(option => option.value === this.state.dob_year)} options={this.state.years} placeholder="YYYY" onChange={year => this.setState({ dob_year: year.value })} />
                                            </Form.Group>
                                            {this.state.dob_error != "" &&
                                                <div className="dob-error">{this.state.dob_error}</div>
                                            }
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group>
                                                <Form.Label>Gender</Form.Label>
                                                <Select className="gender-field" value={global.constant.genders.filter(option => option.value === this.state.sign_up.sex)} options={global.constant.genders} onChange={gender => this.handleChangeGender(gender)} placeholder=""/>
                                            </Form.Group>   
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Label>Mobile Phone</Form.Label>
                                            <Form.Group className="phone-field-div">    
                                                <Select className="phone-prefix-field" value={this.state.countries.filter(option => option.value === this.state.current_country_code)} options={this.state.countries} onChange={selected => this.setState({ current_country_code: selected.value })} components={{ SingleValue }}/>
                                                <div className="phone-input-div">
                                                    <Form.Control
                                                        type="text"
                                                        name="phone"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        isValid={touched.phone && !errors.phone}
                                                        isInvalid={!!errors.phone}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.phone}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </Form.Row>
                                        
                                        <div className="t-and-c">
                                            By continuing, You have read and accept BookDoc's <a href="/search-book/terms" target="_blank">terms & conditions</a> and <a href="/search-book/privacy" target="_blank">Privacy policy</a>.
                                        </div>
                                        <Button className="btn-block submit-btn" type="submit" onClick={() => this.submitBtnClicked()}>Submit</Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    }
                    {/* {this.state.current_step > 1 &&
                        <button className="btn btn-primary" onClick={() => this.previousStep()}>Previous</button>
                    } */}
                    {/* {(this.state.current_step > 1 && this.state.current_step < 3) &&
                        <button className="btn btn-primary" onClick={() => this.nextStep()}>Next</button>
                    } */}
                </Modal.Body>
            </Modal>
        );
  }
}