import { authHeader } from '../helpers/auth-header';
import { userService} from '../services/user';
import { stringify } from 'query-string';
import _ from 'lodash';
import moment from 'moment';

export const partnerService = {
    getHotel,
    getAirbnbUrl,
    getAgodaHotelUrl
};

function getHotel(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: userService.getUser() ? {...header,...authHeader()} : {...header}
    };

    return fetch(`${process.env.REACT_APP_API_URL}hotels/high_rating?${stringify(params)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function getAirbnbUrl(practiceLocation, startDate, website) {
    var destination = practiceLocation.country;

    if (practiceLocation.city) {
      destination = practiceLocation.city + '--' + practiceLocation.country;
    }

    // Format destination
    destination = destination.replace(/ /g, '-');

    var checkin = startDate ? moment(startDate * 1000).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    return website + destination + '/homes?checkin=' + checkin;
}

function getAgodaHotelUrl(agodaId, agodaWebsite) {

    var agodaCID = 'cid=' + process.env.REACT_APP_AGODA_CID;

    // Search page
    var searchPage = 'pages/agoda/default/DestinationSearchResult.aspx';

    // Check Agoda Id from practice location and go to city in Agoda
    if (agodaId) {
      return agodaWebsite + searchPage +'?city=' + agodaId + '&' + agodaCID;
    }

    // Go to BookDoc in agoda
    return agodaWebsite + 'bookdoc';

}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // window.location.reload(true);
        }
        return Promise.reject(response);
    }

    return response.json();
}