import React, { Component }  from "react";
import '../assets/styles/Privacy.scss';

export class Privacy extends Component {

    render() {
        return (
            <div className="privacy-page container">
                <div className="header">
                    <div className="title">Privacy Policy</div>
                    <div className="bread-crumb">Home / Privacy Policy</div>
                </div>
                <hr className="header-line"/>
                <div className="body">
                    <h2 className="privacy__title">NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010</h2>
                    <h2 className="privacy__title">NOTIS DI BAWAH AKTA PERLINDUNGAN DATA PERIBADI 2010</h2>

                    <article className="privacy__content">
                        <ol className="privacy__list privacy__list--main">
                            <li>
                            <h3>This Notice serves to inform you that your personal data is being processed by us or on our behalf when you use our services in relation to the BookDoc website ("Site") and related applications ("Services").</h3>
                            <p className="privacy__desc">Notis ini bertujuan untuk memaklumkan kepada anda bahawa data peribadi anda sedang diproses oleh atau bagi pihak kami apabila anda dengan menggunakan perkhidmatan kami yang berkenaan dengan laman web BookDoc ("Laman Web") dan aplikasi-aplikasi yang berkenaan ("Perkhidmatan").</p>
                            </li>
                            <li>
                            <h3>Your personal data includes:</h3>
                            <p className="privacy__desc">Data peribadi anda termasuk:</p>
                            <ul className="privacy__list privacy__list--sub">
                                <li>
                                <h3>Personal details (such as name, age, gender, identity card number, passport number, date of birth, ethnicity, language, nationality);</h3>
                                <p className="privacy__desc">Butir-butir peribadi (seperti nama, umur, jantina, nombor kad pengenalan, nombor pasport, tarikh lahir, kaum, bahasa, kewarganegaraan);</p>
                                </li>
                                <li>
                                <h3>Contact details (such as address, email, phone number);</h3>
                                <p className="privacy__desc">Butir-butir perhubungan (seperti alamat, emel, nombor telefon);</p>
                                </li>
                                <li>
                                <h3>Social media details (such as Facebook, Twitter, Instagram, LinkedIn);</h3>
                                <p className="privacy__desc">Butir-butir media social (seperti Facebook, Twitten, Instagram, LinkedIn);</p>
                                </li>
                                <li>
                                <h3>Employee Identification Details, if applicable (such as Employee ID, Job Grade, Beneficiary Coverage);</h3>
                                <p className="privacy__desc">Butir-butir pengenalan pekerja, jika terpakai (seperti identiti pekerja, pangkat; perlindungan benefisiari);</p>
                                </li>
                                <li>
                                <h3>Appointment details (such as the doctors, dentists or other healthcare providers ("Healthcare Providers") you have visited, your reasons for visits, your dates of visit, your appointment history (such as cost of consultation), and other medical and health information you choose to share with us;</h3>
                                <p className="privacy__desc">Butir-butir temujanji (seperti doktor, doktor gigi, atau pemberi jagaan kesihatan lain ("Pemberi Jagaan Kesihatan") yang telah dilawati oleh anda, alasan untuk lawatan tersebut, tarikh lawatan anda, sejarah temujanji (seperti kos perundingan) dan maklumat perubatan dan kesihatan lain yang ada pilih untuk berkongsi dengan kami;</p>
                                </li>
                                <li>
                                <h3>Other information that you voluntarily choose to provide to us such as insurance details and including unique identifiers such as passwords, and personal data in communications and correspondence emails or letters that you send to us; and</h3>
                                <p className="privacy__desc">Maklumat lain yang anda secara rela memilih untuk memberi kami, termasuk pengenalan unik seperti kata laluan dan data peribadi di dalam komunikasi dan surat-menyurat dari emel atau surat yang anda hantar kepada kami; dan</p>
                                </li>
                                <li>
                                <h3>Additional personal or other information that may be relevant for us to consider your request for any of our other products.</h3>
                                <p className="privacy__desc">Maklumat tambahan lain atau peribadi yang mungkin relevan kepada kami untuk mempertimbangkan permintaan anda untuk produk kami yang lain.</p>
                                </li>
                            </ul>
                            </li>
                            <li>
                            <h3>You consent to the processing of your personal data for purposes which include but are not limited to the following:</h3>
                            <p className="privacy__desc">Anda bersetuju kepada pemprosesan data peribadi anda untuk maksud-maksud berikut yang termasuk tetapi tidak terhad kepada:</p>
                            {/* <!-- Begin sub list--> */}
                            <ul className="privacy__list privacy__list--sub">
                                <li>
                                <h4>To connect you with the Healthcare Provider that you choose to consult;</h4>
                                <p className="privacy__desc">Untuk menghubungkan anda kepada Pemberi Jagaan Kesihatan yang anda pilih;</p>
                                </li>
                                <li>
                                <h4>To identify your location;</h4>
                                <p className="privacy__desc">Untuk mengenalpasti lokasi anda;</p>
                                </li>
                                <li>
                                <h4>To serve you;</h4>
                                <p className="privacy__desc">Untuk melayan anda;</p>
                                </li>
                                <li>
                                <h4>To provide you with the relevant  healthcare services;</h4>
                                <p className="privacy__desc">Untuk memberikan anda perkhidmatan jagaan kesihatan yang relevan;</p>
                                </li>
                                <li>
                                <h4>To attend to the relevant transaction if applicable;</h4>
                                <p className="privacy__desc">Untuk melaksanakan transaksi yang relevant jika terpakai;</p>
                                </li>
                                <li>
                                <h4>To facilitate our interactions with you;</h4>
                                <p className="privacy__desc">Untuk memudahkan interaksi kami dengan anda;</p>
                                </li>
                                <li>
                                <h4>To contact you when necessary or requested, including to remind you of an upcoming appointment;</h4>
                                <p className="privacy__desc">Untuk menghubungi anda apabila diminta atau ada keperluannya, termasuk untuk mengingatkan anda mengenai suatu temujanji yang akan datang;</p>
                                </li>
                                <li>
                                <h4>For compliance with  legal and regulatory obligations;</h4>
                                <p className="privacy__desc">Untuk mematuhi apa-apa kehendak undang-undang atau kawal selia;</p>
                                </li>
                                <li>
                                <h4>To send you electronic announcements and newsletters that we think will interest you;</h4>
                                <p className="privacy__desc">Untuk menghantar pengumuman elektronik dan surat berita yang berkemungkinan menarik perhatian anda;</p>
                                </li>
                                <li>
                                <h4>To determine your eligibility,  obtaining benefit plan information;</h4>
                                <p className="privacy__desc">Untuk mengenalpasti kelayakan anda, mendapatkan maklumat mengenai pelan manfaat;</p>
                                </li>
                                <li>
                                <h4>To perform core operational  services (such as hosting, billing, fulfilment, data storage, security, insurance verification, or Site analytics);</h4>
                                <p className="privacy__desc">Untuk melakukan perkhidmatan teras operasi (seperti hosting, bil, penunaian, penyimpanan data, sekuriti, pengesahan kelayakan, atau analisa Laman Web);</p>
                                </li>
                                <li>
                                <h4>To enable us to customize our advertising;</h4>
                                <p className="privacy__desc">Untuk membolehkan kami untuk menyesuaikan pengiklanan kami;</p>
                                </li>
                                <li>
                                <h4>For internal record keeping;</h4>
                                <p className="privacy__desc">Bagi penyimpanan rekod dalaman;</p>
                                </li>
                                <li>
                                <h4>For internal investigations, audit or security purposes;</h4>
                                <p className="privacy__desc">Bagi penyiasatan dalaman, audit atau maksud-maksud sekuriti;</p>
                                </li>
                                <li>
                                <h4>To promote the Services and services provided by our related corporations, including providing you with information about our other services;</h4>
                                <p className="privacy__desc">Untuk mempromosikan Perkhidmatan tersebut dan perkhidmatan yang disediakan oleh perbadanan berkaitan kami, termasuk memberikan maklumat mengenai perkhidmatan lain kami kepada anda;</p>
                                </li>
                                <li>
                                <h4>To investigate your complaints;</h4>
                                <p className="privacy__desc">Untuk menyiasat aduan yang dibuat oleh anda;</p>
                                </li>
                                <li>
                                <h4>To produce statistical reports, collectively or at individual data level, for corporate reporting, research works and publications;</h4>
                                <p className="privacy__desc">Untuk menghasilkan laporan statistik, secara kolektif atau tahap data individu, bagi pelaporan korporat, kerja-kerja penyelidikan dan penerbitan;</p>
                                </li>
                                <li>
                                <h4>To use statistical information that we collect in any way permitted by law, including from third parties in connection with their commercial and marketing efforts;</h4>
                                <p className="privacy__desc">Untuk menggunakan maklumat statistik yang kami kumpul melalui cara yang dibenarkan oleh undang-undang, termasuk daripada pihak ketiga selaras dengan usaha komersial dan pemasaran mereka;</p>
                                </li>
                                <li>
                                <h4>For analysis for statistical, profiling or other purposes for us to conduct category analysis, financial analysis, investigate service lapses, and to review, develop and improve the quality of our products and services; and</h4>
                                <p className="privacy__desc">Bagi analisa statistik, profil atau maksud-maksud lain bagi kami untuk melaksanakan analisa kategori, analisa kewangan, penyiasatan kesilapan di dalam perkhidmatan, dan untuk menilai, memajukan dan menambah baik kualiti produk dan perkhidmatan kami; dan</p>
                                <p>Such other purposes directly related to the foregoing (collectively, "Purposes").</p>
                                <p className="privacy__desc">maksud-maksud lain yang berhubungan dengan yang tersebut di atas (secara kolektif, "Maksud-maksud").</p>
                                </li>
                            </ul>
                            {/* <!-- End sub list--> */}
                            <h3>Generally, we handle your personal data for the purposes set out in this Notice. Any one or more of the listed purposes may apply to your personal data, depending on the actual situation. The Purposes above do not purport to be an exhaustive listing, although an effort is made to set out as many salient purposes as may be applicable.</h3>
                            <p className="privacy__desc">Secara lazimnya, kami mengendalikan data peribadi anda bagi Maksud-maksud yang tersebut di dalam Notis ini. Mana-mana satu atau lebih daripada satu Maksud-maksud boleh terpakai kepada data peribadi anda, bergantung kepada keadaan yang sebenar. Maksud-maksud tersebut di atas adalah tidak merupakan senarai yang lengkap, walaupun suatu usaha telah dibuat untuk menyenaraikan sebanyak yang mungkin maksud-maksud yang terpakai.</p>
                            </li>
                            <li>
                            <h3>Generally, your personal data was, is being or is to be collected:</h3>
                            <p className="privacy__desc">Secara lazimnya, data peribadi anda telah, sedang atau akan dikumpulkan:</p>
                            {/* <!-- Begin sub list--> */}
                            <ul className="privacy__list privacy__list--sub">
                                <li>
                                <h4>When you use the Services’ interactive tools and services, such as searching for Healthcare Providers, searching for available appointments with Healthcare Providers and completing medical history forms prior to Healthcare Provider appointments;</h4>
                                <p className="privacy__desc">Apabila anda menggunakan peralatan dan perkhidmatan interaktif yang tersedia di dalam Perkhidmatan tersebut, seperti mencari Pemberi Jagaan Kesihatan, mencari temujanji Pemberi Jagaan Kesihatan yang sedia ada dan menyempurnakan borang sejarah perubatan sebelum temujanji dengan Pemberi Jagaan Kesihatan;</p>
                                </li>
                                <li>
                                <h4>When you voluntarily provide information in free-form text boxes through the Services and through responses to surveys, questionnaires and the like;</h4>
                                <p className="privacy__desc">Apabila anda secara rela memberikan maklumat di dalam borang percuma melalui Perkhidmatan tersebut dan melalui maklum balas, tinjauan, soal selidik dan sebagainya;</p>
                                </li>
                                <li>
                                <h4>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information which notifies us know when you are logged on and available to receive update or alert notices;</h4>
                                <p className="privacy__desc">Jika anda memuat turun dan memasang aplikasi dan perisian tertentu yang disediakan oleh kami, kami boleh menerima dan mengumpul maklumat yang dihantar daripada peranti anda bagi maksud untuk memberikan anda Perkhidmatan yang relevan, seperti maklumat yang memaklumkan kami apabila anda telah log masuk dan sedia untuk menerima notis kemas kini atau amaran;</p>
                                </li>
                                <li>
                                <h4>If you download our mobile application ("Application"), we may receive information about your location and mobile device;</h4>
                                <p className="privacy__desc">Jika anda memuat turn aplikasi mobil ("Aplikasi"), kami boleh menerima maklumat mengenai lokasi dan peranti mobil anda;</p>
                                </li>
                                <li>
                                <h4>Through cookies on the Site, and website analytics services and other tracking technology; and</h4>
                                <p className="privacy__desc">Melalui cookies di dalam Laman Web dan perkhidmatan analisa laman web dan teknologi pengesanan lain;</p>
                                </li>
                                <li>
                                <h4>When you use the "Contact Us" function on the Site, send us an email or otherwise contact us.</h4>
                                <p className="privacy__desc">Apabila anda menggunakan fungsi "Hubungi Kami" di dalam Laman, menghantar emel atau menghubungi kami.</p>
                                </li>
                            </ul>
                            {/* <!-- End sub list--> */}
                            </li>
                            <li>
                            <h3>You are responsible for ensuring that the information you provide us is accurate, complete, not misleading and is kept up to date. If you fail to supply us any of your personal data which is not stated as being voluntarily provided to us, we may refuse to process your personal data for any of the Purposes and you will not be able to use the Services.</h3>
                            <p className="privacy__desc">Anda bertanggungjawab untuk memastikan bahawa maklumat yang anda berikan kami adalah tepat, lengkap, tidak mengelirukan dan terkini. Jika anda gagal untuk memberikan kami data peribadi yang tidak dinyatakan sebagai diberikan secara rela kepada kami, kami tidak boleh memproses data peribadi anda untuk mana-mana Maksud-maksud tersebut dan anda tidak boleh menggunakan Perkhidmatan tersebut.</p>
                            </li>
                            <li>
                            <h3>We may require your assistance if the personal data relating to other persons is required to process your personal data for the Purposes and you hereby agree to use your best endeavors to assist us when required.</h3>
                            <p className="privacy__desc">Kami boleh menghendaki bantuan anda jika data peribadi yang berhubungan dengan orang lain dikehendaki untuk memproses data peribadi anda untuk Maksud-maksud tersebut dan anda dengan ini bersetuju untuk menggunakan usaha terbaik anda untuk membantu kami bila dikehendaki.</p>
                            </li>
                            <li>
                            <h3>Upon your request, you shall have the right to the deletion of your personal data and/or to request access to and to request correction of your personal data and to contact us with any inquiries or complaints in respect of your personal data (including the possible choices and means for limiting the processing of your personal data) through the Site or Application or as follows:</h3>
                            <p className="privacy__desc">Anda berhak untuk meminta akses kepada dan meminta pembetulan terhadap data peribadi anda dan untuk menghubungi kami tentang apa-apa pertanyaan atau aduan berkenaan dengan data peribadi anda (termasuk pilihan-pilihan dan cara-cara yang mungkin untuk mengehadkan pemprosesan data peribadi anda) melalui Laman Web atau Aplikasi atau seperti berikut:</p>
                            <div className="row privacy__pdpa-wrapper">
                                <h4 className="text--font-medium">The Personal Data Protection Officer</h4>
                                <h4 className="privacy__desc text--font-medium">Pegawai Perlindungan Data Peribadi</h4>
                                <div className="privacy__content-info">
                                    <div className="privacy__item"><h4>Email address:</h4><a href="mailto:pdpa@bookdoc.com" className="privacy__link text--danger">pdpa@bookdoc.com</a></div>
                                    <div className="privacy__item"><p className="privacy__desc">Alamat emel:</p></div>
                                    <div className="privacy__item"><h4>Telephone no:</h4><a href="tel:1300882362" className="privacy__link text--danger ng-binding">1300-88-2362</a></div>
                                    <div className="privacy__item"><p className="privacy__desc">No. Telefon:</p></div>
                                </div>
                            </div>
                            <h3>In accordance with the Act:</h3>
                            <p className="privacy__desc">Tertakluk kepada Akta tersebut:</p>
                            <ul className="privacy__list privacy__list--sub alpha">
                                <li>
                                <h4>We may charge a prescribed fee for processing your request for access or correction; and</h4>
                                <p className="privacy__desc">Kami boleh mengenakan fi yang ditetapkan untuk memproses permintaan mengakses atau pembetulan anda; dan</p>
                                </li>
                                <li>
                                <h4>We may refuse to comply with your request for access or correction to your personal data and if we refuse to comply with such request, we will inform you of our refusal and reason for our refusal.</h4>
                                <p className="privacy__desc">Kami boleh enggan mematuhi permintaan mengakses atau pembetulan data peribadi anda dan jika kami enggan mematuhi permintaan tersebut, kami akan memaklumkan anda mengenai keengganan tersebut dan sebab-sebab bagi keengganan tersebut melalui notis bertulis.</p>
                                </li>
                            </ul>
                            </li>
                            <li>
                            <h3>We disclose or may disclose your personal data for the Purposes to our related corporations, your employers (where applicable), our business partners, our service providers, Healthcare Providers, insurance company, governmental departments and/or agencies, regulatory and/or statutory bodies and any such third party requested or authorized by you or as may be required in law.</h3>
                            <p className="privacy__desc">Kami menzahirkan atau boleh menzahirkan data peribadi anda untuk Maksud-maksud tersebut kepada perbadanan berkaitan kami, majikan anda (di mana terpakai), rakan-rakan perniagaan kami, pembekal-pembekal perkhidmatan kami, Pemberi Jagaan Kesihatan lain, syarikat insurans, jabatan-jabatan dan agensi-agensi kerajaan, badan-badan kawal selia dan / atau berkanun dan dan mana-mana pihak ketiga yang diminta atau dibenarkan oleh anda atau seperti yang boleh dikehendaki oleh undang-undang.</p>
                            </li>
                            <li>
                            <h3>We may transfer your personal data to a place outside Malaysia for any of the Purposes.</h3>
                            <p className="privacy__desc">Kami boleh memindahkan data peribadi anda ke sesuatu tempat di luar Malaysia bagi Maksud-maksud tersebut.</p>
                            </li>
                            <li>
                            <h3>We reserve the right to amend this Notice at any time and will place notice of such amendments on the Site or via any other mode that we view suitable.</h3>
                            <p className="privacy__desc">Kami berhak untuk meminda Notis ini pada bila-bila masa dan akan memberikan notis mengenai pemindaan tersebut di dalam Laman Web atau melalui cara lain yang kami pertimbangkan adalah sesuai.</p>
                            </li>
                            <li>
                            <h3>Nothing in this Notice shall limit your rights or our rights under the Act.</h3>
                            <p className="privacy__desc">Tiada apa-apa dalam Notis ini akan menghadkan hak anda atau hak kami di bawah Akta tersebut.</p>
                            </li>
                            <li>
                            <h3>In the event of any inconsistency between the English version and any other languages of this Notice, the English version shall prevail over any other languages.</h3>
                            <p className="privacy__desc">Sekiranya terdapat apa-apa percanggahan antara versi Bahasa Inggeris dan versi-versi bahasa lain Notis ini, versi Bahasa Inggeris akan mengatasi versi-versi bahasa lain.</p>
                            </li>
                        </ol>
                        </article>
                </div>
            </div>
        );
  }
}