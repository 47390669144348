import React, { Component }  from "react";
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import { appointmentService } from '../services/appointment';
import { favouriteService } from '../services/favourite';
import '../assets/styles/BookingModal.scss';
import '../assets/styles/MainModal.scss';

import default_avatar from '../assets/images/avatar-default.png';
import location_pin_outline_icon from '../assets/images/location-pin-outline-icon.png';
import appointment_icon from '../assets/images/appointment_icon.png';
import calendar_add_icon from '../assets/images/calendar_add_icon@x3.png';
import fav_fill from '../assets/images/fav_fill@x3.png';
import fav_unfill from '../assets/images/fav_unfill@x3.png';
import world_icon from '../assets/images/world_icon.png';
import calendar_new_icon from '../assets/images/calendar_new_icon@x3.png';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HotelDialog } from "./HotelDialog";
import Spinner from 'react-bootstrap/Spinner';

export class BookingInformationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alertCancelShow: false,
            alertAddEventShow: false,
            alertStatusEventShow: false,
            showHotelDialog: false,
            addEventMessage: "",
            addEventStatus: ""
        };

        this.handleHotelDialog = this.handleHotelDialog.bind(this);
    }

    handleCancelAlert = (isOpen) => this.setState({ alertCancelShow: isOpen });
    handleHotelDialog = (isOpen) => this.setState({ showHotelDialog: isOpen });
    handleAddEventAlert = (isOpen) => this.setState({ alertAddEventShow: isOpen });
    handleStatusEventAlert = (isOpen) => this.setState({ alertStatusEventShow: isOpen });

    get show() {
        return this.props.showBookingModal;
    }

    componentDidMount(){
    }

    closeBookingModal(){
        this.props.handleBookingModal(false);
    }

    cancelAppointment(){
        appointmentService.cancel(this.props.appointment.id).then(
            data => {
                if(data){
                    this.handleCancelAlert(false);
                    this.props.handlePerAppointmentCancel(this.props.appointment.id)
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    handleFav(){
        let isFav = this.props.appointment.location.favorited;

        let params = {
            practice_location_id: this.props.appointment.location.id
        }
    
        favouriteService.favourite(isFav, params)
        .then(
            data => {
                this.props.handleOnFav(this.props.appointment.id, isFav)
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    getFavWording(){
        return this.props.appointment.location.favorited ? 'Remove from Favourite' : 'Add to Favourite'
    }

    getFavIcon(){
        return this.props.appointment.location.favorited ? <img src={fav_fill} alt="favourite"/> : <img src={fav_unfill} alt="unfavourite"/>;
    }

    convertEpoch(value){
        return moment.unix(value).utc()
    }

    addToCalendar(){

        this.handleAddEventAlert(false);
        let calendar_add_btn = document.getElementById("calendar-add-btn");
        let calendar_add_icon = document.getElementById("calendar-add-icon");
        let calendar_add_spinner = document.getElementById("calendar-add-spinner");
        calendar_add_btn.classList.add("disabled");
        calendar_add_icon.classList.add('d-none');
        calendar_add_spinner.classList.remove('d-none');

        let appointment = this.props.appointment;
        let doctor = appointment.doctor;
        let location = appointment.location;
        var timezone = 'UTC';

        var clinicAddress = location.address +
        (location.postal || location.city ? ',' : '') +
        (location.postal ? ' ' + location.postal : '') +
        (location.city ? ' ' + location.city : '') +
        (location.state ? ', ' + location.state : '');

        var event = {
            'summary': 'BookDoc Appointment with ' + (doctor.title ? (doctor.title + ' ') : '') + doctor.first_name + ' ' + doctor.last_name,
            'location': clinicAddress,
            'description': 'At ' + location.name,
            'start': {
                'dateTime': moment.utc((appointment.time - doctor.timezone.offset) * 1000).format("YYYY-MM-DDTHH:mm:ss"),
                'timeZone': timezone
              },
              'end': {
                'dateTime': moment.utc((appointment.endtime - doctor.timezone.offset) * 1000).format("YYYY-MM-DDTHH:mm:ss"),
                'timeZone': timezone
            },
            'recurrence': [
              'RRULE:FREQ=DAILY;COUNT=1'
            ],
            'reminders': {
                'useDefault': false,
                'overrides': [
                  {'method': 'email', 'minutes': 10},
                  {'method': 'popup', 'minutes': 10}
                ]
            }
        };

        let google_auth_data = {
            'client_id': process.env.REACT_APP_GOOGLE_CLIENT_ID,
            'scope': 'https://www.googleapis.com/auth/calendar'
        }

        let enableBtn = () => {
            calendar_add_btn.classList.remove("disabled");
            calendar_add_icon.classList.remove('d-none');
            calendar_add_spinner.classList.add('d-none');
        }

        let statusAlert = (status,message) => {
            this.setState({
                addEventStatus: status,
                addEventMessage: message
            });
            this.handleStatusEventAlert(true);
        }

        let updateAppointmentEventData = (id) => {
            this.props.updateAppointmentEventData(this.props.appointment.id,id);
        }

        let callback = () => {
            var request = window.gapi.client.calendar.events.insert({
                'calendarId': 'primary',
                'resource': event
            });

            request.execute(function(event) {
                if(!_.isEmpty(event) && _.has(event,'status') && event.status == "confirmed"){
                    //send event id backend
                    let params = {
                        google_event_id: event.id
                    }
                    appointmentService.updateGoogleEvent(appointment.id, params).then(
                        data => {
                            updateAppointmentEventData(event.id);
                        },
                        error => {
                            this.setState({ error })
                        }
                    ).finally(() => {
                        enableBtn();
                        statusAlert('Success','Appointment added into Google Calendar.');
                    });
                }else{
                    enableBtn();
                    statusAlert('Failed','Failed to add appointment into Google Calendar.');
                }
            });
        };

        window.gapi.auth.authorize({...google_auth_data, ...{'immediate': true}}, function(res) {
            if (res && !res.error && res.status.signed_in) {
                window.gapi.client.load('calendar', 'v3', callback);
            }else{
                window.gapi.auth.authorize({...google_auth_data, ...{'immediate': false}}, function(res) {
                    if (res && !res.error && res.status.signed_in) {
                        window.gapi.client.load('calendar', 'v3',callback);
                    } else{
                        enableBtn();
                        statusAlert('Failed','Failed to add appointment into Google Calendar.');
                    }
                });
            }
        });
    }

    render() {
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="booking-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeBookingModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    <div className="booking-information-container" ref={ this.componentRef }>
                        <div className="title">Booking Information</div>
                        <div className="booking-information">
                            <div className="doc-information">
                                <div className="div-title">
                                   <div className="sub-title"> Walk-In Appointment</div>
                                    {!_.isEmpty(this.props.appointment.cancel_by) &&
                                        <div className="appointment-status cancelled">Cancelled</div>
                                    }
                                    {(_.isEmpty(this.props.appointment.cancel_by) && this.props.type == 'past') &&
                                        <div className="appointment-status attended">Attended</div>
                                    }
                                </div>
                                <div className="details-container">                
                                    <div className="profile-pic">
                                        <img src={`${this.props.appointment.doctor.avatar ? (this.props.appointment.doctor.avatar.url.thumb.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} alt="profile-pic"/>
                                    </div>
                                    <div className="details">
                                        <div className="name">{this.props.appointment.doctor.title || '' } {this.props.appointment.doctor.name}</div>
                                        <div className="specialty">{this.props.appointment.doctor.specialties[0].name}</div>
                                        <div className="clinic-row">
                                            <img src={location_pin_outline_icon} alt="location-pin"/>
                                            <div className="clinic-name">{this.props.appointment.location.name}</div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="appointment-container">
                                    <div className="div-title">
                                        <div>Appointment Information</div>
                                    </div>
                                    <div className="appointment-details">
                                        <img src={appointment_icon} alt="appointment"/>
                                        <div className="appointment-date">{this.convertEpoch(this.props.appointment.time).format('ddd, DD MMM YYYY, hh:mm A')}</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="patient-container">
                                    <div className="div-title">
                                        <div>Patient Information</div>
                                    </div>
                                    <div className="patient-details">
                                        <div className="row-info">
                                            <div className="col-label">Medical Coverage</div>
                                            <div className="col-value">{this.props.getCoverage(this.props.appointment)}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Reason</div>
                                            <div className="col-value">{this.props.appointment.reason}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Patient</div>
                                            <div className="col-value">{this.props.appointment.visitor.relationship == 'Me' ? 'Myself' : this.props.appointment.visitor.first_name}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Seen the doctor before?</div>
                                            <div className="col-value">{this.props.appointment.seen_doctor ? 'Patient has seen doctor before' : 'New Patient'}</div>
                                        </div>
                                        <div className="row-info">
                                            <div className="col-label">Note</div>
                                        </div>
                                        <div className="row-note">{this.props.appointment.note}</div>
                                    </div>
                                </div>

                        <div className="confirmed-container">
                        {(() => {
                        if (this.props.appointment.cancel_by || this.props.type == 'past') {
                            return (
                                <div className="features">
                                    <a className="link-container" href={`/search-book/professional-profile/${this.props.appointment.doctor.friendly_id}/${this.props.appointment.location.location_friendly_id}`}>
                                        <div className="interest-icon">
                                            <img src={calendar_new_icon} alt="calendar"/>
                                        </div>
                                        <div className="interest-title">New Appointment</div>
                                    </a>
                                    <div className="link-container" onClick={() => this.handleFav()}>
                                        <div className="interest-icon">
                                            {this.getFavIcon()}
                                        </div>
                                        <div className="interest-title">{this.getFavWording()}</div>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="features">
                                    {_.isEmpty(this.props.appointment.user_google_event_id) && 
                                        <div className="link-container" id="calendar-add-btn" onClick={() => this.handleAddEventAlert(true)}>
                                            <div className="interest-icon">
                                                <img className="calendar-add-icon" src={calendar_add_icon} alt="calendar-add" id="calendar-add-icon"/>
                                                <Spinner className="d-none" id="calendar-add-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                            </div>
                                            <div className="interest-title">Add to Calendar</div>
                                        </div>
                                    }
                                    <div className="link-container" onClick={() => this.handleCancelAlert(true)}>
                                        <div className="interest-icon">
                                            <i className="fa fa-ban" aria-hidden="true"></i>
                                        </div>
                                        <div className="interest-title">Cancel Appointment</div>
                                    </div>
                                    <div className="link-container" onClick={() => this.handleFav()}>
                                        <div className="interest-icon">
                                            {this.getFavIcon()}
                                        </div>
                                        <div className="interest-title">
                                            {this.getFavWording()}
                                        </div>
                                    </div>
                                    <div className="link-container" onClick={() => this.handleHotelDialog(true)}>
                                        <div className="interest-icon">
                                            <img src={world_icon} alt="travel-info"/>
                                        </div>
                                        <div className="interest-title">Travel Info</div>
                                    </div>
                                </div>
                            );
                        }
                        })()}
                        <div className="booking-disclaimer">
                            <p>Please note that a doctor may cancel an appointment at the last minute due to another urgent condition such as surgery or emergency cases. The doctor will make all effort to inform you of the cancellation as soon as possible.</p>
                            <p>BookDoc is neither an agent nor an affiliate of any healthcare provider. We do not recommend or endorse any particular healthcare provider. We do not make any representations or warranties with respet to the healthcare service they provide.</p>
                        </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                {/* cancel dialog */}
                    <Dialog
                        open={this.state.alertCancelShow}
                        onClose={() => this.handleCancelAlert(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{"Cancel Appointment?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to cancel this appointment?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCancelAlert(false)} color="primary">No</Button>
                            <Button onClick={() => this.cancelAppointment()} color="primary" autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                {/* end cancel dialog */}

                {/* add google calendar dialog */}
                    <Dialog
                        open={this.state.alertAddEventShow}
                        onClose={() => this.handleAddEventAlert(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{"Add To Calendar?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to add this appointment to your calendar?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleAddEventAlert(false)} color="primary">No</Button>
                            <Button onClick={() => this.addToCalendar()} color="primary" autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                {/* end add google dialog */}

                {/* status add calendar dialog */}
                    <Dialog
                        open={this.state.alertStatusEventShow}
                        onClose={() => this.handleStatusEventAlert(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">{this.state.addEventStatus}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.addEventMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleStatusEventAlert(false)} color="primary" autoFocus>Close</Button>
                        </DialogActions>
                    </Dialog>
                {/* end status google dialog */}                

                <HotelDialog showHotelDialog={this.state.showHotelDialog} handleHotelDialog={this.handleHotelDialog} doctor={this.props.appointment.doctor} location={this.props.appointment.location} appointment_date={this.props.appointment.time}/>
            </Modal>
        );
  }
}