import React, { Component, useState } from "react";
import '../assets/styles/Navigation.scss';
import { NavLink } from 'react-router-dom';
import bookdoc_logo from '../assets/images/bookdoc-logo.png';
import { Navbar, Nav,NavDropdown, Dropdown, DropdownButton } from 'react-bootstrap' 
import { userService } from '../services/user';
import { SignInModal } from "./SignInModal";
import { SignUpModal } from "./SignUpModal";
import { ChangePwModal } from "./ChangePwModal";

class Navigation extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        name: null,
        showActiv: false,
        showSignInModal: false,
        showSignUpModal: false,
        showChangePwModal: false
      };

      this.handleSignInModal = this.handleSignInModal.bind(this);
      this.handleSignUpModal = this.handleSignUpModal.bind(this);
      this.handleChangePwModal = this.handleChangePwModal.bind(this);
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    closeModal = () => this.setState({ isOpen: false });

    showActivDropdown = () => this.setState({ showActiv: true});
    hideActivDropdown = () => this.setState({ showActiv: false});
    
    activClick = (e) => {
      window.location.href = `${process.env.REACT_APP_MAIN_SITE_URL}/activ`;
    } 

    logout = (e) => {
      userService.logout(false);
      
      if(this.props.location.state )
        window.location.reload(false);
      else
        this.props.history.push({ pathname: "/search-book" });
    } 

    handleSignInModal = (isOpen) => this.setState({ showSignInModal: isOpen });
    handleSignUpModal = (isOpen) => this.setState({ showSignUpModal: isOpen });
    handleChangePwModal = (isOpen) => this.setState({ showChangePwModal: isOpen });
    handleSwitchSignInModal  = (isOpen) => this.setState({ showSignInModal: isOpen, showSignUpModal: !isOpen  });
    handleSwitchSignUpModal  = (isOpen) => this.setState({ showSignInModal: !isOpen, showSignUpModal: isOpen });
    handleSwitchChangePwModal  = (isOpen) => this.setState({ showSignInModal: !isOpen, showChangePwModal: isOpen });

    render() {
    return (
        <div>
          <div className="top-banner">
            <div className="top-banner-content">
              <div className="top-banner-socials">
                  <a href="https://www.facebook.com/BookDoc4u" target="_blank" className="btn social-btn">
                    <i className="fa fa-facebook social-icon fb-icon" aria-hidden="true"></i>
                  </a>
                  <a href="https://twitter.com/bookdoc?lang=en" target="_blank" className="btn social-btn ">
                    <i className="fa fa-twitter social-icon twitter-icon" aria-hidden="true"></i>
                  </a>
                  <a href="https://www.instagram.com/bookdoc4u/?hl=en" target="_blank" className="btn social-btn">
                    <i className="fa fa-instagram social-icon insta-icon" aria-hidden="true"></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UCzXEKbAQejcj1Y8uFiuVipQ" target="_blank" className="btn social-btn">
                    <i className="fa fa-youtube-play social-icon youtube-icon" aria-hidden="true"></i>
                  </a>
                  <a href="https://www.linkedin.com/organization-guest/company/bookdoc?challengeId=AQFvAzT0EafU_wAAAXN5o_ENDOZqkhQ09s5xCPbn5CnqiJ2hopjBXkbHsexXLCHG0RDLchO9Dt7RhiR2I0-39fGaPzwQPZInrg&submissionId=f5a4fba7-d542-2416-2cb8-8c32c643cf00" target="_blank" className="btn social-btn">
                    <i className="fa fa-linkedin social-icon linked-icon" aria-hidden="true"></i>
                  </a>
              </div>
              <div className="top-banner-contacts">
                <div className="top-banner-phone">
                  <i className="fa fa-phone phone-icon" aria-hidden="true"></i>1300-88-2362
                </div>
                <div className="top-banner-email">
                  <i className="fa fa-envelope envelope-icon" aria-hidden="true"></i>care@bookdoc.com
                </div>
              </div>
            </div>
          </div>

          <Navbar collapseOnSelect expand="lg" variant="light">
            <Navbar.Brand href="#home" className="img-fluid">
              <img src={bookdoc_logo} className="header-logo" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="overlay-navbar"></div>
              <div className="mobile-nav-links-container">
                <Nav className="mr-auto desktop-v">
                    <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}`}>Home</Nav.Link></div>
                    <div className="nav-item"><Nav.Link href='/search-book' className={`${['/search-book',''].includes(window.location.pathname) ? "active" : ""}`}>Search & Book</Nav.Link></div>
                    <NavDropdown title="Activ" id="collasible-nav-dropdown" onClick={this.activClick} show={this.state.showActiv} onMouseEnter={this.showActivDropdown} onMouseLeave={this.hideActivDropdown}>
                      <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/activ`}>Overview</NavDropdown.Item>
                      <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/activ-challenge`}>Activ Challenge</NavDropdown.Item>
                      <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/activ-events`}>Activ Events</NavDropdown.Item>
                    </NavDropdown>
                    <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/tele-consult`}>Tele-Consult</Nav.Link></div>
                    <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/services/corporations`}>Corporations</Nav.Link></div>
                    <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MARKETPLACE_URL}`}>Marketplace</Nav.Link></div>
                    <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/our-partners`}>Partnership</Nav.Link></div>
                    {(() => {
                      if(userService.getUser()){
                      return (
                          <DropdownButton className="more-btn nav-item" variant="danger" title="More">
                            <Dropdown.Item href='/search-book/upcoming-appointment'>Upcoming Appointments</Dropdown.Item>
                            <Dropdown.Item href='/search-book/past-appointment'>Past Appointments</Dropdown.Item>
                            <Dropdown.Item href='/search-book/favourites'>Favourites</Dropdown.Item>
                            <Dropdown.Item href="/search-book/settings">Settings</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={this.logout}>Sign out</Dropdown.Item>
                          </DropdownButton>
                      );
                      }else{
                        return <div className="nav-item"><button type="button" className="btn btn-danger login-btn" onClick={() => this.handleSignInModal(true)}><span className="sign-in-text">Sign In</span></button></div>;
                      }
                    })()}
                </Nav>
             
                <Nav className="mr-auto mobile-v">
                  <div className="mobile-nav-close">
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Navbar.Toggle>
                  </div>
                  <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}`}>Home</Nav.Link></div>
                  <NavDropdown title={<span className="nav-title">About Us <i className="fa fa-plus icon-rotates" aria-hidden="true"></i></span>} id="about-us-dropdown">
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us`}>Overview</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/milestone-achievements`}>Milestone Achievements</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/meet-our-people/`}>Meet Our People</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/about-us/our-partners/`}>Our Partners</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title={<span className="nav-title">Activ <i className="fa fa-plus icon-rotates" aria-hidden="true"></i></span>} id="activ-dropdown">
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/activ`}>Overview</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/activ-challenge`}>Activ Challenge</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/activ-events`}>Activ Events</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title={<span className="nav-title">Services <i className="fa fa-plus icon-rotates" aria-hidden="true"></i></span>} id="services-dropdown">
                    <NavDropdown.Item className={`${['/search-book',''].includes(window.location.pathname) ? "active" : ""}`} href='/search-book'>Search & Book</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/tele-consult/`}>Tele-Consult</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/services/healthcare-professionals/`}>Healthcare Professionals</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/services/hospitals-clinics/`}>Hospitals & Clinics</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/services/corporations/`}>Corporations</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MARKETPLACE_URL}`}>Marketplace</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title={<span className="nav-title">Newsroom <i className="fa fa-plus icon-rotates" aria-hidden="true"></i></span>} id="newsroom-dropdown">
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/newsroom/health-stories/`}>Health Stories</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/newsroom/news-coverage/`}>News Coverage</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/newsroom/featured-stories/`}>Featured Stories</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/newsroom/awards/`}>Awards</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title={<span className="nav-title">Careers <i className="fa fa-plus icon-rotates" aria-hidden="true"></i></span>} id="careers-dropdown">
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/careers/experienced-professionals/`}>Experienced Professionals</NavDropdown.Item>
                    <NavDropdown.Item href={`${process.env.REACT_APP_MAIN_SITE_URL}/careers/internship/`}>Internship</NavDropdown.Item>
                  </NavDropdown>

                  <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/testimonials/`}>Testimonials</Nav.Link></div>
                  <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/faqs/`}>FAQs</Nav.Link></div>
                  <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/download-bookdoc-app/`}>Download BookDoc App</Nav.Link></div>
                  <div className="nav-item"><Nav.Link href={`${process.env.REACT_APP_MAIN_SITE_URL}/contact-us/`}>Contact Us</Nav.Link></div>

                  {(() => {
                    if(userService.getUser()){
                    return (
                        <NavDropdown title={<span className="nav-title">More <i className="fa fa-plus icon-rotates" aria-hidden="true"></i></span>} id="more-dropdown">
                          <NavDropdown.Item href='/search-book/upcoming-appointment'>Upcoming Appointments</NavDropdown.Item>
                          <NavDropdown.Item href='/search-book/past-appointment'>Past Appointments</NavDropdown.Item>
                          <NavDropdown.Item href='/search-book/favourites'>Favourites</NavDropdown.Item>
                          <NavDropdown.Item href="/search-book/settings">Settings</NavDropdown.Item>
                          <NavDropdown.Item href="#" onClick={this.logout}>Sign out</NavDropdown.Item>
                        </NavDropdown>
                    );
                    }else{
                      return <div className="nav-item"><button type="button" className="btn btn-danger login-btn" onClick={() => this.handleSignInModal(true)}><span className="sign-in-text">Sign In</span></button></div>;
                    }
                  })()}

                  <div className="mobile-nav-contacts">
                    <div className="top-banner-phone">
                      <i className="fa fa-phone phone-icon" aria-hidden="true"></i>1300-88-2362
                    </div>
                    <div className="top-banner-email">
                      <i className="fa fa-envelope envelope-icon" aria-hidden="true"></i>care@bookdoc.com
                    </div>
                  </div>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Navbar>
        
          <React.Fragment>
            <SignInModal showSignInModal={this.state.showSignInModal} handleSignInModal={this.handleSignInModal} handleSwitchSignUpModal={this.handleSwitchSignUpModal} handleSwitchChangePwModal={this.handleSwitchChangePwModal}/>
            <SignUpModal showSignUpModal={this.state.showSignUpModal} handleSignUpModal={this.handleSignUpModal} handleSwitchSignInModal={this.handleSwitchSignInModal}/>
            <ChangePwModal showChangePwModal={this.state.showChangePwModal} handleChangePwModal={this.handleChangePwModal} handleSwitchChangePwModal={this.handleSwitchChangePwModal}/>
          </React.Fragment>
      
        </div>
    )};
}

export { Navigation };
