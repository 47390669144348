import React, { Component } from "react";

import { healthTipService } from '../services/healthTip';

import '../assets/styles/ExpertColumn.scss';
import _ from 'lodash';
import  { Redirect } from 'react-router-dom';

class ExpertColumnDetail extends Component {

    constructor(props){
        super(props);

        this.state = {
            expert_column: {}
        }
    }

    getExpertColumn(id){

        healthTipService.getHealthTipDetail(id).then(
            res => {
                this.setState({ 
                    expert_column: res
                });
            },
            error => {
                console.log('There has been a problem with your fetch operation: ', 
                    error.message);
            }
        )
    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.getExpertColumn(this.props.match.params.id);
        }else{
            // redirect
        }
    }
    
    render() {
        return (
            <div className="expert-column-detail-page container">
                <div className="header">
                    <div className="title">Expert Columns</div>
                    <div className="bread-crumb">Home / <a className="header-anchor" href="/search-book/expert-column">Expert Columns</a> / {this.state.expert_column ? this.state.expert_column.title : '-'}</div>
                </div>
                <hr className="header-line"/>
                <div className="body">
                    {_.isEmpty(this.state.expert_column) && 
                        <div className="empty-div">Could Not Find Article </div>
                    }
                    {!_.isEmpty(this.state.expert_column) && 
                        <div className="details-div">
                            <div className="image"><img src={this.state.expert_column.image.origin}/></div>
                            <div className="author-date">
                                {this.state.expert_column.author} . {this.state.expert_column.publish_date}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.expert_column.content }}></div>
                        </div>
                    }
                </div>
            </div>
            
        );
    }
}

export { ExpertColumnDetail };
