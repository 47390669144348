import { authHeader } from '../helpers/auth-header';
import { stringify } from 'query-string';
import { userService } from '../services/user';

export const healthTipService = {
    getHealthTips,
    getHealthTipDetail
};

function getHealthTips(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: header,
    };

    return fetch(`${process.env.REACT_APP_API_URL}health_tips?${stringify(params)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function getHealthTipDetail(id){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: header,
    };

    return fetch(`${process.env.REACT_APP_API_URL}health_tips/${id}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}


function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // window.location.reload(true);
            userService.logout();
        }

        if (response.status === 422){
            return response.json();
        }
        const error = (response && response.message) || response.statusText;
        if(error.length == 0){
            error = 'Internal Server Error'
        }
        return Promise.reject(error);
    }

    return response.json();
}