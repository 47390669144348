import React, { useState } from "react";
import { useLoadScript,GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { arrayService } from '../services/array';
import _ from 'lodash';
import '../assets/styles/Map.scss';

function Map(data) {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();

    if(_.has(data, 'location_friendly_id')){
      data.results.forEach(function(item) {
        let location = arrayService.findElementByProperty(item.locations, 'friendly_id', data.location_friendly_id);
        bounds.extend({lat: parseFloat(location.lat), lng: parseFloat(location.long)});
      });
    }else{
      data.results.forEach(item => bounds.extend({lat: item.location.lat, lng: item.location.long}));
    }

    if(data.results.length > 1){
      map.fitBounds(bounds);
    }else{
      map.setCenter(bounds.getCenter());
      map.setZoom(16);
    }
  };

  const containerStyle = {
    position: 'relative',  
    width: '100%',
    height: '100%'
  }

  return isLoaded ? (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={containerStyle}
    >
      {data.results.map((item, index) => (
        <Marker
          key={`map-marker-${index}`}
          position={{ lat: (_.has(data, 'location_friendly_id') ? parseFloat(arrayService.findElementByProperty(item.locations, 'friendly_id', data.location_friendly_id).lat) : item.location.lat ), lng: (_.has(data, 'location_friendly_id') ? parseFloat(arrayService.findElementByProperty(item.locations, 'friendly_id', data.location_friendly_id).long) : item.location.long) }}
          onClick={() => handleActiveMarker(`map-marker-${index}`)}
        >
          {activeMarker === `map-marker-${index}` ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div className={`marker-container ${data.parentName}`}>
                    <div className="marker-info-container">
                        <div className="marker-info-name">{item.name}</div>
                        <div className="marker-info-specialty">{item.specialties[0].name}</div>
                    </div>
                    {data.parentName == 'ResultMapView' && 
                      <div className="button-group">
                        <a className="btn btn-call" href={`tel:${item.phone_number}`}>Call</a>
                        <a className="btn btn-view" href={`/search-book/clinic/${_.has(data, 'location_friendly_id') ? data.location_friendly_id : item.location.friendly_id}`}>View</a>
                      </div>
                    }
                </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  ) : <div>loading</div>;
}

export default Map;
