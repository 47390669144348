import React, { Component } from "react";
import list_icon from '../assets/images/list-icon.png';
import locationIcon from '../assets/images/location-pin-icon.png';
import Map from "./Map";

import ProfessionalCard from "./ProfessionalCard";

export class ResultMapView extends Component {

  constructor(props) {
    super(props);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }

  get show() {
    return this.props.activeView === "map_view";
  }

  onInfoWindowClose() {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    })
  };

  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  render() {
  
    const containerStyle = {
      position: 'relative',  
      width: '100%',
      height: '100%'
    }

    if (this.show) {
      return (
        <React.Fragment>
            <div className="map-content">
              <div className="result-list">
                {
                  this.props.results.map((doctor, index) => (
                    <ProfessionalCard key={`doc-card-${index}`} doctor={doctor} onFav={this.props.onFav}  />
                  ))
                }
              </div>
              <div className="map-container">

              <Map results={this.props.results} parentName={`ResultMapView`}/>

              </div>
            </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}