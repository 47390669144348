import React, { Component } from "react";
import  { Redirect } from 'react-router-dom';

class ComponentError extends Component {
    render() {
        return <Redirect to='/search-book' />
    };
}

export { ComponentError };
