import { authHeader } from '../helpers/auth-header';
import { stringify } from 'query-string';
import { userService } from '../services/user';

export const appointmentService = {
    create,
    get,
    checkAvailability,
    cancel,
    updateGoogleEvent
};

function create(data) {
    
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: {...header,...authHeader()},
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}appointments`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function get(data){
    let header = { 
        'Content-Type': 'application/json',
        'Accept': 'application/laelia.v2'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()},
    };

    return fetch(`${process.env.REACT_APP_API_URL}appointments?${stringify(data)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function updateGoogleEvent(appointment_id,data){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'PUT',
        headers: {...header,...authHeader()},
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}appointments/${appointment_id}/update_google_event`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function cancel(id){

    let header = { 
        'Content-Type': 'application/json'
    }

    const requestOptions = {
        method: 'DELETE',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}appointments/${id}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function checkAvailability(data){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}appointments/available?${stringify(data)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // window.location.reload(true);
            userService.logout();
        }

        if (response.status === 422){
            return response.json();
        }
        const error = (response && response.message) || response.statusText;
        if(error.length == 0){
            error = 'Internal Server Error'
        }
        return Promise.reject(error);
    }

    return response.json();
}