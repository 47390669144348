import React, { Component } from "react";
import _ from 'lodash';
import '../assets/styles/HotelDialog.scss';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import { arrayService } from '../services/array';

import { partnerService } from '../services/partner';

export class HotelDialog extends Component {

    constructor(props){
        super(props);
        this.state = {
            hotels: [],
            valid_hotels: []
        };
    }

    componentDidMount(){
      this.getHotels(this.props.location.lat, this.props.location.long)
    }

    getHotels(lat,long){
        let params = {
            lat: lat,
            long, long
        }
        partnerService.getHotel(params)
        .then(
            data => {
                if(!_.isEmpty(data)){
                    let hotels = data.metadata.hotel_partners;
                    let valid_hotels = []
                    _.each(hotels, (hotel, key) => {
                        let validHotel = arrayService.findElementByProperty(global.constant.partners,'name',hotel.name);
                     
                        if(validHotel){
                            switch (validHotel.propertyName) {
                                // Get agoda website origin url
                                case 'agoda':
                                  hotel.fallback_url = partnerService.getAgodaHotelUrl(this.props.location.agoda_id, hotel.fallback_url);
                                  break;
        
                                // Add home.html to GTower Hotel website
                                case 'gTowerHotel':
                                  hotel.fallback_url = hotel.fallback_url + 'home.html';
                                  break;
        
                                case 'airbnb':
                                    hotel.fallback_url = partnerService.getAirbnbUrl(this.props.location, this.props.appointment_date, hotel.fallback_url);
                                break;

                                default: 
                                    hotel.fallback_url = hotel.fallback_url;
                                    break;
                            }
                            valid_hotels.push(Object.assign(hotel, validHotel));
                        }
                    });

                    this.setState({
                        hotels: hotels,
                        valid_hotels: valid_hotels
                    });
                    
                }
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    render() {
        return (
        <div>
            <Dialog onClose={() => this.props.handleHotelDialog(false)} aria-labelledby="simple-dialog-title" open={this.props.showHotelDialog}>
                <DialogTitle id="simple-dialog-title">Travel Info</DialogTitle>
                
                <DialogContent>
                    <div className="dialog-list">
                        {
                            this.state.valid_hotels.map((hotel, index) => (
                                <a key={`hotel-${index}`} className={`custom-icon--${hotel.iconName}`} href={hotel.fallback_url} target="_blank"/>
                            ))
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )};
}
