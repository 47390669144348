import React, { Component }  from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import { userService } from '../services/user';
import { doctorService } from '../services/doctor';
import { visitReasonService } from '../services/visitReason';
import { appointmentService } from '../services/appointment';
import { validateMessagesService } from '../services/validateMessage';
import { favouriteService } from '../services/favourite';
import { arrayService } from '../services/array';

import '../assets/styles/BookingModal.scss';
import '../assets/styles/MainModal.scss';

import default_avatar from '../assets/images/avatar-default.png';
import location_pin_outline_icon from '../assets/images/location-pin-outline-icon.png';
import appointment_icon from '../assets/images/appointment_icon.png';
import edit_icon from '../assets/images/edit_icon.png';
import success_img from '../assets/images/success.png';
import calendar_add_icon from '../assets/images/calendar_add_icon@x3.png';

import world_icon from '../assets/images/world_icon.png';
import calendar_new_icon from '../assets/images/calendar_new_icon@x3.png';
import fav_fill from '../assets/images/fav_fill@x3.png';
import fav_unfill from '../assets/images/fav_unfill@x3.png';

import { Formik,ErrorMessage, getIn } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { HotelDialog } from "./HotelDialog";
import { BookingBehalfConfirmDialog } from "./BookingBehalfConfirmDialog";
import Spinner from 'react-bootstrap/Spinner';

export class BookingModal extends Component {

    constructor(props) {
        super(props);

        let user = userService.getUser();
        // fixed state
        this.fixed_state = {
            visit_reasons: [],
            relationships: global.constant.relationships,
        }

        // based state
        this.base_state = {
            coverages: [],
            selected_coverage: 1,
            current_step: 1,
            slot_availability_error: "",
            showHotelDialog: false,
            temp_show: false,
            showConfirmDialog: false,
            days: _.range(1, 32).map((x) => ({ value: x, label: String(x).padStart(2, '0') })),
            months: _.range(1, 13).map((x) => ({ value: x, label: String(x).padStart(2, '0') })),
            years: _.range(global.constant.minYear, moment().format('YYYY')).reverse().map((x) => ({ value: x, label: x })),
            labels:{
                medical_coverage: "I am paying by myself",
                reason: "Consultation",
                patient: "Me",
                seen_doc_before: "New Patient"
            },
            dob_day: "",
            dob_month: "",
            dob_year: "",
            dob_error: "",
            temp_behalf: {},
            behalf_field: '',
            temp_coverage: {},
            appointment: {
                is_me: true,
                reason: "Consultation",
                pay_by_employer: false,
                note: "",
                doctor_id: '',
                seen_doctor: false,
                user_id: '',
                endtime: '',
                time: '',
                location_id: '',
                visitor: {
                    date_of_birth: user.date_of_birth,
                    sex: user.sex,
                    last_name: user.last_name,
                    id: user.id,
                    relationship: "Me",
                    first_name: user.first_name,
                    email: user.email
                }
            }
        }

        this.state = {...this.fixed_state, ...this.base_state};

        this.handleHotelDialog = this.handleHotelDialog.bind(this);
        this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
        this.handleVisitorSelectorChange = this.handleVisitorSelectorChange.bind(this);
        this.onCoverageChange = this.onCoverageChange.bind(this);
    }

    handleHotelDialog = (isOpen) => this.setState({ showHotelDialog: isOpen });
    handleConfirmDialog = (isOpen) => this.setState({ showConfirmDialog: isOpen });

    get show() {
        if(this.props.showBookingModal && !this.state.temp_show){
            // reset state
            this.resetState();
            // end reset
            this.getCoverages();
        }
        return this.props.showBookingModal;
    }

    appointmentSchema = yup.object().shape({
        visitor: yup.object().shape({
            first_name: yup.string().required().label('First Name'),
            last_name: yup.string().required().label('Last Name'),
            email: yup.string().email().label('Email')
        }),
        note: yup.string().max(200).label('Note')
    });

    componentDidMount(){
        if(this.props){
            this.getVisitReasons();
            this.getBookingOnBehalfs();
        }
    }

    resetState(){
        this.setState(this.base_state)
    }

    closeBookingModal(){
        this.setState({
            current_step: 1,
            temp_show: false
        });
        this.props.handleBookingModal(false);
    }

    getCoverages(){
        if(!this.state.temp_show){
            this.setState({temp_show: true});

             if(!_.has(this.props.selected.doctor,'employers')){
                    let params = {
                        location_id: this.props.selected.doctor.location.friendly_id
                    }
                doctorService.get(this.props.selected.doctor.friendly_id,params)
                .then(
                    data => {
                        if(data){
                            this.getCoveragesMain(data);
                        }else{
                            this.getCoveragesMain({});
                        }
                    },
                    error => {
                        this.getCoveragesMain({});
                    }
                )
            }else{
                this.getCoveragesMain(this.props.selected.doctor);
            }
        }
    }

    getCoveragesMain(temp_doctor){

        let coverages = global.constant.coverages;
        let employers = userService.getUser().employers;
        let doctor_employers = temp_doctor ? temp_doctor.employers : [];

        if(!_.isEmpty(employers) && !_.isEmpty(doctor_employers)){
            let employer = employers[0];
            let isValidEmp = arrayService.findElementByProperty(doctor_employers,'name',employer.name);
            
            if(isValidEmp && isValidEmp.verified){
                var checkCoverageExist = coverages.findIndex(x => x.label == employer.name);
                let empCoverage = {
                    label: employer.name,
                    value: 3
                };

                this.onCoverageChange(empCoverage);

                if(checkCoverageExist < 0){
                    coverages.unshift(empCoverage);
                }
            }else{
                coverages = coverages.filter(coverage => coverage.value !== 3);
                this.onCoverageChange(coverages[0]);
            }
        }
        this.setState({ coverages: coverages});
    }

    getVisitReasons(){
        visitReasonService.get().then(
            visit_reasons => {
                this.setState({
                    visit_reasons: visit_reasons.map((visit_reason) => ({ id: visit_reason.id ,value: visit_reason.name, label: visit_reason.name }))
                });
            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    getBookingOnBehalfs(){
        userService.bookingOnBehalfs().then(
            behalfs => {
                let _relationships = this.state.relationships.map((el) => {
                    let behalf = _.find(behalfs, function(behalf) { return behalf.name.toLowerCase () == el.value.toLowerCase(); });
                    if(behalf){
                        el.enabled = behalf.enabled
                    }
                    return el;
                })
                this.setState({
                    relationships: _relationships
                });
            },
            error => {
                this.setState({ error, loading: false })
            }
        );
    }

    onIsMeChange(selected) {
        let visitor;
        let temp_behalf = {};
        if(selected.value){
            //is me
            let user = userService.getUser();
            visitor = {
                date_of_birth: user.date_of_birth,
                sex: user.sex,
                last_name: user.last_name,
                id: user.id,
                relationship: "Me",
                first_name: user.first_name,
                email: user.email
            }
        }else{
            temp_behalf = _.find(this.state.relationships, function(relationship) { return relationship.value == "Child"})
            visitor = {
                date_of_birth: '',
                sex: 'male',
                last_name: '',
                id: '',
                relationship: "Child",
                first_name: '',
                email: ''
            }
        }
        this.setState(prevState => ({
            dob_error: "",
            temp_behalf: temp_behalf,
            labels: {
                ...prevState.labels, 
                patient: selected.label
            },
            appointment: {
                ...prevState.appointment, 
                is_me: selected.value,
                visitor: visitor
            },
        }));
    }

    onSeenDoctorChange(selected) {
        this.setState(prevState => ({
            labels: {
                ...prevState.labels, 
                seen_doc_before: selected.label
            },
            appointment: {
                ...prevState.appointment, 
                seen_doctor: selected.value
            }
        }));
    }

    onVisitReasonChange(selected){
        let _appointment = this.state.appointment;
        _appointment.reason = selected.value;
        this.setState(prevState => ({
            labels: {
                ...prevState.labels, 
                reason: selected.label
            },
            appointment: _appointment
        }));
    }

    onCoverageChange(selected, is_enabled = false){
        /* 
            1(pay by myself) -> value = null
            2(not applicable voucher) -> value = null and voucher_redeem = "not_applicable"
            3(emp coverage) -> insurance_id = -1, pay_by_employer = true
        */
        if(selected.value == 3 && !this.state.temp_behalf.enabled && !this.state.appointment.is_me && !is_enabled){
            this.setState({
                showConfirmDialog: true,
                behalf_field: 'coverage',
                temp_coverage: selected
            });
        }else{
            if(is_enabled){
                selected = _.find(this.state.coverages, function(o) { return o.value == 1; });
            }

            let appointment = this.state.appointment;
            let pay_by_employer = false;
            let insurance_id = null;
     
             switch(selected.value) {
                 case 1:
                     appointment = (delete appointment['voucher_redeem'], appointment);
                     break;
                 case 2: 
                     appointment.voucher_redeem = "not_applicable";
                     break;
                 case 3:
                     appointment = (delete appointment['voucher_redeem'], appointment);
                     insurance_id = -1;
                     pay_by_employer = true;
                     break;
             }
     
             appointment.pay_by_employer = pay_by_employer;
             appointment.insurance_id = insurance_id;
     
             this.setState({
                 selected_coverage: selected.value,
                 appointment: appointment
             });
     
             this.setState(prevState => ({
                 labels: {
                     ...prevState.labels, 
                     medical_coverage: selected.label
                 },
                 selected_coverage: selected.value,
                 appointment: appointment
             }));
        }
    }

    handleAppointment(formData, actions, stateData){
        
        this.setState(prevState => ({
            current_step: stateData.dob_error ? 1 : 2,
            appointment: {
                ...prevState.appointment, 
                doctor_id: this.props.selected.doctor.id,
                endtime: this.props.selected.slot.end,
                time: this.props.selected.slot.start,
                location_id: this.props.location.id,
                user_id: userService.getUser().id
            }
        }));
    }

    onEditPatientClick(){
        this.setState({
            current_step: 1
        }); 
    }

    onEditSlotClick(){
        this.setState({
            current_step: 4
        }); 
    }

    confirmBooking(){
        // check slot availability
        // booking

        document.getElementById("confirm-booking-btn").classList.add("disabled");
        document.getElementById("confirm-booking-text").classList.add('d-none');
        document.getElementById("confirm-booking-spinner").classList.remove('d-none');

        let availability_params = {
            doctor_id: this.props.selected.doctor.id,
            end: this.props.selected.slot.end,
            start: this.props.selected.slot.start,
            location_id: this.props.location.id,
            user_id: userService.getUser().id
        }
        
        appointmentService.checkAvailability(availability_params).then(
            availability => {
                if(_.has(availability, 'status') && availability.status){
                    // do booking
                    appointmentService.create(this.state.appointment).then(
                        appointment => {
                            this.setState({
                                current_step: 3,
                                slot_availability_error: ""
                            }); 
                        },
                        error => {
                            this.setState({ error, loading: false });
                        }
                    );
                }else{
                    // show timeslot and error message(show only the first one)
                    this.setState({
                        slot_availability_error: availability[Object.keys(availability)[0]]
                    }); 
                }
            },
            error => {
                this.setState({ error, loading: false })
            }
        ).finally(() => {
            document.getElementById("confirm-booking-btn").classList.remove("disabled");
            document.getElementById("confirm-booking-text").classList.remove('d-none');
            document.getElementById("confirm-booking-spinner").classList.add('d-none');
        });
    }

    onSelectedSlot(slot){
        this.props.setSelectedSlot(slot);
        this.setState(prevState => ({
            current_step: 2,
            appointment: {
                ...prevState.appointment, 
                endtime: slot.end,
                time: slot.start
            }
        })); 
    }

    /* someone-else form */
    handleVisitorInputChange(values){
        this.setState(prevState => ({
            appointment: {
                ...prevState.appointment,
                note: values.note,
                visitor: {
                    ...prevState.appointment.visitor,
                    first_name: values.visitor.first_name,
                    last_name: values.visitor.last_name,
                    email: values.visitor.email
                }
            }
        }))
    }

    handleVisitorSelectorChange(field_name, selected, is_enabled = false){
        // open confirm modal
        if(field_name == 'relationship' && this.state.selected_coverage == 3 && !selected.enabled && !is_enabled){
            this.setState({
                showConfirmDialog: true,
                temp_behalf: selected,
                behalf_field: field_name
            })
        }else{
            if(is_enabled){
                let coverage_selected = _.find(this.state.coverages, function(o) { return o.value == 1; });
                this.onCoverageChange(coverage_selected);
            }

            this.setState(prevState => ({
                temp_behalf: selected,
                appointment: {
                    ...prevState.appointment,
                    visitor: {
                        ...prevState.appointment.visitor,
                        [field_name]: selected.value
                    }
                }
            }))   
        }
    }

    checkDob(){
        let dob_error = "";
        let dob = moment([this.state.dob_day, this.state.dob_month, this.state.dob_year].join('-'), 'D-M-YYYY', true);

        if(dob.isValid()){
            let age = moment().diff(dob, 'years',true)

            if(age < global.constant.minAge){
                dob_error = validateMessagesService.ageInvalid('Date of Birth', global.constant.minAge)
            }else{
                let _visitor = this.state.appointment.visitor;
                _visitor.date_of_birth = dob.format('YYYY-MM-DD');
                this.setState(prevState => ({
                    assignment: {
                        ...prevState.assignment, 
                        visitor: _visitor
                    }
                }))
            }
        }else{
            dob_error = validateMessagesService.invalid('Date of Birth')
        }

        this.setState({
            dob_error: dob_error
        });

        return dob_error;
    }

    submitVisitorForm(){
        if(!this.state.appointment.is_me){
            let dob_error = this.checkDob();
            let list = document.querySelectorAll('.dob-field [class*=control]')
            for (var i = 0; i < list.length; ++i) {
                dob_error ? list[i].classList.add('is-invalid') : list[i].classList.remove('is-invalid');
            }
        }
    }
    /* end someone-else */

    /* for fav */
    getFavWording(){
        return this.props.selected.doctor.favorited ? 'Remove from Favourite' : 'Add to Favourite'
    }

    getFavIcon(){
        return this.props.selected.doctor.favorited ? <img src={fav_fill}/> : <img src={fav_unfill}/>;
    }

    handleFav(){
        let isFav = this.props.selected.doctor.favorited;

        let params = {
            practice_location_id: this.props.location.id
        }
    
        favouriteService.favourite(isFav, params)
        .then(
            data => {
                this.props.onFav(this.props.selected.doctor.id, isFav, this.props.location.id)
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }
    /* end fav */

    convertEpoch(value){
        return moment.unix(value).utc()
    }

    render() {
        return (
            <Modal show={this.show} backdrop="static" size="lg" dialogClassName="booking-modal main-modal">
                <button className="btn close-modal-btn" onClick={() => this.closeBookingModal()}>
                    <i className="fa fa-times"></i>
                </button>
                <Modal.Body>
                    {this.state.current_step == 1 &&
                        <div>
                            <div className="title">Patient Information</div>
                            <div className="booking-form-container">
                                <Formik enableReinitialize show={this.state.current_step == 1} validationSchema={this.appointmentSchema} onSubmit={(form, actions) => this.handleAppointment(form, actions, this.state)} initialValues={this.state.appointment}>
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors
                                    }) => (
                                        <Form noValidate onSubmit={handleSubmit} className="booking-form">
                                            <Form.Row className="row-inline">
                                                <Form.Label>Are you under any medical coverage?</Form.Label>
                                                <div className="right-div">
                                                    <Select className="insurance-field" options={this.state.coverages} value={this.state.coverages.filter(option => option.value === this.state.selected_coverage)} onChange={(val) => this.onCoverageChange(val)} placeholder=""/>
                                                    <div className="ins-eligibility">The eligibility for reimbursement by your insurance company depends on the contracted provisions of your insurance plan.</div>
                                                </div>
                                            </Form.Row>

                                            <Form.Row className="row-inline">
                                                <Form.Label>What's the reason for your visit?</Form.Label>
                                                <Select options={this.state.visit_reasons} placeholder="" value={this.state.visit_reasons.filter(option => option.value === this.state.appointment.reason)} onChange={(val) => this.onVisitReasonChange(val)}/>
                                            </Form.Row>

                                            <Form.Row className="row-inline">
                                                <Form.Label>Who will be seeing the doctor?</Form.Label>
                                                <Select options={global.constant.visitors} placeholder="" value={global.constant.visitors.filter(option => option.value === this.state.appointment.is_me)} onChange={(val) => this.onIsMeChange(val)}/>
                                            </Form.Row>

                                            {!this.state.appointment.is_me &&
                                                <div className="someone-else-form">
                                                    <hr/>
                                                        <Form.Row>
                                                            <Form.Group>
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="visitor.first_name"
                                                                    value={values.visitor.first_name}
                                                                    onChange={handleChange}
                                                                    onBlur={e => {
                                                                        handleBlur(e);
                                                                        this.handleVisitorInputChange(values);
                                                                      }}
                                                                    className={`${getIn(errors, "visitor.first_name") ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage
                                                                    component="div"
                                                                    name="visitor.first_name"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="visitor.last_name"
                                                                    value={values.visitor.last_name}
                                                                    onChange={handleChange}
                                                                    onBlur={e => {
                                                                        handleBlur(e);
                                                                        this.handleVisitorInputChange(values);
                                                                      }}
                                                                    className={`${getIn(errors, "visitor.last_name") ? 'is-invalid' : ''}`}
                                                                />
                                                                <ErrorMessage
                                                                    component="div"
                                                                    name="visitor.last_name"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group>
                                                            <Form.Label>Email Address (Optional)</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="visitor.email"
                                                                value={values.visitor.email}
                                                                onChange={handleChange}
                                                                onBlur={e => {
                                                                    handleBlur(e);
                                                                    this.handleVisitorInputChange(values);
                                                                  }}
                                                                className={`${getIn(errors, "visitor.email") ? 'is-invalid' : ''}`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="visitor.email"
                                                                className="invalid-feedback"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Gender</Form.Label>
                                                            <Select className="gender-field" value={global.constant.genders.filter(option => option.value === this.state.appointment.visitor.sex)} options={global.constant.genders} onChange={gender => this.handleVisitorSelectorChange('sex',gender)} placeholder=""/>
                                                        </Form.Group>   
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group>  
                                                            <Form.Label>Date of Birth</Form.Label>  
                                                            <div className="dob-field-div">
                                                                <Select className="dob-field dob-day-field" value={this.state.days.filter(option => option.value === this.state.dob_day)} options={this.state.days} placeholder="DD" onChange={day => this.setState({ dob_day: day.value })} />
                                                                <Select className="dob-field dob-month-field" value={this.state.months.filter(option => option.value === this.state.dob_month)} options={this.state.months} placeholder="MM" onChange={month => this.setState({ dob_month: month.value })} />
                                                                <Select className="dob-field dob-year-field" value={this.state.years.filter(option => option.value === this.state.dob_year)} options={this.state.years} placeholder="YYYY" onChange={year => this.setState({ dob_year: year.value })} />
                                                            </div>
                                                            {this.state.dob_error != "" &&
                                                                <div className="dob-error">{this.state.dob_error}</div>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Relationship with the patient</Form.Label>
                                                            <Select className="gender-field" value={this.state.relationships.filter(option => option.value === this.state.appointment.visitor.relationship)} options={this.state.relationships} onChange={relationship => this.handleVisitorSelectorChange('relationship',relationship)} placeholder=""/>
                                                        </Form.Group> 
                                                    </Form.Row>
                                                    <hr/>
                                                </div>
                                            }

                                            <Form.Row className="row-inline">
                                                <Form.Label>Has this patient seen the doctor before?</Form.Label>
                                                <Select options={global.constant.seenDoctors} value={global.constant.seenDoctors.filter(option => option.value === this.state.appointment.seen_doctor)} onChange={(val) => this.onSeenDoctorChange(val)} placeholder=""/>
                                            </Form.Row>

                                            <Form.Row className="row-inline">
                                                <Form.Label>Note</Form.Label>
                                                <Form.Control 
                                                    name="note"
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    onBlur={e => {
                                                        handleBlur(e);
                                                        this.handleVisitorInputChange(values);
                                                    }}
                                                    isValid={touched.note && !errors.note}
                                                    isInvalid={!!errors.note}
                                                    className="note-textarea" 
                                                    as="textarea" 
                                                    rows={3} 
                                                    placeholder="Your note to doctor"/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.note}
                                                </Form.Control.Feedback>
                                            </Form.Row>
                                    
                                            <Button className="next-btn" variant='danger' type="submit" onClick={() => this.submitVisitorForm()}>Next</Button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    }

                    {this.state.current_step == 2 &&
                        <div className="booking-information-container" ref={ this.componentRef }>
                            <div className="title">Booking Information</div>
                            <div className="booking-information">
                                {!_.isEmpty(this.state.slot_availability_error) &&
                                    <div className="slot-error">{this.state.slot_availability_error}</div>
                                }
                                <div className="doc-information">
                                    <div className="div-title">Walk-In Appointment</div>
                                    <div className="details-container">                
                                        <div className="profile-pic">
                                            <img src={`${this.props.selected.doctor.avatar ? (this.props.selected.from == 'prof_profile' ? this.props.selected.doctor.avatar.styles.origin.url : this.props.selected.doctor.avatar.url.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} />
                                        </div>
                                        <div className="details">
                                            <div className="name">{this.props.selected.doctor.title || '' } {this.props.selected.doctor.name}</div>
                                            <div className="specialty">{this.props.selected.doctor.specialties[0].name}</div>
                                            <div className="clinic-row">
                                                <img src={location_pin_outline_icon}/>
                                                <div className="clinic-name">{this.props.location.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="appointment-container">
                                        <div className="div-title">
                                            <div>Appointment Information</div>
                                            <img src={edit_icon} className="edit-icon" onClick={() => this.onEditSlotClick()}/>
                                        </div>
                                        <div className="appointment-details">
                                            <img src={appointment_icon}/>
                                            <div className="appointment-date">{this.convertEpoch(this.props.selected.slot.start).format('ddd, DD MMM YYYY, hh:mm A')}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="patient-container">
                                        <div className="div-title">
                                            <div>Patient Information</div>
                                            <img src={edit_icon} className="edit-icon" onClick={() => this.onEditPatientClick()}/>
                                        </div>
                                        <div className="patient-details">
                                            <div className="row-info">
                                                <div className="col-label">Medical Coverage</div>
                                                <div className="col-value">{this.state.labels.medical_coverage}</div>
                                            </div>
                                            <div className="row-info">
                                                <div className="col-label">Reason</div>
                                                <div className="col-value">{this.state.labels.reason}</div>
                                            </div>
                                            <div className="row-info">
                                                <div className="col-label">Patient</div>
                                                <div className="col-value">{this.state.appointment.is_me ? 'Me' : this.state.appointment.visitor.first_name}</div>
                                            </div>
                                            <div className="row-info">
                                                <div className="col-label">Seen the doctor before?</div>
                                                <div className="col-value">{this.state.labels.seen_doc_before}</div>
                                            </div>
                                            <div className="row-info">
                                                <div className="col-label">Note</div>
                                            </div>
                                            <div className="row-note">{this.state.appointment.note}</div>
                                        </div>
                                        <div className="booking-disclaimer">
                                            <p>Please note that a doctor may cancel an appointment at the last minute due to another urgent condition such as surgery or emergency cases. The doctor will make all effort to inform you of the cancellation as soon as possible.</p>
                                            <p>BookDoc is neither an agent nor an affiliate of any healthcare provider. We do not recommend or endorse any particular healthcare provider. We do not make any representations or warranties with respet to the healthcare service they provide.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="t-and-c">
                                    By continuing, You have read and accept BookDoc's <a href="/search-book/terms" target="_blank">terms & conditions</a> and <a href="/search-book/privacy" target="_blank">Privacy policy</a>.
                                </div>
                                <button className="btn btn-block submit-btn" id="confirm-booking-btn" onClick={() => this.confirmBooking()}>
                                    <span id="confirm-booking-text">Confirm Booking</span>
                                    <Spinner className="d-none" id="confirm-booking-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                    }

                    {this.state.current_step == 3 &&
                        <div className="confirmed-container">
                            <div className="success-img">
                                <img src={success_img} />
                            </div>
                            <div className="success-title">Your Booking is Confirmed!</div>
                            <div className="appointment-detail">
                                <div className="div-title">Appointment Details</div>
                                <div className="slot-detail">
                                    <img src={appointment_icon}/>
                                    <div className="detail-div">{this.convertEpoch(this.props.selected.slot.start).format('ddd, DD MMM YYYY, hh:mm A')}</div>
                                </div>
                                <div className="location-detail">
                                    <img src={location_pin_outline_icon}/>
                                    <div className="detail-div">{this.props.location.name}</div>
                                </div>
                            </div>
                            <div className="features">
                                    {/* <div className="link-container">
                                        <div className="interest-icon">
                                            <img className="calendar-add-icon" src={calendar_add_icon}/>
                                        </div>
                                        <div className="interest-title">Add to Calendar</div>
                                    </div> */}
                                    <div className="link-container" onClick={() => this.closeBookingModal()}>
                                        <div className="interest-icon">
                                            <img src={calendar_new_icon}/>
                                        </div>
                                        <div className="interest-title">New Appointment</div>
                                    </div>
                                    <div className="link-container" onClick={() => this.handleFav()}>
                                        <div className="interest-icon">
                                            {this.getFavIcon()}
                                        </div>
                                        <div className="interest-title">
                                            {this.getFavWording()}
                                        </div>
                                    </div>
                                    <div className="link-container" onClick={() => this.handleHotelDialog(true)}>
                                        <div className="interest-icon">
                                            <img src={world_icon}/>
                                        </div>
                                        <div className="interest-title">Travel Info</div>
                                    </div>
                            </div>
                            <div className="booking-disclaimer">
                                <p>Please note that a doctor may cancel an appointment at the last minute due to another urgent condition such as surgery or emergency cases. The doctor will make all effort to inform you of the cancellation as soon as possible.</p>
                                <p>BookDoc is neither an agent nor an affiliate of any healthcare provider. We do not recommend or endorse any particular healthcare provider. We do not make any representations or warranties with respet to the healthcare service they provide.</p>
                            </div>

                            <button className="btn btn-block submit-btn" onClick={() => this.closeBookingModal()}>Done</button>
                        </div>
                    }

                    {this.state.current_step == 4 &&
                        <div className="slot-selection-container">
                            <div className="title">Time Available</div>
                            <div className="slots-container">
                                <div className="date-bar">
                                    <button className={`btn btn-link previous ${this.props.day_diff > 0 ? '' : 'disabled'}`}><i className="fa fa-chevron-left" aria-hidden="true" onClick={() => this.props.get_range_dates(0)}></i></button>
                                    <div className="date">{moment(this.props.slot_date).format('ddd, DD MMM Y')}</div>
                                    <button className="btn btn-link next" onClick={() => this.props.get_range_dates(1)}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                </div>
                                <div className="slots">
                                    {(() => {
                                        if (this.props.slot_loading) {
                                            return <div className="slots-loader"><Spinner animation="border" className="spinner"/></div>;
                                        } else {
                                            return (!_.isEmpty(this.props.slots) ?
                                                (
                                                    this.props.slots.map((slot, index) => (
                                                        <button key={`slot-${index}`} className="btn slot valid-slot" onClick={() => this.onSelectedSlot(slot)}>{this.convertEpoch(slot.start).format('hh:mm A')}</button>
                                                    ))
                                                ) : <div className="empty-div">No Slot</div>
                                            );
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>

                <HotelDialog showHotelDialog={this.state.showHotelDialog} handleHotelDialog={this.handleHotelDialog} doctor={this.props.selected.doctor} location={this.props.location} appointment_date={this.props.selected.slot.start}/>

                <BookingBehalfConfirmDialog showConfirmDialog={this.state.showConfirmDialog} handleConfirmDialog={this.handleConfirmDialog} handleVisitorSelectorChange={this.handleVisitorSelectorChange} temp_behalf={this.state.temp_behalf} temp_coverage={this.state.temp_coverage} behalf_field={this.state.behalf_field} temp_coverage={this.state.temp_coverage} onCoverageChange={this.onCoverageChange}/>
            </Modal>
        );
  }
}