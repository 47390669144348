import React, { Component } from "react";
import _ from 'lodash';
import { stringify } from 'query-string';
import { doctorService } from '../services/doctor';
import { languageService } from '../services/language';

import '../assets/styles/Result.scss';
import map_icon from '../assets/images/map-icon.png';
import list_icon from '../assets/images/list-icon.png';
import filter_icon from '../assets/images/filter-icon.png';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';

import { ResultListView } from './ResultListView';
import { ResultMapView } from "./ResultMapView";
import { Error } from "./Error";
import Pagination from '@material-ui/lab/Pagination';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterModal } from "./FilterModal";

import ReactGA from 'react-ga';

class Result extends Component {

    constructor(props){
        super(props);

        const query = new URLSearchParams(this.props.location.search);

        var selected_location = {
            name: query.get('location'),
            lat: query.get('lat'),
            long: query.get('long')
        };
        let startDate = moment().startOf('day');
        let selected_specialty = {
            label: query.get('specialty'),
            value: {
                type: query.get('type'),
                name: query.get('specialty')
            }
        };
        let search_input_value = '';
        if(!_.isEmpty(this.props.location.state)){
            selected_location = this.props.location.state.location;
            startDate = moment(this.props.location.state.startDate);
            selected_specialty = this.props.location.state.selected_specialty;
            search_input_value = this.props.location.state.search_input_value;
        }

        this.state = {
            selected_location: selected_location,
            startDate: startDate,
            specialties: [],
            search_input_value: search_input_value,
            selected_specialty: selected_specialty,
            location: {       
                name: null,
                latitude: null,
                longitude: null
            },
            loading_result: false,
            loading_result_list: false,
            error_result: '',
            results: [],
            meta: {},
            activeView: "list_view",
            locations: [],
            result_pageindex: 1,
            meridiem: '',
            language: '',
            languages: [],
            showFilterModal: false,
            feature: ''
        };

        this.handleToggleView = this.handleToggleView.bind(this);
        this.getResultsBasedOnDate = this.getResultsBasedOnDate.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleOnFav = this.handleOnFav.bind(this);
        this.handleFilterDateChange = this.handleFilterDateChange.bind(this);
        this.handleMeridiemChange = this.handleMeridiemChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleFeatureChange = this.handleFeatureChange.bind(this);
    }

    handleFilterModal = (isOpen) => this.setState({ showFilterModal: isOpen });

    handleToggleView(e) {
        const { name } = e.target;
        this.setState(() => ({
          activeView: name
        }));
    }

    getResults(date, lat, long, specialty, pageindex, forSlots = false){
        this.setState({
            loading_result_list: !forSlots,
            loading_result: true
        });

        if(!forSlots){
            document.getElementById("results-loader").classList.remove('d-none');
            document.getElementById("results-list").classList.add('d-none');
        }

        let params = {
            around: 3,
            date: moment(date, 'DD-MM-YYYY').format('YYYYMMDD'),
            perpage: 20,
            pageindex: pageindex
        }

        if(!_.has(specialty,'value')){
            specialty = {
                name: this.state.search_input_value,
                type: 'keyword'
            }
        }else{
            specialty = specialty.value;
        }

        if(specialty.type == 'common_name'){
            params = {...params, ...{
                lat: lat,
                long: long,
                type: specialty.type,
                keyword: specialty.name
            }}
        }
        else if(specialty.id == -1){
            params = {...params, ...{
                lat: lat,
                long: long,
                type: specialty.type,
                keyword: specialty.name
            }}
        }else if(specialty.type == 'doctor'){
            params = {...params, ...{
                type: specialty.type,
                doctor_id: specialty.id
            }}
        }else if(specialty.type == 'facility'){
            this.props.history.push({
                pathname:  `/search-book/clinic/${specialty.friendly_id}`
            });
        }else if(specialty.type == 'keyword'){
            params = {...params, ...{
                lat: lat,
                long: long,
                keyword: specialty.name
            }}
        }else{
            params = {...params, ...{
                lat: lat,
                long: long,
                specialty_name: specialty.name
            }}
        }

        if(this.state.language){
            params = {...params, ...{
                language: this.state.language.toLowerCase()
            }}
        }

        if(!_.isEmpty(this.state.meridiem)){
            params = {...params, ...{
                meridiem: this.state.meridiem.value
            }}
        }

        if(!_.isEmpty(this.state.feature)){
            if(this.state.feature.value == 'online-booking'){
                params = {...params, ...{
                    is_using_calendar: 1
                }}
            }else if(this.state.feature.value == 'chat'){
                params = {...params, ...{
                    chat_enabled: 1
                }}

            }else if(this.state.feature.value == 'video-call'){
                params = {...params, ...{
                    video_enabled: 1
                }}
            }
            
        }

        ReactGA.pageview(this.props.location.pathname + '?' + stringify(params));

        doctorService.searchDoctors(params)
        .then(
            data => {
                if(data){
                    this.setState({ 
                        error_result: "",
                        results: data.doctors,
                        meta: data.meta
                    });
                }
            },
            error => {
                if (typeof error.json === "function") {
                    error.json().then(jsonError => {
                        this.setState({
                            error_result: jsonError.error
                        });
                    });
                } else {
                    this.setState({
                        error_result: 'Internal Server Error'
                    });
                    console.log('There has been a problem with your fetch operation: ', 
                    error.message);
                }
            }
        ).finally(() => {
            this.setState({
                loading_result_list: false,
                loading_result: false
            });
            if(!forSlots){
                let results_loader_class = document.getElementById("results-loader");
                let results_list_class = document.getElementById("results-list");

                if(results_loader_class)
                    results_loader_class.classList.add('d-none');

                if(results_list_class)
                    results_list_class.classList.remove('d-none');
            }
        });
    }

    getResultsBasedOnDate(date, forSlots = false){
        this.getResults(date, this.state.selected_location.lat, this.state.selected_location.long, this.state.selected_specialty, this.state.result_pageindex, forSlots); 
    }

    getCommons(){
        axios.get(process.env.REACT_APP_API_URL+'commons').then(res => {
            const specialties = res.data.map((common) => {
                common._label = common.name;
                return { value: common, label: common.name }
            });
            this.setState({ specialties });
        })
    }

    getLanguages(){
        languageService.get()
        .then(
            data => {
                if(data){
                    this.setState({ 
                        languages: _.sortBy(data.languages),
                    });
                }
            },
            error => {
                console.log(error)
            }
        );
    }

    componentWillMount(){
        const locations = global.constant.available_countries;

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var checkLocExist = locations.findIndex(x => x.type == 'current_location')

                if(checkLocExist < 0){
                    locations.unshift({
                        name: 'Current Location',
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                        type: 'current_location'
                    });
                }
              },
              function(error) {
                console.log("Error Code = eoo " + error.code + " - " + error.message);
              });
          } else {
            console.log("Not Available");
        }
        this.setState({ locations });
    };

    componentDidMount(){
        this.getCommons();
        this.getLanguages();

        this.getResults(this.state.startDate, this.state.selected_location.lat, this.state.selected_location.long, this.state.selected_specialty, this.state.result_pageindex); 
    }

    renderSuggestionType(suggestion) {
        switch(suggestion.type) {
            case 'common_name':
                return "Layman's Term";
            default:
                return suggestion.type;
        }
    }

    renderSuggestionName(suggestion){
        if(suggestion.type == 'doctor' && suggestion.id == -1){
            return `Doctors named "${this.state.search_input_value}" (${suggestion.count})`;
        }else if(suggestion.type == 'facility' && suggestion.id == -1){
            return `Facilities named "${this.state.search_input_value}" (${suggestion.count})`;
        }
        else{
            return suggestion.name;
        }
    }
    
    onSearch(e){
        e.preventDefault();

        let _pageindex = 1
        this.setState({result_pageindex: _pageindex}); 

        this.getResults(this.state.startDate, this.state.selected_location.lat, this.state.selected_location.long, this.state.selected_specialty, _pageindex); 
    }

    handleSpecialtySelectChange = selectedValue => {
        selectedValue.label = selectedValue.value._label;

        this.setState({ 
            selected_specialty: selectedValue,
            search_input_value: selectedValue.label
        });

        var locationField = document.getElementById("locationField");

        if(selectedValue.value.type == 'doctor' && selectedValue.value.id != -1){
            locationField.classList.add("disabled");
        }else if(selectedValue.value.type == 'facility' && selectedValue.value.id != -1){
            locationField.classList.add("disabled");
        }else{
            locationField.classList.remove("disabled");
        }
    };

    handleSpecialtyInputChange = (newValue, { action }) => {
        if (action === "input-change"){
            this.setState({ 
                search_input_value: newValue,
                selected_specialty: null
            });
            return newValue;
        }
    };

    handleOnFav(doctor_id, isFav, location_id){
        this.setState({
            results: this.state.results.map(el => ((el.id == doctor_id && el.location.id == location_id) ? Object.assign({}, el, { favorited: !isFav }) : el))
        });
    }

    handlePageChange = (event, value) => {
       this.setState({result_pageindex: value});
       this.getResults(this.state.startDate, this.state.selected_location.lat, this.state.selected_location.long, this.state.selected_specialty, value); 
    };

    handleFilterDateChange(val){
        let _date = moment(val,'YYYY-MM-DD')
        this.setState({
            startDate: _date
        })
    }

    handleMeridiemChange(selected){
        this.setState({ meridiem: selected }, function () {
            this.getResultsBasedOnDate(this.state.startDate);
        });
    }

    handleLanguageChange(selected){
        this.setState({ language: selected }, function () {
            this.getResultsBasedOnDate(this.state.startDate);
        });
    }

    handleFeatureChange(selected){
        this.setState({ feature: selected }, function () {
            this.getResultsBasedOnDate(this.state.startDate);
        });
    }

    render() {

        const promiseOptions2 = inputValue =>
        new Promise((resolve, reject) => {
            if(inputValue.length >= 2){
                axios.get(`${process.env.REACT_APP_API_URL}suggestions?keyword=${inputValue}`).then(res => {
                    resolve(res.data.map((suggestion) => { 
                        if(suggestion.id == -1 && (suggestion.type == 'doctor' || suggestion.type == 'facility')){
                            suggestion.count = suggestion.name
                            suggestion.name = this.state.search_input_value
                        }
                        suggestion._label = this.renderSuggestionName(suggestion)
                        return { value: suggestion, label: 
                        (<div className="label specialty-label">
                            <span className="suggestion-name">{this.renderSuggestionName(suggestion)}</span>
                            <span className="suggestion-type">
                                {this.renderSuggestionType(suggestion)}
                            </span>
                        </div>) }}))
                })
            }else{
                resolve([]);
            }
        }); 

        return (
        <div className="result-page">
            {/* main section */}
            <div className="main-section">
                <div className="container">
                    <div className="main-title">FIND HEALTHCARE PROFESSIONALS</div>
                    <div className="main-sub-title">Hassle-free integrated online eco-system for finding and booking healthcare professionals</div>

                    <div className="search-bar w-100">
                        <form className="section-form" onSubmit={this.onSearch}>
                            <div className="form-group speciality-group">
                                <AsyncSelect className="speciality-field" placeholder="Specialty, Service, Doctor, Clinic, etc." cacheOptions loadOptions={promiseOptions2} defaultOptions={this.state.specialties} onInputChange={this.handleSpecialtyInputChange} onChange={this.handleSpecialtySelectChange} defaultValue={this.state.selected_specialty} inputValue={this.state.search_input_value} value={this.state.selected_specialty} closeMenuOnSelect={true}/>
                            </div>

                            <div className="form-group location-group">
                                <Select id="locationField" className="location-field" defaultValue={this.state.selected_location} options={this.state.locations} getOptionLabel={(option)=>option.name} getOptionValue={(option)=>option} placeholder="Select Location" onChange={location => this.setState({ selected_location: location })} />
                            </div>

                            <div className="form-group button-group">
                                <input className="btn search-btn section-btn w-100" type="submit"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            <div className="result-list-container">
                <div className="container-lg">
                    <div className="filter-bar">
                        <div className="left-filters">
                            <TextField
                                className="filter-field"
                                id="standard-password-input"
                                type="date"
                                autoComplete="current-password"
                                value={this.state.startDate.format('YYYY-MM-DD')} 
                                onChange={(e) => this.handleFilterDateChange(e.target.value)}
                                inputProps={{min: moment().format('YYYY-MM-DD') }}
                                label="Date"
                                />
                            <Autocomplete
                                className="filter-field"
                                value={this.state.meridiem}
                                onChange={(e,val) => this.handleMeridiemChange(val)}
                                options={global.constant.time_options}
                                getOptionLabel={(option) => option.label ? option.label : ""}
                                getOptionSelected={(option, selected) => option.value === selected.value }
                                id="meridiem-field"
                                selectOnFocus
                                renderInput={(params) => <TextField {...params} label="Time" margin="normal" />}
                            />
                            <Autocomplete
                                className="language-field filter-field"
                                value={this.state.language}
                                onChange={(e,val) => this.handleLanguageChange(val)}
                                options={this.state.languages}
                                getOptionLabel={(option) => option ? option : ""}
                                getOptionSelected={(option, selected) => option === selected }
                                id="language-field"
                                selectOnFocus
                                renderInput={(params) => <TextField {...params} label="Language" margin="normal" />}
                            />
                            <Autocomplete
                                className="filter-field"
                                value={this.state.feature}
                                onChange={(e,val) => this.handleFeatureChange(val)}
                                options={global.constant.feature_options}
                                getOptionLabel={(option) => option.label ? option.label : ""}
                                getOptionSelected={(option, selected) => option.value === selected.value }
                                id="feature-field"
                                selectOnFocus
                                renderInput={(params) => <TextField {...params} label="Feature" margin="normal" />}
                            />

                        </div>
                        <div className="mobile-filters">
                            <button className="btn btn-link" onClick={() => this.handleFilterModal(true)}><img src={filter_icon}/></button>
                        </div>
                        {(() => {
                            if (this.state.activeView != 'map_view') {
                                return (
                                    <div className="right-items">
                                        <img className="map-icon" src={map_icon} />
                                        <button className="btn btn-link map-view-btn" onClick={this.handleToggleView} name="map_view">Map View</button>
                                    </div> 
                                );
                            } else {
                                return (                        
                                    <div className="right-items">
                                        <img className="map-icon" src={list_icon} />
                                        <button className="btn btn-link map-view-btn" onClick={this.handleToggleView} name="list_view">List View</button>
                                    </div>);
                            }
                        })()}
                    </div>

                    <div className="loader d-none" id="results-loader"><Spinner animation="border" className="spinner"/></div>
                    <div id="results-list">
                        {(() => {
                            if (_.isEmpty(this.state.error_result)) {
                                return (_.isEmpty(this.state.results) ? 
                                    <div className="loader no-result-div">
                                        <p>We are sorry, there is no doctor that matches the criteria you selected.</p>
                                        <p>We are in the process of recruiting more doctors into the platform. Do let us know if there is any particular preferred doctor/specialty that you are interested in. Your feedback will help improve convenience for all.</p>
                                        <p>You may contact us <a className="contact-us-here" href={`${process.env.REACT_APP_MAIN_SITE_URL}/contact-us/`} target="_blank">here</a>.</p>
                                    </div> : (<React.Fragment>
                                    <ResultListView activeView={this.state.activeView} results={this.state.results} onGetResultsBasedOnDate={this.getResultsBasedOnDate} start_date={this.state.startDate} onFav={this.handleOnFav} meta={this.state.meta} loading_result={this.state.loading_result}/>
                                    <ResultMapView activeView={this.state.activeView} results={this.state.results} onFav={this.handleOnFav} />
                                    <Pagination className="container" count={this.state.meta.total_pages} page={this.state.page_no} onChange={this.handlePageChange}/>
                                    </React.Fragment>)
                                );
                            } else {
                                return <Error message={this.state.error_result}></Error>;
                            }
                        })()}
                    </div>

                    <FilterModal showFilterModal={this.state.showFilterModal} handleFilterModal={this.handleFilterModal} startDate={this.state.startDate} handleFilterDateChange={this.handleFilterDateChange} handleMeridiemChange={this.handleMeridiemChange} meridiem={this.state.meridiem} handleLanguageChange={this.handleLanguageChange} language={this.state.language} languages={this.state.languages} handleFeatureChange={this.handleFeatureChange} feature={this.state.feature}/>
                </div>
            </div>
        </div>
    )};
}

export { Result };
