import React, { Component } from "react";
import moment from 'moment';
import _ from 'lodash';

export class TimeSlots extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
    }

    convertEpoch(value){
        return moment.unix(value).utc()
    }

    render() {
        if(_.isEmpty(this.props)){
            return <div>loading</div>;
        }else{
            return (
                    <div className="time-slots">
                        {this.props.dateRange.map(( date, index ) => {
                            let _data = this.props.timeSlots.filter(function(slot){
                                return moment(slot.start_time).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY');
                            })
                    
                            let _slots = []
                    
                            _.times(6, (i) => {
                                _slots.push((_data[i] ? <button className="btn slot valid-slot" onClick={() => {this.props.onSelectedSlot(_data[i], this.props.doctor)}}>{this.convertEpoch(_data[i].start).format('hh:mm A')}</button> : <div className="slot invalid-slot">-</div>));
                            });
                    
                            return <div key={`time-slot-${index}`} className="time-slots-col">
                                {_slots}
                            </div>;
                        })}
                    </div>
            );
        }
    };
}