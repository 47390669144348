import { authHeader } from '../helpers/auth-header';
import { userService} from '../services/user';
import { stringify } from 'query-string';
import _ from 'lodash';

export const doctorService = {
    get,
    searchDoctors
};

function get(doc_friendly_id,data){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: userService.getUser() ? {...header,...authHeader()} : {...header}
    };

    return fetch(`${process.env.REACT_APP_API_URL}doctors/${doc_friendly_id}?${stringify(data)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function searchDoctors(data){
    let header = { 
        'Content-Type': 'application/json',
        'Accept': 'application/laelia.v2'
    }
    const requestOptions = {
        method: 'GET',
        headers: userService.getUser() ? {...header,...authHeader()} : {...header}
    };

    return fetch(`${process.env.REACT_APP_API_URL}doctors/search?${stringify(data)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            throw error;
            if (typeof error.json === "function") {
                throw error.json().then(jsonError => {
                    return jsonError.error;
                    console.log("Json error from API");
                    console.log(jsonError.error);
                }).catch(genericError => {
                    console.log("Generic error from API");
                    console.log(error.statusText);
                });
            } else {
                console.log('There has been a problem with your fetch operation: ', 
                error.message);
            }

        });;
}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // window.location.reload(true);
        }
        return Promise.reject(response);
    }

    return response.json();
}