import React, { Component } from "react";
import _ from 'lodash';
import '../assets/styles/ProfessionalCard.scss';

import { userService} from '../services/user';
import { favouriteService } from '../services/favourite';
import unfav from '../assets/images/fav-unfill.png'; 
import fav from '../assets/images/fav-fill.png'; 
import default_avatar from '../assets/images/avatar-default.png';
import location_pin_outline_icon from '../assets/images/location-pin-outline-icon.png';
import mail_icon from '../assets/images/mail_icon.png';
import phone_icon from '../assets/images/phone_icon.png';
import walk_in_icon from '../assets/images/walk_in_icon.png'; 
import video_icon from '../assets/images/video_colored_icon.png'; 
import chat_icon from '../assets/images/chat_icon_dual_color.png'; 

export class ProfessionalCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    onFavClick(location_id, doctor_id, isFav){

        let params = {
            practice_location_id: location_id
        }

        favouriteService.favourite(isFav, params)
        .then(
            data => {
                this.props.onFav(doctor_id, isFav, location_id)
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

  render() {
  
      return (
        <div className="prof-card card">
            <div className="card-body">
                {userService.getUser() && 
                    <button className="btn fav-btn" onClick={() => this.onFavClick(this.props.doctor.location.id, this.props.doctor.id,this.props.doctor.favorited)}>
                        { this.props.doctor.favorited ? <img src={fav}/> : <img src={unfav}/> }
                    </button>
                }
                <div className="prof-details">
                    <div className="profile-pic">
                        <img src={`${this.props.doctor.avatar ? (this.props.doctor.avatar.url.thumb.url) : default_avatar}`} onError={(e) => { e.target.src = default_avatar }} />  
                        <div className="prof-feature">
                            {this.props.doctor.is_using_calendar == true && 
                                <div className="feature"><img src={walk_in_icon} className="consultation-icon"/>Walk-in</div>
                            }
                            {(_.has(this.props.doctor, 'chat_enabled') && this.props.doctor.chat_enabled) &&
                                <div className="feature"><img src={chat_icon} className="consultation-icon"/> Chat</div>
                            }
                            {(_.has(this.props.doctor, 'video_enabled') && this.props.doctor.video_enabled) &&
                                <div className="feature"><img src={video_icon} className="consultation-icon"/> Video Call</div>
                            }
                        </div>
                    </div>
                    <div className="details">
                        <div className="name-row">
                            <a className="name" href={`/search-book/professional-profile/${this.props.doctor.friendly_id}/${this.props.doctor.location.friendly_id}`} target="_blank">{this.props.doctor.title || '' } {this.props.doctor.name}</a>
                        </div>
                        <div className="specialty">{this.props.doctor.specialties[0].name}</div>
                        <div className="clinic-row">
                            <img src={location_pin_outline_icon}/>
                            <a className="clinic-name" href={`/search-book/clinic/${this.props.doctor.location.friendly_id}`}>{this.props.doctor.location.name}</a>
                        </div>
                        <div className="feature-tags">
                            {this.props.doctor.is_using_calendar == true && 
                                <div className="feature-tag online-booking">Online Booking</div>
                            }
                            {(!_.isEmpty(userService.getUser()) && _.includes(this.props.doctor.employer_ids, _.isEmpty(userService.getUser().employee) ? null : userService.getUser().employee.employer_id)) && 
                                <div className="feature-tag corporate-panel">Corporate Panel</div>
                            }
                        </div>
                        <div className="contact-container">
                            {this.props.doctor.is_using_calendar == true ? 
                                <a className="btn btn-link book-online-btn" href={`/search-book/professional-profile/${this.props.doctor.friendly_id}/${this.props.doctor.location.friendly_id}`}>Book Online</a> : <div></div>
                            }
                            <div className="phone-email-container">
                                <a href={`tel:${this.props.doctor.phone_number}`}><img className="contact-icon phone" src={phone_icon}/></a>
                                <a href={`mailto:${this.props.doctor.email}`}><img className="contact-icon mail" src={mail_icon}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
      );
  }
}

export default ProfessionalCard;
