import React, { Component } from "react";

import { userService} from '../services/user';
import { appointmentService } from '../services/appointment';

import '../assets/styles/UpcomingAppointment.scss';
import _ from 'lodash';
import  { Redirect } from 'react-router-dom';

import { AppointmentCard } from "./AppointmentCard";
import Spinner from 'react-bootstrap/Spinner';

class UpcomingAppointment extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            appointments: [],
            page_no: 1,
            per_page: 10,
            type: 'upcoming',
            meta: {},
        }

        this.handleOnFav = this.handleOnFav.bind(this);
        this.setPageNo = this.setPageNo.bind(this);
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.updateAppointmentEventData = this.updateAppointmentEventData.bind(this);
    }

    getAppointments(pageNo){
        this.setState({
            loading: true
        });
        let params = {
            type: this.state.type,
            per_page: this.state.per_page,
            page: pageNo
        }
        appointmentService.get(params)
        .then(
            appointments => {
                if(appointments){
                    this.setState({
                        meta: appointments.meta,
                        appointments: appointments.appointments
                    });
                }
            },
            error => {
                console.log(error)
                this.setState({ error })
            }
        ).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    setPageNo(page_no){
        this.setState({page_no});
        this.getAppointments(page_no);
    }

    componentDidMount(){
        if(userService.getUser()){
           this.getAppointments(this.state.page_no);
        }
    }

    handleOnFav(appointment_id, isFav){
        let appointment = _.find(this.state.appointments, ['id', appointment_id]);
        _.set(appointment, 'location.favorited', !isFav);
        this.setState({
            appointments: this.state.appointments.map(el => (el.id === appointment_id ? appointment : el))
        });
    }

    handleOnCancel(appointment_id){
        this.setState({
            appointments: this.state.appointments.map(el => (el.id == appointment_id ? Object.assign({}, el, { cancel_by: '1' }) : el))
        });
    }

    updateAppointmentEventData(appointment_id, event_id){
        this.setState({
            appointments: this.state.appointments.map(el => (el.id === appointment_id ? Object.assign({}, el, { user_google_event_id: event_id }) : el))
        });
    }
    
    render() {
        if (_.isEmpty(userService.getUser()))
            return <Redirect to='/search-book' />;

        return (
            <div className="upcoming-appointment-page container">
                <div className="header">
                    <div className="title">Upcoming Appointments</div>
                    <div className="bread-crumb">Home / Upcoming Appointments</div>
                </div>
                <hr className="header-line"/>
                <div className="body">
                    {(() => {
                        if (this.state.loading) {
                            return <div className="loader"><Spinner animation="border" className="spinner"/></div>;
                        } else {
                            return (!_.isEmpty(this.state.appointments) ?
                                <AppointmentCard appointments={this.state.appointments} meta={this.state.meta} page_no={this.state.page_no} type={this.state.type} handleOnFav={this.handleOnFav} setPageNo={this.setPageNo} handleOnCancel={this.handleOnCancel} updateAppointmentEventData={this.updateAppointmentEventData}/>  : <div className="empty-div">No Appointment</div>
                                );
                        }
                    })()}
                </div>
            </div>
            
        );
    }
}

export { UpcomingAppointment };
