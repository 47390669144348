import { authHeader } from '../helpers/auth-header';
import { stringify } from 'query-string';

export const favouriteService = {
    favourite,
    get
};

function favourite(isFav,data){
    let header = { 
        'Content-Type': 'application/json'
    }
    let url, requestOptions;

    if(isFav){
        url = `${process.env.REACT_APP_API_URL}favorites?${stringify(data)}`;
        requestOptions = {
            method: 'DELETE',
            headers: {...header,...authHeader()}
        };
    }else{
        url = `${process.env.REACT_APP_API_URL}favorites`;
        requestOptions = {
            method: 'POST',
            headers: {...header,...authHeader()},
            body: JSON.stringify(data)
        };
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function get(data){
    let header = { 
        'Content-Type': 'application/json',
        'Accept': 'application/laelia.v2'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()},
    };

    return fetch(`${process.env.REACT_APP_API_URL}favorites?${stringify(data)}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function handleResponse(response) {

    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // window.location.reload(true);
        }
        const error = (response && response.message) || response.statusText;
        if(error.length == 0){
            error = 'Internal Server Error'
        }
        return Promise.reject(error);
    }

    return response.json();
}