import React, { Component } from "react";
import { userService} from '../services/user';
import  { Redirect } from 'react-router-dom';
import { appointmentService } from '../services/appointment';

class Profile extends Component {

    constructor(props){
        super(props);

        let user = userService.getUser();
        let start_time, end_time, doctor_id, endtime, time, location_id = "";

        if(user){
        }

        this.state = {
            upcoming_page_no: 1,
            past_page_no: 1,
            per_page: 5
        }
    }

    getAppointment(type, page_no){
        let params = {
            type: type,
            per_page: this.state.per_page,
            page: page_no
        }
        appointmentService.get(params)
        .then(
            data => {
                //console.log(data)
            },
            error => {
                console.log(error)
                this.setState({ error, loading: false })
            }
        );
    }

    componentDidMount(){
        if(userService.getUser()){
           this.getAppointment('past',this.state.page_page_no);
           this.getAppointment('upcoming',this.state.upcoming_page_no);
        }
    }
    
    render() {
        if (!userService.getUser())
            return <Redirect to='/search-book'  />

        return (
        <div className="profile-page">
            profilepage
        </div>
    )};
}

export { Profile };
