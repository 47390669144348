import React, { useState } from "react";
import { useLoadScript,GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import _ from 'lodash';
import '../assets/styles/Map.scss';

function ClinicMap(data) {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    data.results.forEach(item => bounds.extend({lat: item.latitude, lng: item.longitude}));
    map.setCenter(bounds.getCenter());
    map.setZoom(16);
  };

  const containerStyle = {
    position: 'relative',  
    width: '100%',
    height: '100%'
  }

  return isLoaded ? (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={containerStyle}
    >
      {data.results.map((item, index) => (
        <Marker
          key={`map-marker-${index}`}
          position={{ lat: item.latitude, lng: item.longitude }}
          onClick={() => handleActiveMarker(`map-marker-${index}`)}
        >
          {activeMarker === `map-marker-${index}` ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div className="marker-container">
                    <div className="marker-info-container">
                        <div className="marker-info-name">{item.name}</div>
                    </div>
                </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  ) : <div>loading</div>;
}

export default ClinicMap;
