import { authHeader } from '../helpers/auth-header';
import _ from 'lodash';

export const userService = {
    login,
    logout,
    getUser,
    registration,
    changeNumber,
    changePassword,
    getEmployers,
    updateEmployer,
    resetPassword,
    bookingOnBehalfs
};

function login(email, password) {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/login`, requestOptions)
        .then(handleChangePasswordResponse)
        .then(data => {
            // login successful if there's a user in the response
            if (_.has(data,'user')) {
                localStorage.setItem('user', JSON.stringify(data.user));
            }

            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function logout(unauthorized = true) {
    // remove user from local storage to log user out
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'DELETE',
        headers: {...header,...authHeader()}
    };

    localStorage.removeItem('user');

    if(unauthorized){
        window.sessionStorage.setItem("unauthorized", true);
    }

    return fetch(`${process.env.REACT_APP_API_URL}users/logout`, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;

}

function getUser() {
    if (!localStorage.getItem('user')) return
    return JSON.parse(localStorage.getItem('user'))
}

function registration(params) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };

    return fetch(`${process.env.REACT_APP_API_URL}users`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's a user in the response
            if (data.user) {
                localStorage.setItem('user', JSON.stringify(data.user));
            }

            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function changeNumber(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'PUT',
        headers: {...header,...authHeader()},
        body: JSON.stringify(params)
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/change_phone`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });;
}

function changePassword(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'PUT',
        headers: {...header,...authHeader()},
        body: JSON.stringify(params)
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/password`, requestOptions)
        .then(handleChangePasswordResponse)
        .then(data => {
            if(_.has(data,'errors')){
                if(data.errors.includes('autho')){
                    logout();
                    window.location.reload(true);
                }
            }
            if(_.has(data,'user')){
                localStorage.setItem('user', JSON.stringify(data.user));
            }
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function resetPassword(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(params)
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/password`, requestOptions)
        .then(handleChangePasswordResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function getEmployers(){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/employers`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function updateEmployer(params){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'PUT',
        headers: {...header,...authHeader()},
        body: JSON.stringify(params)
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/update_employee`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function bookingOnBehalfs(){
    let header = { 
        'Content-Type': 'application/json'
    }
    const requestOptions = {
        method: 'GET',
        headers: {...header,...authHeader()}
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/booking_on_behalfs`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }).catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', 
            error.message);
        });
}

function handleResponse(response) {
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        }else if(response.status === 422) {
            return response.json();
        }else{
            const error = (response && response.message) || response.statusText;
            if(error.length == 0){
                error = 'Internal Server Error'
            }
            return Promise.reject(error);
        }
    }

    return response.json();
}

function handleChangePasswordResponse(response){
    if(!response.ok){
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            return response.json();
        }else if(response.status === 422) {
            return response.json();
        }else{
            const error = (response && response.message) || response.statusText;
            if(error.length == 0){
                error = 'Internal Server Error'
            }
            return Promise.reject(error);
        }
    }

    return response.json();
}