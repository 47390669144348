import React from "react";
import _ from 'lodash';
import moment from 'moment';

function OperationHour(data) {

  const setDayName = (day) => {
    let days = {
      mon: "Mon",
      tue: "Tue",
      wed: "Wed",
      thu: "Thurs",
      fri: "Fri",
      sat: "Sat",
      sun: "Sun",
      ph: "PH",
      lunch_break: "Lunch Break",
      dinner_break: "Dinner Break"
    }

    return days[day];
  };

  const setWorkHour = (operation) => {
    if(operation.by_appointment_only)
      return "By Appointment Only";
    else if(operation.open_time && operation.close_time)
      return `${moment(operation.open_time, ["HH:mm"]).format('hh:mm A')} - ${moment(operation.close_time, ["HH:mm"]).format('hh:mm A')}`;
    else
      return "Not Available";
  }

  return (
    <React.Fragment>
        {
            data.operatingHours.map((hour, index) => {
                if(hour.day_of_week == 'lunch_break' || hour.day_of_week == 'dinner_break') {
                  if(hour.open_time && hour.close_time){
                    return (
                      <div className="tab-content credential day-row" key={`hour-${index}`}>
                        <div className="cre-title day">* Break time</div>
                        <div className="punctuation">:</div>
                        <div className="cre-content hour">{setWorkHour(hour)}</div>
                      </div>
                    );
                  }
                }else{
                  return (
                    <div className="tab-content credential day-row" key={`hour-${index}`}>
                      <div className="cre-title day">{setDayName(hour.day_of_week)}</div>
                      <div className="punctuation">:</div>
                      <div className="cre-content hour">{setWorkHour(hour)}</div>
                    </div>
                  );
                }
            })
        }
    </React.Fragment>
  );
}

export default OperationHour;
